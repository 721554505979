import { Component, ViewChild } from "@angular/core";

import { RegionsService } from "../services/regions.service";
import { RegionTree, Country, Filter } from "../../customsector.model";
import { SelectionModel } from "@angular/cdk/collections";
import { TableFilterService } from "../../../Templates/styles/services/table-filter.service";
import { Logger } from 'app/_helpers';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'regions-dialog',
    templateUrl: '../view/regions-dialog.component.html'
})
export class RegionsDialog extends TableFilterService {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns = ['Id', 'Name', 'Type'];

    isLoading: boolean;
    isError: boolean;

    constructor(public dialogRef: MatDialogRef<RegionsDialog>, private regionsService: RegionsService, 
        private snackBar: MatSnackBar,
        logger: Logger) {
        super(logger);
    }

    ngOnInit() {
        const allowMultiSelect = true;

        this.regionsService.isError.subscribe(data => this.isError = data);
        this.regionsService.isLoading.subscribe(data => this.isLoading = data);
        this.regionsService.sectorRegion.subscribe(data => this.dataSource.data = data);

        this.selection = new SelectionModel<RegionTree>(allowMultiSelect, []);
        this.regionsService.selectedRegion.source.value.forEach(element => {
            this.selection.select(element);
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    createFilterAttributes(): Array<Filter>{
        let filterAttributes = new Array<Filter>();
        filterAttributes.push({
            Name: "Nordic",
            Choosen: true,
            Color: "lemon"
        });
        filterAttributes.push({
            Name: "Non Nordic",
            Choosen: true,
            Color: "pink"
        });
        return filterAttributes;
    };

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.regionsService.setSelectedRegions(<Array<Country>>this.selection.selected);
        this.openSnackBar("Countries", "Saved", 'style-succes');
    }

    openSnackBar(message: string, action: string, style: any) {
        this.snackBar.open(message, action, {
            duration: 1000,
            panelClass: style
        });
    }

    // DUPLICATED FROM customize.component.ts
    //set the height of the table with all metrics, relative to the filter height
    tableHeight(includeTwo: boolean = true){
        let filter = document.getElementById('table-filter').offsetHeight;
        if(!includeTwo){
            let filter2 = document.getElementById('table-filter2').offsetHeight;
            return "calc(100% - " + filter + "px - " + filter2 + "px - 3rem - 1px)" 
        }
        return "calc(100% - " + filter + "px)"     
    }

}