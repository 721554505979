import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import {
  AggregatedReportCollection,
  Company,
  SectorTree
} from "../../customsector.model";
import { Logger } from 'app/_helpers';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
  get baseUrlGrowApi() {
    return `${environment.baseUrlScreeningApi}v1/`;
  }

  constructor(
    private http: HttpClient,
    private logger: Logger
  ) {}

  getAggregatedReportAttributeList(): Observable<AggregatedReportCollection[]> {
    return this.http.get<AggregatedReportCollection[]>(
      `${this.baseUrlGrowApi}screening/attributeList`,
      { withCredentials: true }
    );
  }

  getAggregatedCompanies(id: number): Observable<Company[]> {
    return this.http.get<Company[]>(
      `${this.baseUrlGrowApi}screening/attributeCompanies/${id}`,
      { withCredentials: true }
    );
  }

  saveCreatedList(
    name: string,
    status: string,
    reportId: number,
    user: string,
    companies: Array<Company>,
    equityUnderwork:boolean,
    creditUnderwork:boolean
  ) {
    return this.http.put<any>(
      `${this.baseUrlGrowApi}screening/companylist`,
      {
        Name: name,
        Status: status,
        ReportId: reportId,
        User: user,
        Companies: companies,
        EquityUnderwork:equityUnderwork,
        CreditUnderwork:creditUnderwork
      },
      { withCredentials: true }
    );
  }

  getSectorTree(): Observable<SectorTree> {
    return this.http.get<SectorTree>(
      `${this.baseUrlGrowApi}sectors/tree`, {
      withCredentials: true
    });
  }

  getSectorCompanies(sectorId: Array<number>): Observable<Company[]> {
    let sb = "?";
    for (let i = 0; i < sectorId.length; i++) {
      if (i != 0 || i < sectorId.length - 1) sb = sb + "&";
      sb = sb + "sectors=" + sectorId[i];
    }
    this.logger.debug("getSectorCompanies", sectorId, sb);
    return this.http.get<Company[]>(
      `${this.baseUrlGrowApi}screening/sectorcompanies${sb}`,
      { withCredentials: true }
    );
  }
}
