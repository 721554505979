import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(private router: Router, ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status == 401) {
                    this.router.navigate(ApplicationPaths.LoginPathComponents, {
                        queryParams: {
                            [QueryParameterNames.ReturnUrl]: this.router.url
                        }
                    });
                } else {
                    return throwError(err);
                }
            })
        );
    }

}