export class SectorTree 
{
    Data: number;
    Label: string;
    Children: Array<SectorTree>;

    Selected: boolean = false;
}

export class Sector
{
    Data: number;
    Label: string;
}

export class RegionTree
{
    Name: string;
    Id: number;
    ChildRegions: Array<RegionTree>
}

export class Country
{
    Id: number;
    Name: string;
    Type: string;
}

export class Company
{
    Id: number;
    Name: string;
    Type: string;
    Sector: number;
    isCredit: boolean;
    isEquity: boolean;
    ExistsEquityUnderwork: boolean;
    ExistsCreditUnderwork: boolean;
    UseEquityUnderwork: boolean;
    UseCreditUnderwork: boolean;
}

export enum FilterColors
{
    Normal = 'salmon',
    HCMlight = 'butternut',
    Deactivated = 'teal',
    ImportOnly = 'mint',
    Notfullcoverage = 'iris',
    Standard = 'standard'
}

/**
 * Copied directly from 1.Report model. Must be changed !!!!!
 */
export class AggregatedReportCollection
{
    Id: number;
    Name: string;
    StatusId: number;
    Type: string;
    UserName: string;
    CreatedDate: Date;
    EquityUnderwork: boolean;
    CreditUnderwork: boolean;
}

export class ResultHeadline
{
    Headline: string;
    ReportId: number;
    Author: string;
}

export interface AGKeyValues
{
    Id: string;
    Name: string;
    Type: string;
    Formula: string;
    TimePeriod: string;
}

export class Filter 
{
    Name: string;
    Choosen: boolean;
    Color: string;
}