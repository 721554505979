import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { CustomlistResultService } from "./customList/services/customlist-result.service";
import { SectorsResultService } from "./sector/services/sector-result.service";
import { SectorFilter } from "../datastructure.model";
import { ResultHeadline } from "./customsector.model";

/* 
    public service that should ONLY have getters so other modules 
    (app.module, result.module etc.) can receive the necessary data
    without getting to much knowledge or controll
*/
@Injectable()
export class CustomsectorResultService {

    private isSectorSource: BehaviorSubject<boolean>;
    isSector;

    private customlistSource: BehaviorSubject<boolean>;
    customlist;

    private sectorSource: BehaviorSubject<boolean>;
    sector;

    constructor(private customlistService: CustomlistResultService, private sectorService: SectorsResultService) {
        this.isSectorSource = new BehaviorSubject<boolean>(false);
        this.isSector = this.isSectorSource.asObservable();

        this.customlistSource = new BehaviorSubject<boolean>(false);
        this.customlist = this.customlistSource.asObservable();

        this.sectorSource = new BehaviorSubject<boolean>(false);
        this.sector = this.sectorSource.asObservable();

        this.customListCheck();
        this.sectorCheck();
    }

    private customListCheck() {
        this.customlistService.companies.subscribe(data => {
            if (data) {
                this.customlistSource.next(true);
            } else {
                this.customlistSource.next(false);
            }
        });
    }

    private sectorCheck() {
        this.sectorService.sectors.subscribe(data => {
            if (data && this.sectorService.regions.source.value) {
                this.sectorSource.next(true);
            } else {
                this.sectorSource.next(false);
            }
        });
        this.sectorService.regions.subscribe(data => {
            if (data && this.sectorService.sectors.source.value) {
                this.sectorSource.next(true);
            } else {
                this.sectorSource.next(false);
            }
        });
    }

    public getSectorErrorlist(): Array<string> {
        let errors = new Array<string>();
        if (!this.sectorService.sectors.source.value)
            errors.push("You need to select at least one sector");
        if (!this.sectorService.regions.source.value)
            errors.push("The country list can't be empty");
        return errors;
    }

    public getCompanies() {
        return this.customlistService.getCompanies();
    }

    public getSectorList() {
        return this.sectorService.getSectorList();
    }

    public getCountries() {
        return this.sectorService.getCountries();
    }

    public getSectorFilter(): SectorFilter {
        return <SectorFilter>this.sectorService.getFilter();
    }

    public getIsSector() {
        return this.isSectorSource.getValue();
    }

    // To keep track of which tab the user is on
    public setIsSector(isSector: boolean) { //not very pretty
        this.isSectorSource.next(isSector);
    }

    get getCustomHeadline(): ResultHeadline {
        return this.customlistService.getHeadline;
    }
}