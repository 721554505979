<div class="middle">
    <div class="w-6 h-12 left">
        <content></content>
    </div>

    <!--<div class="error-reached" *ngIf="isErrorResults">
        <div class="child">
            <p>Ops, something went wrong. Be so kind and try to retriew the data again</p>
        </div>
    </div>

    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>-->

    <div class="w-3 result h-12">
        <div class="h-12 y-align pad-4 result-list result-list-help" *ngIf="sectorList.length < 1">
            <div class="help-result-text">
                <p class="result-top">Here you can choose sectors to include in your report. This is how:</p>
                <div class="result-bottom pad-2-bottom">
                    <mat-icon>arrow_upward</mat-icon>
                    <p>If you rather want to select per company use the select 'Custom list' above</p>
                </div>
                <div class="result-left">
                    <mat-icon>arrow_back</mat-icon>
                    <p>To the left you can choose some sectors.</p>
                </div>
                <div class="result-right">
                    <mat-icon>arrow_forward</mat-icon>
                    <p>To the right you have some selection that can be altered to your need.</p>
                </div>
            </div>
        </div>

        <div class="h-12 w-12 result-list" *ngIf="sectorList.length >= 1">
            <div class="row y-align pad-2-bottom pad-2-top">
                <div class="w-11">
                    <div class="row color-white">
                        <h2>Selected sectors</h2>
                        <p class="h2-expl color-white">&nbsp; ({{sectorList.length}})</p>
                    </div>
                </div>
            </div>

            <!--<span>
                    <span class="color-gray" matTooltip="De-select all sectors" [matTooltipPosition]="'above'"
                        [matTooltipDisabled]="sectorList.length < 1">
                        <button mat-icon-button [disabled]="sectorList.length < 1" (click)="deleteAllKeyValues()">
                            <mat-icon aria-label="Delete all sectors">delete_sweep</mat-icon>
                        </button>
                    </span>
                </span>-->

            <table class="w-12 color-white">
                <tr *ngFor="let value of sectorList; let i = index">
                    <div class="w-12 y-align" style="height: 40px">
                        {{value.Label}}
                    </div>
                </tr>
            </table>
        </div>
    </div>

    <div class="w-3 right">
        <div class="w-12 h-4  pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align">
                <div class="w-12">
                    <!--<h2>Find saved list</h2>-->
                    <div class="pad-1-bottom column">
                        <h2>Coverage</h2>
                        <p class="h2-expl font-gray">Filter selected sector companies, this is set globally.</p>
                    </div>
                    <!--<div class="spacer">
                    <div class="row">
                        <div class="col-sm-12">
                            <mat-chip-list>
                                <mat-chip *ngFor="let filter of filterAttributes; let i = index" [ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'"
                                    (click)="clickFilter(i, !filter.Choosen)">
                                    {{filter.Name}}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>-->
                    <ul class="ul-margin-1 ul-basic">
                        <li *ngFor="let filter of filterAttributes; let i = index">
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [checked]="filter.Choosen" (change)="clickFilter(i, !filter.Choosen)">
                                </mat-checkbox>
                                <!--<div class="w-1">
                                <div class="circle-small" [ngClass]="getFilterColor(filter.Name)"></div>
                            </div>-->
                                <span class="w-9">{{filter.Name}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-12 h-4  pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align">
                <div class="w-12">
                    <!--<h2>Find saved list</h2>-->
                    <div class="pad-1-bottom column">
                        <div class="row">
                            <h2>Countries</h2>
                            <p class="h2-expl">&nbsp; ({{countriesSelected}})</p>
                        </div>
                        <p class="h2-expl font-gray">Filter selected sector companies on country.</p>
                        <button matTooltip="Advanced options" [matTooltipPosition]="'above'" class="font-gray-dark right-inner-icons"
                            (click)="openRegionsDialog()" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <!--<p class="h2-expl" *ngIf="timePeriods.length > 1">&nbsp; ({{timePeriods.length}})</p>-->
                    </div>
                    <!--<b *ngIf="countriesSelected > 0" class="font-quick font-small pad-2-left">{{countriesSelected}}
                            countries</b>-->
                    <!--<p class="h2-expl font-gray pad-2-bottom">Select in which countries the selected companies must be
                        in.</p>-->
                    <ul class="ul-margin-1 ul-basic">
                        <li>
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [checked]="countriesService.isTypeRegionsSelected('Nordic')"
                                    (change)="selectNordicCountries($event.checked)">
                                </mat-checkbox>
                                <span class="w-9">Nordic</span>
                            </div>
                        </li>
                        <li>
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [checked]="countriesService.isTypeRegionsSelected('Non Nordic')"
                                    (change)="selectNonNordicCountries($event.checked)">
                                </mat-checkbox>
                                <span class="w-9">Non nordic</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-12 h-4  pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align  radius-0-bottom">
                <div class="w-12">
                    <!--<h2>Find saved list</h2>-->
                    <div class="pad-1-bottom column">
                        <h2>Company</h2>
                        <p class="h2-expl font-gray">Manage the company selection in the excel.</p>
                        <!--<p class="h2-expl" *ngIf="timePeriods.length > 1">&nbsp; ({{timePeriods.length}})</p>-->
                    </div>
                    <!--<div class="column">
                    <mat-checkbox [checked]="onlyCompanies" (change)="setOnlyCompanies()">Only Companies</mat-checkbox>
                    <mat-checkbox [checked]="lockCompanies" (change)="setLockCompanies()">Lock companies in sector</mat-checkbox>
                </div>-->
                    <ul class="ul-margin-1 ul-basic">
                        <li>
                            <div class="row y-align">
                                <!--<mat-checkbox class="w-1" [checked]="onlyCompanies" (change)="setOnlyCompanies()">
                                </mat-checkbox>
                                <span class="w-9">Alphabetically</span>-->
                                <!--Only companies-->
                                <!--<mat-radio-group class="w-12 column" [(ngModel)]="typeChoice">
                                    <mat-radio-button class="pad-1-bottom" [value]="alph">
                                        Alphabetically
                                    </mat-radio-button>
                                    <mat-radio-button class="pad-1-bottom" [value]="sector">
                                        Per sector
                                    </mat-radio-button>
                                </mat-radio-group>-->
                                <mat-checkbox class="w-1" [checked]="lockCompanies" (change)="setLockCompanies()">
                                </mat-checkbox>
                                <span class="w-9">Lock companies in sector as of today</span>
                            </div>
                        </li>
                        <li>
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [checked]="onlyCompanies" (change)="setOnlyCompanies()">
                                </mat-checkbox>
                                <span class="w-9">Show companies in alphabetic order</span>
                            </div>
                        </li>
                        <!--<li>
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [checked]="lockCompanies" (change)="setLockCompanies()">
                                </mat-checkbox>
                                <span class="w-9">Lock companies in sector</span>
                            </div>
                        </li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>