import { Injectable } from "@angular/core";
import { Filter } from "./model";
import { TableSelectionService } from "./table-selection.service";
import { Logger } from 'app/_helpers';

/** 
 * This service can be inherited by a component that want to use custom filtering
 * Note that the provided data structure must have a key called Name as well as a Type
*/
@Injectable()
export abstract class TableFilterService extends TableSelectionService implements CreateFilterAttributes {

    private _filterAttributes = [];

    constructor(logger: Logger) {
        super(logger);
        this.filterAttributes = this.createFilterAttributes();  // filtering colors
        this.dataSource.filterPredicate = this.filterPredicate; // custom filtering
        this.applyFilter("0000"); //to filter on default filtering options
    }

    abstract createFilterAttributes(): Array<Filter>;

    set filterAttributes(value: Array<Filter>){
        this._filterAttributes = value;
    }
    get filterAttributes(){
        return this._filterAttributes;
    }

    getFilterColor(val: string): string {
        if (val == null) return "standard";
        for (let filter of this.filterAttributes) {
            if (filter.Name.toLowerCase() == val.toLowerCase())
                return filter.Color;
        }
        return "standard";
    }

    applyFilter(filterValue: string) {
        if(filterValue != "")
            this.dataSource.filter = filterValue;
        else
            this.applyFilter("0000")
    }

    clickFilter(val: number) {
        this.filterAttributes[val].Choosen = !this.filterAttributes[val].Choosen;
        this.applyFilter("0000");
    }

    filterPredicate: ((data: any, filter: string) => boolean) = (data: any, filter: string): boolean => {
        filter = filter.trim();
        filter = filter.toLowerCase();

        const report = data.Name.trim().toLowerCase();
        var returnValue = false;
        var filterHit = false;
        for (let val of this.filterAttributes) {
            if (data.Type == val.Name) filterHit = true;
            if (val.Choosen && filterHit) {
                returnValue = true;
                if (filter == "0000") return true;
                break;
            }
        }

        if ((filter == "0000") && !filterHit) return true;

        if (!returnValue || (report.indexOf(filter) == -1)) returnValue = false;

        return returnValue;
    }

}

interface CreateFilterAttributes {
    createFilterAttributes();
}