import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SelectionModel } from "@angular/cdk/collections";

import { ApiService } from "../services/api.service";

import { HttpClient } from "@angular/common/http";
import { ResultService } from "../services/result.service";
import { TableFilterService } from "../../../Templates/styles/services/table-filter.service";
import {
  FilterColors,
  Filter,
  Company,
  SectorTree
} from "../../customsector.model";
import { AnnualquarterlyResultService } from "../../../1.Report/annualQuarterly/services/annualquarterly-result.service";
import { SelectedEquityCredit } from "../../../1.Report/report.model";
import { Logger } from 'app/_helpers';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatSidenav } from "@angular/material/sidenav";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";


@Component({
  selector: "customize",
  templateUrl: "../view/customize.component.html"
})
export class CustomizeComponent extends TableFilterService implements AfterViewChecked {
    @ViewChild('sidenav') sidenav: MatSidenav;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns = ['Id', 'Name', 'Underwork', 'IsEquity', 'IsCredit', 'Type'];

    isLoadingResults = true;
    isError = false;

    isLoadingTree: boolean;
    isErrorTree: boolean;
    tree: SectorTree;
    topSelected: boolean;
    treeTitle: string;

    filterList: Array<string> = [];

    selectionInPreviousStep: SelectedEquityCredit;
    isReadOnly: boolean;

    constructor(private changeRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder, private resultService: ResultService,
        private http: HttpClient, public dialog: MatDialog, private annualquarterlyService: AnnualquarterlyResultService,
        private api: ApiService, private auth: AuthorizeService, logger: Logger) {
        super(logger);
    }

    ngOnInit() {

        this.annualquarterlyService.selectedEquityCreditKeyvalue.subscribe(selected => {this.selectionInPreviousStep = selected; selected.Equity});
        this.auth.getUser().subscribe(u => {this.isReadOnly = u.name.indexOf('@handelsbanken.') < 0
        this.displayedColumns= this.isReadOnly? this.displayedColumns.filter(x=>x!='Underwork'): this.displayedColumns});

        // If the user changes the sort order, reset back to the first page.
        //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.resultService.keyValues.subscribe(data => {
            //this.resetSelectionDatasource(this.dataSource.data, data)
            this.selection = new SelectionModel<Company>(true, []);
            this.dataSource.data.forEach(element => {
                if(this.resultService.contains(element)){
                    this.selection.select(element);
                }
            });
        });

        this.getSectorCompanies([]);
        this.getSectorTree();

        //this.sidenav.close();
        this.treeTitle = "All";
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

    createFilterAttributes(): Array<Filter> {
        let filterAttributes = new Array<Filter>();
        filterAttributes.push({
            Name: "HCM coverage",
            Choosen: true,
            Color: FilterColors.Normal
        });
        filterAttributes.push({
            Name: "HCM light",
            Choosen: true,
            Color: FilterColors.HCMlight
        });
        filterAttributes.push({
            Name: "Not fully covered",
            Choosen: false,
            Color: FilterColors.Notfullcoverage
        });
        filterAttributes.push({
            Name: "Consensus",
            Choosen: false,
            Color: FilterColors.ImportOnly
        });
        filterAttributes.push({
            Name: "Deactivated", //kanske inte synas utåt?
            Choosen: false,
            Color: FilterColors.Deactivated
        });
        return filterAttributes;
    };

    treeUpdate(element: Array<number>) {
        this.getSectorCompanies(element);
        //this.paginator.pageIndex = 0
        this.sidenav.close()
    }

    setTreeTitle(title: string) {
        this.treeTitle = title;
    }

    changeResult(value: Company) {
        if(!this.resultService.contains(value))
            this.resultService.add(value);
        else
            this.resultService.delete(value);
    }

    changeAllResult() {
        if (this.selection.selected.length > 0)
            this.resultService.addAll(<Array<Company>>this.selection.selected);
        else
            this.resultService.deleteAll(this.dataSource.filteredData);
    }

    getSectorCompanies(array: Array<number>) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.api.getSectorCompanies(array);
                }),
                map(data => {
                    //this.resetSelectionDatasource(data, this.resultService.keyValues.source.value)
                    this.isLoadingResults = false;

                    this.selection = new SelectionModel<Company>(true, []);
                    (data as Company[]).forEach(element => {
                        if(this.resultService.contains(element)){
                            this.selection.select(element);
                        }
                    });
                    return data;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isError = true;
                    return [];
                })
            ).subscribe(data => {
                this.dataSource.data = data;
            });
    }

    getSectorTree() {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingTree = true;
                    return this.api.getSectorTree();
                }),
                map(data => {
                    this.isLoadingTree = false;
                    return data;
                }),
                catchError(() => {
                    this.isLoadingTree = false;
                    this.isErrorTree = true;
                    return [];
                })
            ).subscribe(data => this.tree = data);
    }

    showFilterRow(){
        // the filter row expl
            //category
        this.filterList = new Array<string>();
        if(this.treeTitle != "All") this.filterList.push(this.treeTitle)
    
            //type
        let filterTypeList = new Array<string>();
        this.filterAttributes.forEach(element => {
            if(element.Choosen)
                filterTypeList.push(element.Name)
        });
            //tesult
        if(filterTypeList.length != this.filterAttributes.length) 
            this.filterList = this.filterList.concat(filterTypeList);
    }

    //set the height of the table with all metrics, relative to the filter height
    tableHeight(){
        let filter = document.getElementById('table-filter').offsetHeight;
        return "calc(100% - " + filter + "px)"
    }
}
