import { Component } from '@angular/core';
import { List } from '../Templates/styles/services/model';
import { CustomsectorResultService } from './customsector-result.service';

@Component({
  selector: 'custom-sector',
  templateUrl: './customsector.component.html',
})
export class CustomSectorComponent {

  choice: string;
  headerList: Array<List>

  constructor(private customsectorService: CustomsectorResultService) { }

  ngOnInit() {
    this.choiceChange('Sector');
    this.headerList = [
      {Path: "Sector", Name: "Sector", Disabled: false},
      {Path: "CustomList", Name: "Custom list", Disabled: false}
    ]
  }

  choiceChange(test: string){
    this.choice = test;
    if(test == "Sector")
      this.customsectorService.setIsSector(true);
    else
      this.customsectorService.setIsSector(false);
  }
}