import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ResultService } from "./result.service";
import { ResultHeadline } from "../../customsector.model";

/* 
    public service that should ONLY have getters so other modules 
    (app.module, result.module etc.) can receive the necessary data
    without getting to much knowledge or controll
*/
@Injectable()
export class CustomlistResultService {

    private headlineSource: BehaviorSubject<boolean>;
    headline;

    private companiesSource: BehaviorSubject<boolean>;
    companies;

    constructor(private resultService: ResultService) {
        this.headlineSource = new BehaviorSubject<boolean>(false);
        this.headline = this.headlineSource.asObservable();

        this.companiesSource = new BehaviorSubject<boolean>(false);
        this.companies = this.companiesSource.asObservable();

        this.headlineCheck();
        this.companiesCheck();
    }

    private headlineCheck() {
        this.resultService.headline.subscribe(data => {
            if(data.Headline != null && data.Headline.length > 0)
                this.headlineSource.next(true);
            else
                this.headlineSource.next(false);
        });
    }

    private companiesCheck() {
        this.resultService.keyValues.subscribe(data => {
            if(data.length > 0)
                this.companiesSource.next(true);
            else
                this.companiesSource.next(false);
        });
    }

    public getCompanies(){
        return this.resultService.keyValues.source.value;
    }

    get getHeadline(): ResultHeadline{
        return this.resultService.headline.source.value;
    }
}