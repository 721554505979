import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class Logger {

    constructor(private logger: NGXLogger) {
        this.logger.setWithCredentialsOptionValue(true);
    }

    log(message: string, ...additional: any[]) {
        this.logger.log(message, additional);
    }

    debug(message: string, ...additional: any[]) {
        this.logger.debug(message, additional);
    }

    info(message: string, context?: any) {
        this.logger.info(message, context ? context : '');
    }

    warn(message: string, context?: any) {
        this.logger.warn(message, context ? context : '');
    }

    error(message: string, context?: any)
    {
        this.logger.error(message, context ? context : '');
    }

}
