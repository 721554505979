import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { List } from '../services/model';

@Component({
    selector: 'hb-header',
    templateUrl: '../view/header.component.html',
    styleUrls: ['../view/header.component.sass']
})
export class HeaderComponent {
    @Input() headline: string;
    @Input() data: Array<List>

    @Output() chosenPath: EventEmitter<string> = new EventEmitter<string>();
    
    choice: string;
    constructor() { }

    ngOnInit() {
        if(this.data != null)
            this.choice = this.data[0].Path;
    }

    ngOnChanges(changes: SimpleChanges) {}
}