import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';
import { LogoutComponent } from 'api-authorization/logout/logout.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthorizeGuard] },
    { path: 'logout', component: LogoutComponent }

    // otherwise redirect to home
    // { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
