import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable} from 'rxjs';

import { SectorTree } from 'app/2.CustomSector/customsector.model';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
  get baseUrlGrowApi() {
    return `${environment.baseUrlScreeningApi}v1/`;
  }

  constructor(
    private http: HttpClient
  ) {}

  getSectorTree(): Observable<SectorTree> {
    return this.http.get<SectorTree>(`${this.baseUrlGrowApi}sectors/tree`, {
      withCredentials: true
    });
  }

  getRegionTree(): Observable<any> {
    return this.http.get<any>(`${this.baseUrlGrowApi}regions`, {
      withCredentials: true
    });
  }
}
