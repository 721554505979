import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { TimePeriodsService } from "./timeperiods.service";
import { ResultService } from "./result.service";
import { CurrencyService } from "./currency.service";
import { SelectedEquityCredit, AGKeyValues, ResultHeadline } from "../../report.model";

/* 
    public service that should ONLY have getters so other modules 
    (app.module, result.module etc.) can receive the necessary data
    without getting to much knowledge or controll
*/
@Injectable()
export class AnnualquarterlyResultService {

    private headlineSource: BehaviorSubject<boolean>;
    headline;

    private keyValuesSource: BehaviorSubject<boolean>;
    keyValues;

    private timePeriodsSource: BehaviorSubject<boolean>;
    timePeriods;

    private selectedEquityCreditKeyvalueSource: BehaviorSubject<SelectedEquityCredit>;
    selectedEquityCreditKeyvalue;

    constructor(
        private tpService: TimePeriodsService,
        private resultService: ResultService,
        private currencyService: CurrencyService
    ) {
        this.headlineSource = new BehaviorSubject<boolean>(false);
        this.headline = this.headlineSource.asObservable();

        this.keyValuesSource = new BehaviorSubject<boolean>(false);
        this.keyValues = this.keyValuesSource.asObservable();

        this.timePeriodsSource = new BehaviorSubject<boolean>(false);
        this.timePeriods = this.timePeriodsSource.asObservable();

        this.selectedEquityCreditKeyvalueSource = new BehaviorSubject<SelectedEquityCredit>(new SelectedEquityCredit);
        this.selectedEquityCreditKeyvalue = this.selectedEquityCreditKeyvalueSource.asObservable();

        this.timePeriodsCheck();
        this.headlineCheck();
        this.keyValuesCheck();
        this.selectedCheck();
    }

    private timePeriodsCheck() {
        this.tpService.data.subscribe(data => {
            var contentExists = false;
            if (data.length > 0) {
                data.forEach(element => {
                    if (element.Content.length > 0 && element.Name != "")
                        contentExists = true;
                    else
                        contentExists = false;
                });
            }
            this.timePeriodsSource.next(contentExists);
        });
    }

    private headlineCheck() {
        this.resultService.headline.subscribe(data => {
            if (data.Headline != null && data.Headline.length > 0)
                this.headlineSource.next(true);
            else
                this.headlineSource.next(false);
        });
    }

    private keyValuesCheck() {
        this.resultService.keyValues.subscribe(data => {
            if (data.length > 0)
                this.keyValuesSource.next(true);
            else
                this.keyValuesSource.next(false);
        });
    }

    private selectedCheck() {
        this.resultService.keyValues.subscribe(data => {
            let selectedCredit = false;
            let selectedEquity = false;
            data.forEach(element => {
                if (!element.IsCredit) {
                    selectedEquity = true;
                    if (selectedCredit) return;
                }
                if (element.IsCredit) {
                    selectedCredit = true;
                    if (selectedEquity) return;
                }
            });
            let seq = new SelectedEquityCredit();
            seq.Credit = selectedCredit;
            seq.Equity = selectedEquity;
            this.selectedEquityCreditKeyvalueSource.next(seq);
        });
    }

    getKeyValues() {
        return this.resultService.keyValues.source.value;
    }

    getTimePeriods() {
        return this.tpService.data.source.value;
    }

    get timePeriodsDone(): boolean {
        return this.timePeriodsSource.getValue();
    }

    get getCurrency(): string {
        return this.currencyService.selected.source.value;
    }

    get getHeadline(): ResultHeadline {
        return this.resultService.headline.source.value;
    }
}