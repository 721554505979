export const environment = {
  production: true,
  applicationName: "#{Release.EnvironmentName}#-screening-frontend",
  hmr: false,
  baseUrlOidcConfig: "#{Ng.Authority}#",
  baseUrlScreeningApi: "#{Ng.BaseUrlScreeningApi}#",
  baseUrlReonApi: "#{Ng.BaseUrlReonApi}#",
  backUrl: "#{Ng.BaseUrlReon}#",
  appInsights: {
    instrumentationKey: '#{ApplicationInsights.InstrumentationKey}#'
  }
};
