<!--<div id="top-small">
    <div class="hQuestion" id="left">
        <h3>Manage time periods</h3>
        <span class="fa fa-question-circle-o"></span>
    </div>

    <div id="right">
        <button color="accent" mat-button (click)="addNewPeriod()" [disabled]="timePeriods[0].Content.length < 1">
            <span class="left-gray-text">Add new time period</span>
            <mat-icon>add</mat-icon>
        </button>

        <span class="text">Add new time period</span>
        <button mat-fab color="accent" (click)="addNewPeriod()" [disabled]="timePeriods[0].Content.length < 1">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>-->

<style>
    .mat-drawer-content {
        padding: 2px;
        min-height: calc(95vh - 120px);
    }

    .mat-drawer {
        padding: 6rem;
    }
</style>
<div class="h-12 w-12 background-white overflow-hidden">
    <button class="dialog-close" mat-icon-button (click)="onNoClick()" *ngIf="!sideOpen">
        <mat-icon>close</mat-icon>
    </button>

    <div class="background-gray pad-4" id="table-filter">
        <h1 class="pad-2-top pad-1-bottom">Manage time periods</h1>
        <p class="h1-expl pad-2-bottom">Create new time periods and edit them here. You can use both full
            year
            or quarterly data. The periods can then be set per metric in the metric list.</p>
        <button class="button-success" mat-raised-button (click)="addNewPeriod()" [disabled]="timePeriods[0].Content.length < 1">
            <mat-icon>add</mat-icon>
            <span class="left-gray-text">Add period</span>
        </button>
    </div>
    <!--<button color="accent" mat-button (click)="addNewPeriod()" [disabled]="timePeriods[0].Content.length < 1"
                            style="padding-left: 0">
                            <mat-icon>add</mat-icon>
                            <span class="left-gray-text">Add another time period</span>
                        </button>-->

    <div class="w-12 pad-4 overflow-y-scroll" [ngStyle]="{'height': tableHeight()}">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let value of timePeriods; let i = index" [expanded]="currentTimeperiodEditIndex === i">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b class="font-quick">{{value.Name}}</b>
                    </mat-panel-title>
                    <mat-panel-description>
                        <span [ngClass]="value.Content.length >= 1 ? '' : 'font-error'">{{value.Content.length}}
                            years</span>, periodic
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <time-periods-content [value]="value" [i]="i" (edit)="editTimeperiod($event)"></time-periods-content>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>

<!--<style>
    .mat-form-field {
        width: 8rem;
        margin-right: 20px;
    }

    .mat-slider-horizontal {
        width: 700px
    }

    .mat-tab-body-content {
        padding: 2rem !important
    }
</style>
<div class="spacer-half" id="managetimeperiods">
    <mat-tab-group [dynamicHeight]="true" (selectedTabChange)="tabChanged($event)" [selectedIndex]="currentTab.Index">
        <mat-tab label="{{value.Name}}" *ngFor="let value of timePeriods; let i = index">
            <time-periods-content [value]="value" [i]="i"></time-periods-content>
        </mat-tab>
    </mat-tab-group>
</div>-->