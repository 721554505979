import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { merge, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/map';

import { RegionTree, Country } from "../../customsector.model";
import { ApiService } from "./api.service";

@Injectable()
export class RegionsService {


    private sectorRegionSource: BehaviorSubject<Array<Country>>;
    sectorRegion;

    private isLoadingSource: BehaviorSubject<boolean>;
    isLoading;

    private isErrorSource: BehaviorSubject<boolean>;
    isError;

    private selectedRegionSource: BehaviorSubject<Array<Country>>;
    selectedRegion;

    constructor(private api: ApiService) {        

        this.sectorRegionSource = new BehaviorSubject<Array<Country>>([]);
        this.sectorRegion = this.sectorRegionSource.asObservable();

        this.isLoadingSource = new BehaviorSubject<boolean>(true);
        this.isLoading = this.isLoadingSource.asObservable();

        this.isErrorSource = new BehaviorSubject<boolean>(false);
        this.isError = this.isErrorSource.asObservable();

        this.selectedRegionSource = new BehaviorSubject<Array<Country>>([]);
        this.selectedRegion = this.selectedRegionSource.asObservable();
    }

    getRegionsTree() {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingSource.next(true);
                    return this.api.getRegionTree();
                }),
                map(data => {
                    this.isLoadingSource.next(false);
                    return this.parseRegions(data);
                }),
                catchError(() => {
                    this.isLoadingSource.next(false);
                    this.isErrorSource.next(true);
                    return [];
                })
            ).subscribe(data => {
                this.sectorRegionSource.next(data);
                this.setTypeRegions("Nordic");
            });
    }

    private parseRegions(data: any): Array<Country> {
        let array = new Array<Country>();
        data.RootNodes.forEach(element => {
            element.ChildRegions.sort(function (a, b) {
                return a.Name.localeCompare(b.Name);
            }).forEach(country => {
                let Name = this.capitalizeEachWord(country.Name.toLowerCase());
                array.push({ Id: country.Id, Name: Name, Type: element.Name })
            });
        });
        return array;
    }

    private capitalizeEachWord(str: string): string {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }

    setSelectedRegions(regions: Array<Country>) {
        this.selectedRegionSource.next(regions);
    }

    setTypeRegions(type: string, select: boolean = true) {
        let regions = this.sectorRegionSource.getValue().filter(x => x.Type == type)
        let selectedRegion = this.selectedRegionSource.getValue();
        if(select)
            this.selectedRegionSource.next(selectedRegion.concat(regions.filter(function (country) {
                return selectedRegion.indexOf(country) < 0;
            })));
        else
            this.selectedRegionSource.next(selectedRegion.filter(country => !regions.includes(country)))
    }

    isTypeRegionsSelected(type: string){
        let nordicregions = this.sectorRegionSource.getValue().filter(x => x.Type == type)
        let selectedRegion = this.selectedRegionSource.getValue();
        let selected =  selectedRegion.filter(country => nordicregions.includes(country))
        return selected.length == nordicregions.length
    }
}
