<!--<div class="hQuestion">
    <h3>Sector tree</h3>
    <mat-icon>help_outline</mat-icon>
</div>

<div class="error-reached" style="position: relative">
    <div class="child">
        <p>You're welcome to select sectors and countries, but don't expect to get any data in the result... Try 'Custom list'
            instead, it actually results in some data</p>
    </div>
</div>-->

<!--<div class="spacer-half">
    <mat-checkbox></mat-checkbox>
    <button mat-icon-button>
        <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button mat-icon-button>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
</div>-->

<!--<div class="spacer">
        <div class="row">
            <div class="col-sm-12">
                <mat-chip-list>
                    <mat-chip *ngFor="let filter of filterAttributes; let i = index" [ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'"
                        (click)="clickFilter(i, !filter.Choosen)">
                        {{filter.Name}}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>-->



<div class="h-12 w-12 overflow-y-scroll content">
    <div class="loading-shade" *ngIf="isLoadingTree">
        <mat-spinner *ngIf="isLoadingTree"></mat-spinner>
    </div>

    <div class="column loading-shade background-white" *ngIf="isErrorTree">
        <h1 class="font-error pad-4-bottom">Error!</h1>
        <p class="pad-2-bottom">Ops, something went wrong. Be so kind and try to retriew the data again</p>
        <button class="button" mat-raised-button (click)="getSectorTree(); isErrorTree=false">Try again</button>
    </div>

    <sector-tree [data]="tree" [topNode]="true" (treeOutput)="treeOutput($event)"></sector-tree>
</div>