import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportComponent } from './report.component';
import { AnnualQuarterlyModule } from './annualQuarterly/annualquarterly.module';
import { StyleTemplatesModule } from 'app/Templates/styles/styletemplates.module';
import { MaterialImportModule } from 'material/materialimport.module';

@NgModule({
  declarations: [
    ReportComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    AnnualQuarterlyModule,
    StyleTemplatesModule
  ],
  exports: [
    ReportComponent
  ]
})
export class ReportModule { }