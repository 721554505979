import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { MaterialImportModule } from "material/materialimport.module";

import { ContentComponent, RegionsDialog, SectorComponent } from "./components";
import { ApiService,
    OptionsService,
    RegionsService,
    ResultService,
    SectorsResultService
 } from "./services";
import { StyleTemplatesModule } from "../../Templates/styles/styletemplates.module";

@NgModule({
    declarations: [
        SectorComponent,
        ContentComponent,
        RegionsDialog
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        MaterialImportModule,
        HttpClientModule,
        StyleTemplatesModule
    ],
    entryComponents: [
        RegionsDialog
    ],
    exports: [
        SectorComponent
    ],
    providers: [
        ApiService,
        ResultService,
        RegionsService,
        OptionsService,
        SectorsResultService
    ]
})
export class SectorModule { }