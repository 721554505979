export class AggregatedReportCollection
{
    Id: number;
    Name: string;
    StatusId: number;
    Type: string;
    UserName: string;
    CreatedDate: Date;
    Currency:string;
}

export class Filter 
{
    Name: string;
    Choosen: boolean;
    Color: string;
}

export class AggTree
{
    Name: string;
    Info: AGKeyValues;
    Element: AggTree[];

    expanded: boolean = false;
}

export interface AGKeyValues
{
    //Id: string;
    Mnemonic: string;
    XmlId: string;
    Name: string;
    Type: string;
    Formula: string;
    TimePeriodId: number;
    IsCredit: boolean;
}

export class ResultHeadline
{
    Headline: string;
    ReportId: number;
    Author: string;
}

export class TimePeriod
{
    Name: string;
    NameId: number;
    Content: TimePeriodContent[];
}

export class TimePeriodContent
{
    Year: number;
    Q1: boolean;
    Q2: boolean;
    Q3: boolean;
    Q4: boolean;
    Annual: boolean;
}

export interface Tab {
    Name: string,
    Index: number
}

export enum FilterKeyValuesColors 
{
    Rawvalue = 'iris',
    Formula = 'teal',
    NonPeriodic = 'salmon',
    Concensus = 'mint',
    Standard = 'standard',
    TechIndicator= 'butternut'
}

export class SelectedEquityCredit
{
    Equity: boolean;
    Credit: boolean;
}
