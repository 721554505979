import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, } from 'rxjs';
import {
  Aggregated,
  AggregatedSector,
  AggregatedExcel
} from "../model";
import { AuthorizeService } from 'api-authorization/authorize.service';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {

  get baseUrlReonApi() {
    return environment.baseUrlReonApi;
  }

  constructor(
    private http: HttpClient,
    private authorize: AuthorizeService
  ) {}

  postResult(data: Aggregated): Observable<{}> {
    console.log("postResult", data);
    return this.http.post(
      `${this.baseUrlReonApi}aggregatedreport/gettabledata`,
      data,
      { withCredentials: true }
    );
  }

  postResultSector(data: AggregatedSector): Observable<any> {
    console.log("postResultSector", data);
    return this.http.post(
      `${this.baseUrlReonApi}aggregatedreport/postsector`,
      data,
      { withCredentials: true }
    );
  }

  createExcel(data: AggregatedExcel): Observable<any> {
    console.log("createExcel", data);

    return Observable.create(observer => {

      this.authorize.getAccessToken().toPromise()
        .then((accessToken: string) => {
          let xhr = new XMLHttpRequest();

          xhr.open(
            "POST",
            `${this.baseUrlReonApi}aggregatedreport/CreateExcel`,
            true
          );
          xhr.setRequestHeader("Content-type", "application/json");
          xhr.responseType = "blob";
    
          xhr.setRequestHeader("Authorization", "bearer " + accessToken);
          
          xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                var contentType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                var blob = new Blob([xhr.response], { type: contentType });
                var header = xhr.getResponseHeader('Content-Disposition');
                observer.next({blob: blob, header: header});
                observer.next(blob);
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          };
          xhr.send(JSON.stringify(data));
        });

     
    });
  }
}
