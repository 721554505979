import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ResultService } from "./result.service";
import { RegionsService } from "./regions.service";
import { SectorFilter } from "../../../datastructure.model";
import { OptionsService } from "./options.service";

/* 
    public service that should ONLY have getters so other modules 
    (app.module, result.module etc.) can receive the necessary data
    without getting to much knowledge or controll
*/
@Injectable()
export class SectorsResultService {

    private sectorsSource: BehaviorSubject<boolean>;
    sectors;

    private regionsSource: BehaviorSubject<boolean>;
    regions;

    private filterSource: BehaviorSubject<boolean>;
    filter;

    constructor(private regionService: RegionsService, private resultService: ResultService, private optionsService: OptionsService) {
        this.sectorsSource = new BehaviorSubject<boolean>(false);
        this.sectors = this.sectorsSource.asObservable();

        this.regionsSource = new BehaviorSubject<boolean>(false);
        this.regions = this.regionsSource.asObservable();

        this.filterSource = new BehaviorSubject<boolean>(false);
        this.filter = this.filterSource.asObservable();

        this.regionsCheck();
        this.sectorsCheck();
        this.filterCheck();
    }

    private regionsCheck() {
        this.regionService.selectedRegion.subscribe(data => {
            var contentExists = false;
            if (data.length > 0) {
                contentExists = true;
            }
            this.regionsSource.next(contentExists);
        });
    }

    private sectorsCheck() {
        this.resultService.sectorList.subscribe(data => {
            if(data.length > 0)
                this.sectorsSource.next(true);
            else
                this.sectorsSource.next(false);
        });
    }

    private filterCheck() {
        let filter = new SectorFilter();
        this.optionsService.filter.subscribe(data => filter = data);
        if(filter.HCMCovered || filter.NotFullyCovered || filter.Consensus)
            this.filterSource.next(true);
    }

    public getSectorList(){
        return this.resultService.sectorList.source.value;
    }

    public getCountries(){
        return this.regionService.selectedRegion.source.value;
    }

    public getFilter(){
        return this.optionsService.filter.source.value;
    }
}