import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
  get baseUrlGrowApi() {
    return `${environment.baseUrlScreeningApi}v1/`;
  }

  constructor(
    private http: HttpClient
  ) {}

  getCurrencies(): Observable<Array<string>> {
    return this.http.get<Array<string>>(
      `${this.baseUrlGrowApi}screening/currencies`,
      { withCredentials: true }
    );
  }
}
