<div class="h-12 background-white">
    <div class="loading-shade background-gray radius-3 z-11" *ngIf="apiCallStates.isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="loading-shade background-white radius-3 z-11 column" *ngIf="apiCallStates.isSuccess">
        <mat-icon style="font-size: 15rem; height: 15rem; width: 15rem;" class="font-success">done</mat-icon>
        <h1 class="font-success pad-2-top">Report saved!</h1>
    </div>

    <div class="column loading-shade background-white pad-4-side radius-3 z-11" *ngIf="apiCallStates.isError">
        <h1 class="font-error pad-4-bottom">Error!</h1>
        <p class="pad-2-bottom">{{apiCallStates.errorMessage}}</p>
        <button class="button" mat-raised-button (click)="apiCallStates.isError=false; emitApiCallStates()">Try again</button>
    </div>

    <div class="background-gray pad-4-side pad-4-top pad-3-bottom grid" id="table-filter">
        <h1 class="pad-2-top">Save metric list</h1>
   </div>

    <div class="pad-4-side pad-2-top pad-4-bottom grid">

        <form class="w-12 pad-2-bottom">
            <mat-form-field class="w-12" appearance="standard">
                <input matInput autocomplete="off" required placeholder="Name" value="{{headlineWritten}}" #input
                    (keyup)="headlineWritten = input.value">
            </mat-form-field>
        </form>

        <mat-radio-group class="w-12 pad-2-bottom column" [(ngModel)]="typeChoice">
            <mat-radio-button class="pad-1-bottom" *ngFor="let type of types" [value]="type">
                {{type}}
            </mat-radio-button>
        </mat-radio-group>

        <div class="w-12 pad-2-top y-align-end">
            <div class="column">
                <button mat-raised-button class="button-second mar-2-bottom" [disabled]="headlineWritten == ''" (click)="save(true)"
                    *ngIf="headline.ReportId != null && headline.Author == currentUser">update {{headline.Headline}}</button>
                <button class="button-success" mat-raised-button [disabled]="headlineWritten == ''" (click)="save(false)">Save
                    as a new report</button>
            </div>
        </div>

    </div>
</div>