<style>
    .outer {
        /*position: relative*/
        padding: 2rem;
        padding-bottom: 0;
    }

    .inner {
        /*width: 100%;
            margin-left: 10rem;*/
        position: relative;
        background: #fff;
        z-index: 9;
        padding: 1rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        box-shadow: 0 10px 30px rgba(190, 190, 190, 0.2);
    }

    table {
        border-collapse: collapse;
        /*max-width: 100%;*/
        /*height: 100vh;*/
        max-width: calc(100vw - 5rem);
        overflow: auto;
        display: block;
        background-color: #fff;
    }

    thead,
    tbody {
        display: block;
    }

    tbody {
        overflow-y: overlay;
        overflow-x: hidden;
        height: calc(100% - 70px)
    }

    td,
    th {
        /*padding: 1rem;*/
        /*width: 100px;*/
        min-width: 10rem;
        max-width: 10rem;
        height: 3rem;
        /*word-break: break-word;*/
        padding: 0.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        z-index: 10
    }

    /*.hover-scroll:hover span {
        margin-left: -300px;
    }*/

    .hover-scroll {
        width: 100%;
        height: 100%;
    }

    .hover-scroll span, .hb-blue {
        -webkit-transition: 1s;
        -moz-transition: 1s;
        transition: 1s;
    }

    table thead tr th:first-child,
    table tbody tr td:first-child {
        position: relative;
        z-index: 11;
        background-color: #fff;
    }

    .hoverShow:hover {
        /*background-color: #005FA0 !important;*/
        background-color: rgba(245, 245, 245, 1);
        cursor: pointer;
        /*color: #fff;
        z-index: 99px;*/
    }

    .y-head {
        min-width: 18rem;
        font-weight: bold;
        color: #000 !important
        /*background-color: rgba(255,255,255,0.93) !important;*/
    }

    .x-head {
        font-weight: bold;
        padding-bottom: 1.5rem;
    }

    table .col1 {
        position: absolute;
        *position: relative;
        /*ie7*/
        left: 0;
        width: 10rem;
    }
</style>

<div class="outer">
    <div class="inner">
        <table #resultTable *ngIf="tableData != null && tableData[0].Companies.length > 0">
            <thead id="thead">
                <tr>
                    <th class="y-head"></th>
                    <th style="padding: 1rem" *ngFor="let keyvalue of tableData; let i = index" [ngClass]="i % 2 == 0 ? 'lightblue' : ''" [attr.colspan]="keyvalue.Companies[0].Serie.length">
                        <div class="hover-scroll">
                            <span (mouseenter)="stringWidthIn($event)" (mouseleave)="stringWidthOut($event)">{{keyvalue.Name}}</span>
                        </div>
                    </th>
                    <td style="min-width: 2rem; max-width: 2rem;"></td> <!--to prevent the scrollbar to hide content-->
                </tr>
                <tr>
                    <th class="y-head"></th>
                    <ng-container *ngFor="let keyvalue of tableData; let keyvalueIndex = index">
                        <ng-container *ngIf="keyvalue.Companies[0].Serie.length > 0">
                            <th [ngClass]="keyvalueIndex % 2 == 0 ? 'lightblue' : ''" style="text-align:center" *ngFor="let serie of keyvalue.Companies[0].Serie"
                                class="x-head">
                                <ng-container *ngIf="serie.Year >= 1990">
                                    {{serie.Year}}
                                    <br/>
                                    <span *ngIf="serie.Quarter == null">
                                        Y
                                    </span>
                                    <span *ngIf="serie.Quarter != 0 && serie.Quarter != null">
                                        Q{{serie.Quarter}}
                                    </span>
                                </ng-container>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="keyvalue.Companies[0].Serie.length == 0">
                            <td [ngClass]="keyvalueIndex % 2 == 0 ? 'lightblue' : ''" style="text-align:center"></td>
                        </ng-container>
                    </ng-container>
                    <td style="min-width: 2rem; max-width: 2rem;"></td> <!--to prevent the scrollbar to hide content-->
                </tr>
            </thead>
            <tbody id="tbody" [style.maxHeight]="tableHeight()"> <!--[style.maxHeight]="tableHeight()"-->
                <tr *ngFor="let company of tableData[0].Companies; let companyIndex = index" class="hoverShow" (click)="markCompany(company.Id)"
                    [ngClass]="this.markedCompanies.includes(company.Id) ? 'hb-blue' : ''">
                    <td class="y-head">{{company.Name}}</td>
                    <ng-container *ngFor="let keyvalue of tableData; let keyvalueIndex = index">
                        <ng-container *ngIf="keyvalue.Companies[companyIndex].Serie.length > 0">
                            <td [ngClass]="keyvalueIndex % 2 == 0 ? 'lightblue' : ''" style="text-align:center" *ngFor="let datapoint of keyvalue.Companies[companyIndex].Serie">
                                <div class="hover-scroll">
                                    <span (mouseenter)="stringWidthIn($event)" (mouseleave)="stringWidthOut($event)">{{datapoint.FormattedValue}}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="keyvalue.Companies[companyIndex].Serie.length == 0">
                            <td [ngClass]="keyvalueIndex % 2 == 0 ? 'lightblue' : ''" style="text-align:center">
                                <div class="hover-scroll">
                                    <span style="font-size: 1.1rem; font-style: italic;">nomatch</span>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                    <td style="min-width: 2rem; max-width: 2rem;"></td> <!--to prevent the scrollbar to hide content-->
                </tr>
            </tbody>
        </table>
        <div class="spacer" style="margin-left: 30px; font-weight: bold" *ngIf="tableData != null && tableData[0].Companies.length < 1">
            The genereated data didn't contain any companies. Most probably because of the selected countries, add some countries and try again!
        </div>
    </div>
</div>