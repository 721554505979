<div id="top">
    <!--<div id="left">
        <h2>{{headline}}</h2>
    </div>-->
    <div id="middle">
        <!--<mat-radio-group class="radio-group" [(ngModel)]="choice">
            <mat-radio-button class="radio-button" *ngFor="let item of data" value="{{item.Path}}" (change)="this.chosenPath.emit(item.Path)"
                [disabled]="item.Disabled">{{item.Name}}</mat-radio-button>
        </mat-radio-group>-->
        <button mat-button *ngFor="let item of data" (click)="this.chosenPath.emit(item.Path); choice = item.Path" [disabled]="item.Disabled" [ngClass]="choice == item.Path ? 'chosen' : ''">
            {{item.Name}}
        </button>
    </div>
    <!--<div id="right">-->
    <!--<span class="text">Help me out</span>-->
    <!--<button mat-fab color="accent" (click)="openDialog()">
            <mat-icon>date_range</mat-icon>
        </button>
        <button mat-fab color="accent" (click)="openDialog()">
            <mat-icon>date_range</mat-icon>
        </button>-->
    <!--<button mat-icon-button>
            <mat-icon aria-label="Help" style="color:lightblue">help</mat-icon>
        </button>-->
    <!--</div>-->
</div>