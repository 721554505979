<ng-container *ngIf="value != null && i != null">
    <!--<div style="display: flex; align-items: center;">
        <form>
            <mat-form-field style="width:20rem">
                <h4>
                    <textarea matInput matTextareaAutosize value="{{value.Name}}" #nameInput (keyup)="changeName(nameInput.value, i)" [disabled]="true"></textarea>
                    [disabled]="i == 0"
                </h4>
                <mat-hint *ngIf="nameError || value.Name < 2" class="error-hint">
                    The period name must be unique and contain characters
                </mat-hint>
            </mat-form-field>
        </form>
        <button color="accent" mat-icon-button [disabled]="i == 0" (click)="deletePeriod()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div class="spacer-half">
        <section class="example-section">
            <mat-checkbox class="example-margin" #q1 (click)="chosen(!q1.checked)">Q1</mat-checkbox>
            <mat-checkbox class="example-margin" #q2 (click)="chosen(!q2.checked)">Q2</mat-checkbox>
            <mat-checkbox class="example-margin" #q3 (click)="chosen(!q3.checked)">Q3</mat-checkbox>
            <mat-checkbox class="example-margin" #q4 (click)="chosen(!q4.checked)">Q4</mat-checkbox>
            <mat-checkbox class="example-margin" #qy [checked]="'true'" (click)="chosen(!qy.checked)">Annual</mat-checkbox>
        </section>
    </div>

    <div style="display: flex; flex-direction: row; align-items: flex-end; justify-content: space-between">
        <div style="width: 70%; display: block">
            <nouislider [connect]="true" [min]="min" [max]="max" [step]="step" [tooltips]="[true, true]" [(ngModel)]="date"></nouislider>
        </div>

        <span matTooltip="Add to time period list" [matTooltipPosition]="'above'" [matTooltipDisabled]="nmrChosen == 0">
            <button mat-mini-fab style="float:right" [disabled]="nmrChosen == 0" (click)="addPeriod(q1.checked, q2.checked, q3.checked, q4.checked, qy.checked)">
                <mat-icon>playlist_add</mat-icon>
            </button>
        </span>

    </div>-->

    <div class="row pad-2-top">
        <div class="w-6 column">
            <div class="font-gray font-quick font-small pad-3-bottom">Edit:</div>
            <nouislider class="w-10" [connect]="true" [min]="min" [max]="max" [step]="step" [tooltips]="[true, true]" 
                (ngModelChange)="addPeriod(q1.checked, q2.checked, q3.checked, q4.checked, qy.checked, $event)" [(ngModel)]="date"></nouislider>
            <section class="pad-2-top pad-4-bottom">
                <mat-checkbox class="pad-1-right" #q1 (click)="chosen(!q1.checked)">Q1</mat-checkbox>
                <mat-checkbox class="pad-1-right" #q2 (click)="chosen(!q2.checked)">Q2</mat-checkbox>
                <mat-checkbox class="pad-1-right" #q3 (click)="chosen(!q3.checked)">Q3</mat-checkbox>
                <mat-checkbox class="pad-1-right" #q4 (click)="chosen(!q4.checked)">Q4</mat-checkbox>
                <mat-checkbox class="pad-1-right" #qy [checked]="true" (click)="chosen(!qy.checked)">FY</mat-checkbox>
            </section>

            <div class="pad-2-bottom" *ngIf="i > 0">
                <button class="button" mat-raised-button (click)="deletePeriod()">
                    <mat-icon>delete</mat-icon> Delete
                </button>
            </div>
        </div>

        <!--<button mat-icon-button (click)="emitEdit()">
        <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button [disabled]="i == 0" (click)="deletePeriod()">
        <mat-icon>delete</mat-icon>
    </button>-->

        <ng-container *ngIf="value.Content.length > 0">
            <table class="table w-6">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                        <th>FY</th>
                        <th>
                            <button mat-icon-button (click)="clearRows()">
                                <mat-icon>delete_sweep</mat-icon>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of value.Content">
                        <td>{{item.Year}}</td>
                        <td>
                            <mat-checkbox [checked]="item.Q1" (change)="item.Q1 = !item.Q1"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox [checked]="item.Q2" (change)="item.Q2 = !item.Q2"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox [checked]="item.Q3" (change)="item.Q3 = !item.Q3"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox [checked]="item.Q4" (change)="item.Q4 = !item.Q4"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox [checked]="item.Annual" (change)="item.Annual = !item.Annual"></mat-checkbox>
                        </td>
                        <td>
                            <button mat-icon-button (click)="deleteRow(item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</ng-container>