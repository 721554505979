<!-- <div class="spacer">
    <button mat-button class="button-big">
        <i class="fa fa-calendar-o"></i> Manage time periods</button>
</div>

<div class="hQuestion">
    <h3>Key values</h3>
    <span class="fa fa-question-circle-o"></span>
</div>

<div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div> -->

<mat-sidenav-container (backdropClick)="sidenav.close()" class="h-12 content">
    <mat-sidenav #sidenav disableClose="true" position='end' mode='over' class="h-12 overflow-y-hidden">
        <div class="pad-4-side pad-4-top">
            <h2>Filter list</h2>
            <p class="h2-expl">Select what you want to be shown in the table</p>
        </div>
        <div class="overflow-y-scroll w-12" style="height: calc(100% - 177px)">
            <h3 class="pad-4-side pad-2-top pad-2-bottom">Type</h3>
            <div class="column pad-4-side">
                <ul class="ul-margin-1 ul-basic">
                    <li *ngFor="let filter of filterAttributes; let i = index">
                        <!--[ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'" (click)="clickFilter(i); searchTerm.value=''"-->
                        <div class="row y-align">
                            <div class="w-1"></div>
                            <mat-checkbox class="w-2" (change)="clickFilter(i); searchTerm.value=''" [checked]="filter.Choosen">
                            </mat-checkbox>
                            <div class="w-1">
                                <div class="circle-small" [ngClass]="getFilterColor(filter.Name)"></div>
                            </div>
                            <span class="w-8">{{filter.Name}}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <h3 class="pad-4-side pad-4-top pad-2-bottom">Category</h3>
            <div class="pad-4-side">
                <sector-tree *ngIf="tree" [data]="tree" [radioButton]="true" [topNode]="true" (listOutput)="treeUpdate($event)"
                    (titleOutput)="setTreeTitle($event)"></sector-tree>
            </div>
        </div>
        <div class="pad-4-side pad-4-top pad-1-bottom">
            <button class="w-12 button-success mar-2-top" (click)="sidenav.close(); showFilterRow()" mat-raised-button>
                <mat-icon>done</mat-icon>&nbsp; Show {{dataSource.filteredData.length}} metrics
            </button>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="loading-shade background-gray" *ngIf="isLoadingResults">
            <mat-spinner></mat-spinner>
        </div>

        <!--<div class="spacer">
            <div class="hQuestion">
                <h3>Company list</h3>
                <mat-icon>help_outline</mat-icon>
            </div>
            <button mat-button (click)="sidenav.open()" style="padding-left: 0">
                <mat-icon color="accent">filter_list</mat-icon>
                <span class="left-gray-text">{{treeTitle}}</span>
            </button>
        </div>-->

        <div class="column loading-shade background-gray" *ngIf="isError">
            <h1 class="font-error pad-4-bottom">Error!</h1>
            <p class="pad-2-bottom">Ops, something went wrong. Be so kind and try to retriew the data again</p>
            <button class="button" mat-raised-button (click)="getAggregatedReports(); isError=false">Try again</button>
        </div>

        <div style="display: grid" id="table-filter">
            <div class="y-align">
                <div class="w-10">
                    <mat-form-field class="w-7">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search metric"
                            #searchTerm>
                        <button mat-button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="applyFilter('0000'); searchTerm.value=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="w-2">
                    <button mat-button (click)="sidenav.open()" style="float: right; margin-right: 1rem">
                        <span class="font-quick font-small">Filter list</span>
                        <!--{{treeTitle}}-->
                        <mat-icon color="accent">filter_list</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row w-12 pad-2-bottom">
                <span *ngIf="filterList.length > 0" class="font-quick font-small">Filtered on:&nbsp;&nbsp;</span>
                <span *ngFor="let filter of filterList; let i = index" class="font-small">
                    <span>{{filter}}<span *ngIf="filterList.length - 1 > i">,&nbsp;</span></span>
                </span>
            </div>
        </div>

        <div [ngStyle]="{'height': tableHeight()}">
            <mat-table #table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="Id">
                    <mat-header-cell *matHeaderCellDef [ngClass]="'column-small'" style="overflow:initial;">
                        <ng-container *ngIf="treeTitle != 'All' && !filterAttributes[4].Choosen">
                            <mat-checkbox (change)="$event ? masterToggle() : null; changeAllResult();" [checked]="selection.hasValue() && isAllSelected(dataSource.filteredData)"
                                [indeterminate]="selection.hasValue() && !isAllSelected(dataSource.filteredData)">
                            </mat-checkbox>
                        </ng-container>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" style="overflow:initial;" [ngClass]="'column-small'">
                        <mat-checkbox *ngIf="row.Type != 'Deactivated'" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null; changeResult(row);"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Underwork">
                    <mat-header-cell *matHeaderCellDef [ngClass]="'column-bool'"> UW </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-bool'">
                        <mat-icon *ngIf="(selectionInPreviousStep.Equity && element.ExistsEquityUnderwork) 
                                    || (selectionInPreviousStep.Credit && element.ExistsCreditUnderwork)">
                            work_outline {{selectionInPreviousStep.Equity}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="IsEquity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-bool'"> Equity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-bool'">
                        <mat-icon *ngIf="element.IsEquity">check</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="IsCredit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-bool'"> Credit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-bool'">
                        <mat-icon *ngIf="element.IsCredit">check</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="Type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-xlarge'"> Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-xlarge'">
                        <div class="row y-align">
                            <div class="circle-small mar-1-right" [ngClass]="getFilterColor(element.Type)"></div>
                            <div class="color-gray">{{element.Type}}</div>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <!--<mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[20, 50, 100]" [showFirstLastButtons]="true">
            </mat-paginator>-->
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>