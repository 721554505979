import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { merge, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/map';

import { ApiService } from "./api.service";

@Injectable()
export class CurrencyService {
  private currenciesSource: BehaviorSubject<Array<string>>;
  currencies;
  private isLoadingSource: BehaviorSubject<boolean>;
  isLoading;
  private isErrorSource: BehaviorSubject<boolean>;
  isError;

  private selectedSource: BehaviorSubject<string>;
  selected;

  constructor(private http: HttpClient, private api: ApiService) {
    this.currenciesSource = new BehaviorSubject<Array<string>>([]);
    this.currencies = this.currenciesSource.asObservable();

    this.isLoadingSource = new BehaviorSubject<boolean>(false);
    this.isLoading = this.isLoadingSource.asObservable();

    this.isErrorSource = new BehaviorSubject<boolean>(false);
    this.isError = this.isErrorSource.asObservable();

    this.selectedSource = new BehaviorSubject<string>("");
    this.selected = this.selectedSource.asObservable();

    this.fetch();
  }

  private fetch() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.api.getCurrencies();
        }),
        map(data => {
          this.isLoading = false;
          this.isError = false;
          return data;
        }),
        catchError(() => {
          this.isLoading = false;
          this.isError = true;
          return [];
        })
      )
      .subscribe(data => this.currenciesSource.next(data));
  }

  setSelected(currency: string) {
    this.selectedSource.next(currency);
  }

  getSelected(){
    return this.selectedSource.getValue();
  }
}
