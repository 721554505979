import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialImportModule } from '../../../material/materialimport.module'
import { HeaderComponent } from './components/header.component';
import { SectorTreeComponent } from './components/sectortree.component';
import { TableSelectionService } from './services/table-selection.service';
import { SaveComponent } from './components/save.component';
import { WhoamiService } from './services/whoami.service';
import { ExistingSearchtBox } from './components/existingSearchBox.component';
import { ParseFilename } from './services/parse-filename';

@NgModule({
  declarations: [
    HeaderComponent,
    SectorTreeComponent,
    SaveComponent,
    ExistingSearchtBox
  ],
  imports: [
    MaterialImportModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    SectorTreeComponent,
    SaveComponent,
    ExistingSearchtBox
  ],
  providers: [
    TableSelectionService,
    WhoamiService,
    ParseFilename
  ]
})
export class StyleTemplatesModule { }