<button class="dialog-close" mat-icon-button (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>

<div style="width:100%; height:100%; display: flex; align-items: center; justify-content: center;">
  <div class="loading-shade background-gray" *ngIf="isLoading" style="z-index: 999999;">
    <mat-spinner></mat-spinner>
  </div>

  <div class="column loading-shade background-white" *ngIf="isError">
    <h1 class="font-error pad-2-bottom">Error!</h1>
    <p class="pad-2-bottom">It's not you it's the file!</p>
    <p class="pad-2-bottom">{{ isErrorMessage }}</p>
    <button class="button" mat-raised-button (click)="isError=false">Try again</button>
  </div>

  <div style="text-align: center">
    <h1 class="pad-3-bottom">Update an existing report</h1>

    <div *ngIf="fileInfo.length <= 0">
      <button class="button-primary" mat-raised-button (click)="fileInput.click()">
        <mat-icon>library_add</mat-icon>
        <span>Upload an excel file</span>
        <input #fileInput type="file" (change)="onFileChange($event)" style="display:none;" accept=".xlsx, .xlsm" />
      </button>
    </div>

    <div *ngIf="fileInfo != undefined && fileInfo.length > 0" class="spacer-half">
      <button mat-icon-button style="color:rgba(0, 0, 0, 0.26)" (click)="resetFileInfo()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon>
      </button>

      <p class="pad-2-bottom">
        Select one or more of the updatable sheets
      </p>

      <ul class="spacer-half" style="padding: 5px; max-height: 60vh; overflow-y: scroll">
        <li *ngFor="let element of fileInfo; let i = index" style="list-style: none; width: 300px;"
          [ngStyle]="!element.IsUpdatable && {'opacity': '0.3'}">
          <mat-card [ngStyle]="!element.IsUpdatable && {'background': '#fff'}">
            <mat-card-content>
              <mat-checkbox [disabled]="!element.IsUpdatable" (change)="selectedSheet(i)"
                [checked]="selectedSheets.includes(i)"></mat-checkbox>
              <div class="spacer-half">
                <p style="font-size: 0.9rem; color: gray">Sheet nmr. {{i}} <br /><i>({{element.Visibility}})</i></p>
                <p style="line-height: 1.1; font-size: 1.4rem; font-weight: bold">
                  {{element.Name}}
                </p>
                <button mat-icon-button style="color:rgba(0, 0, 0, 0.26)">
                  <mat-icon aria-label="Example icon-button with a heart icon">expand_more</mat-icon>
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </li>
      </ul>

      <div class="pad-2-top">
        <button class="button-success" mat-raised-button (click)="updateExcel()" [disabled]="selectedSheets.length < 1">
          <mat-icon aria-label="">cloud_download</mat-icon> Update excel
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  .mat-card {
    padding: 18px;
    background: rgba(225, 240, 245, 0.3);
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
</style>