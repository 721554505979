import { Component } from "@angular/core";

import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { ApiService } from "../services/api.service";
import { SectorTree, Filter, FilterColors } from "../../customsector.model";
import { ResultService } from "../services/result.service";
import { RegionsService } from "../services/regions.service";
import { SectorFilter } from "../../../datastructure.model";
import { OptionsService } from "../services/options.service";

@Component({
    selector: 'content',
    templateUrl: '../view/content.component.html',
})
export class ContentComponent {

    isLoadingTree: boolean;
    isErrorTree: boolean;
    tree: SectorTree;
    topSelected: boolean;
    filterAttributes: Array<Filter>;

    constructor(private apiService: ApiService, private resultService: ResultService, private regionsService: RegionsService,
        private optionsService: OptionsService){}

    ngOnInit(){
        this.getSectorTree();
        this.regionsService.getRegionsTree();
        this.topSelected = false;
        this.optionsService.filter.subscribe(data => this.filterAttributes = this.createFilterAttributes(data));
    }

    getSectorTree(){
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingTree = true;
                    return this.apiService.getSectorTree();
                }),
                map(data => {
                    this.isLoadingTree = false;
                    return data;
                }),
                catchError(() => {
                    this.isLoadingTree = false;
                    this.isErrorTree = true;
                    return [];
                })
            ).subscribe(data => this.tree = data);
    }

    treeOutput(tree: Array<SectorTree>){
        this.resultService.setSectorTree(tree);
    }

    createFilterAttributes(filterData: SectorFilter): Array<Filter> {
        let filterAttributes = new Array<Filter>();
        filterAttributes.push({
            Name: "HCM coverage",
            Choosen: filterData.HCMCovered,
            Color: FilterColors.Normal
        });
        filterAttributes.push({
            Name: "Not fully covered",
            Choosen: filterData.NotFullyCovered,
            Color: FilterColors.Notfullcoverage
        });
        filterAttributes.push({
            Name: "Consensus",
            Choosen: filterData.Consensus,
            Color: FilterColors.ImportOnly
        });
        return filterAttributes;
    };

    // Depends on the order in the function above
    clickFilter(index: number, chosen: boolean){
        if(index == 0)
            this.optionsService.setFilter(chosen);
        if(index == 1)
            this.optionsService.setFilter(null, chosen)
        if(index == 2)
            this.optionsService.setFilter(null, null, chosen)
    }
}