export class AggregatedReportCollection
{
    Id: number;
    Name: string;
    StatusId: number;
    Type: string;
    UserName: string;
    CreatedDate: Date;
    Currency:string;
    EquityUnderwork:boolean;
    CreditUnderwork:boolean;
}

export interface List {
    Path: string,
    Name: string,
    Disabled: boolean
}

export class SectorTree {
    Data: number;
    Label: string;
    Children: Array<SectorTree>;

    Expanded: boolean = false;
    Selected: boolean = false;
}

export class Sector {
    Data: number;
    Label: string;
}

export class Filter 
{
    Name: string;
    Choosen: boolean;
    Color: string;
}

export class ResultHeadline
{
    Headline: string;
    ReportId: number;
    Author: string;
}

export class Save {
    Headline: ResultHeadline;
    Type: string;
}

export class ApiCallStates {
    isLoading: boolean = false;
    isError: boolean = false;
    isSuccess: boolean = false;
    errorMessage: string;
}
