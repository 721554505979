import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { merge, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/map';

import { ApiService } from "./api.service";
import { AggregatedReportCollection } from "../../report.model";

@Injectable()
export class ExistingService {
  private dataSource: BehaviorSubject<Array<AggregatedReportCollection>>;
  data;
  private selectedSource: BehaviorSubject<AggregatedReportCollection>;
  selected;
  private isLoadingSource: BehaviorSubject<boolean>;
  isLoading;
  private isErrorSource: BehaviorSubject<boolean>;
  isError;
  private isPrivate: boolean = false;

  constructor(private http: HttpClient, private api: ApiService) {
    this.dataSource = new BehaviorSubject<Array<AggregatedReportCollection>>(
      []
    );
    this.data = this.dataSource.asObservable();

    this.selectedSource = new BehaviorSubject<AggregatedReportCollection>(
      new AggregatedReportCollection()
    );
    this.selected = this.selectedSource.asObservable();

    this.isLoadingSource = new BehaviorSubject<boolean>(true);
    this.isLoading = this.isLoadingSource.asObservable();

    this.isErrorSource = new BehaviorSubject<boolean>(false);
    this.isError = this.isErrorSource.asObservable();

    this.fetch();
  }

  public fetch(isPrivate: boolean = false) {
    this.isPrivate = isPrivate;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingSource.next(true);
          return this.api.getAggregatedReports(this.isPrivate);
        }),
        map(data => {
          this.isLoadingSource.next(false);
          this.isErrorSource.next(false);
          return data;
        }),
        catchError(() => {
          this.isLoadingSource.next(false);
          this.isErrorSource.next(true);
          return [];
        })
      )
      .subscribe(data => this.dataSource.next(data));
  }

  public setSelected(val: AggregatedReportCollection) {
    this.selectedSource.next(val);
  }
}
