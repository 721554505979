import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ResultService } from "../services/result.service";
import { Sector, Filter, FilterColors } from "../../customsector.model";
import { RegionsDialog } from "./regions-dialog.component";
import { RegionsService } from "../services/regions.service";
import { OptionsService } from "../services/options.service";
import { SectorFilter } from "../../../datastructure.model";
import { listItem } from '../../../Templates/styles/services/animations';

@Component({
    selector: "sector",
    templateUrl: "../view/sector.component.html",
    animations: [
        listItem
    ]
})
export class SectorComponent {
    filterAttributes: Array<Filter>;

    sectorList: Array<Sector>;
    countriesSelected: number;

    onlyCompanies: boolean;
    lockCompanies: boolean;

    isErrorResult: boolean;
    isLoadingResult: boolean;

    constructor(private resultService: ResultService, public timeDialog: MatDialog, public countriesService: RegionsService,
        private optionsService: OptionsService) { }

    ngOnInit() {
        this.resultService.sectorList.subscribe(data => this.sectorList = data);
        this.countriesService.selectedRegion.subscribe(data => this.countriesSelected = data.length);
        this.optionsService.onlyCompanies.subscribe(data => this.onlyCompanies = data);
        this.optionsService.lockCompanies.subscribe(data => this.lockCompanies = data);
        this.optionsService.filter.subscribe(data => this.filterAttributes = this.createFilterAttributes(data));
    }

    deleteAllKeyValues() {
        this.resultService.deleteAll();
    }

    openRegionsDialog(): void {
        this.timeDialog.open(RegionsDialog, {
            width: '819px',
            height: '95vh',
            autoFocus: false,
            backdropClass: 'backdrop'
        });
    }

    setOnlyCompanies() {
        this.onlyCompanies = !this.onlyCompanies;
        this.optionsService.setOnlyCompanies(this.onlyCompanies);
    }

    setLockCompanies() {
        this.lockCompanies = !this.lockCompanies;
        this.optionsService.setLockCompanies(this.lockCompanies);
    }

    createFilterAttributes(filterData: SectorFilter): Array<Filter> {
        const filterAttributes = new Array<Filter>();
        filterAttributes.push({
            Name: "HCM coverage",
            Choosen: filterData.HCMCovered,
            Color: FilterColors.Normal
        });
        filterAttributes.push({
            Name: "Not fully covered",
            Choosen: filterData.NotFullyCovered,
            Color: FilterColors.Notfullcoverage
        });
        filterAttributes.push({
            Name: "Consensus",
            Choosen: filterData.Consensus,
            Color: FilterColors.ImportOnly
        });
        filterAttributes.push({
            Name: "Credit",
            Choosen: filterData.Credit,
            Color: FilterColors.Standard
        });
        return filterAttributes;
    };
    // Depends on the order in the function above
    clickFilter(index: number, chosen: boolean) {
        if (index == 0)
            this.optionsService.setFilter(chosen);
        if (index == 1)
            this.optionsService.setFilter(null, chosen)
        if (index == 2)
            this.optionsService.setFilter(null, null, chosen)
        if (index == 3)
            this.optionsService.setFilter(null, null, null, chosen)
    }

    selectNordicCountries(checked: boolean) {
        this.countriesService.setTypeRegions("Nordic", checked);
    }
    selectNonNordicCountries(checked: boolean) {
        this.countriesService.setTypeRegions("Non Nordic", checked);
    }
}
