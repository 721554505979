import { Component, Input, Output } from '@angular/core';
import { AggTree, AGKeyValues } from '../report.model';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'tree-view',
    templateUrl: 'tree-view.html'
})

export class TreeView{
    @Input() data: Array<AggTree>; 
    @Input() topNode: boolean;
    @Output() onDataUpdate: EventEmitter<Array<AGKeyValues>> = new EventEmitter<Array<AGKeyValues>>();
    @Output() onDataTitle: EventEmitter<string> = new EventEmitter<string>();

    dataReturn: Array<AGKeyValues>;

    constructor(){}

    ngOnInit(){
        this.dataReturn = [];
    }

    ngAfterViewInit(){
        if(this.topNode){
            this.checkboxChange(this.data[0])
        }
    }

    checkboxChange(value: AggTree){
        this.dataReturn = [];
        this.retrieveLeafs(value);
        this.onDataUpdate.emit(this.dataReturn);
        this.onDataTitle.emit(value.Name);
    }

    private retrieveLeafs(value: AggTree){
        if(value != null){
            if(value.Info != null){
                if(value.Info.Type == "Non Periodic Data") //Oh, soooo ugly!
                    value.Info.Type = "Non Periodic";
                this.dataReturn.push(value.Info);
            }else{
                value.Element.forEach(element => {
                    this.retrieveLeafs(element);
                });
            }
        }
    }

    emit(value: Array<AGKeyValues>){
        this.onDataUpdate.emit(value);
    }

    emitTitle(value: string){
        this.onDataTitle.emit(value);
    }
}