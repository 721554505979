import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

import { SectorFilter } from "../../../datastructure.model";

@Injectable()
export class OptionsService{

    private filterSource: BehaviorSubject<SectorFilter>;
    filter;

    private onlyCompaniesSource: BehaviorSubject<boolean>;
    onlyCompanies;

    private lockCompaniesSource: BehaviorSubject<boolean>;
    lockCompanies;
    
    constructor(){
        this.filterSource = new BehaviorSubject<SectorFilter>({ HCMCovered: true, NotFullyCovered: false, Consensus: false, Credit: false, OnlyCompanies: true, LockCompanies: false });
        this.filter = this.filterSource.asObservable();

        this.onlyCompaniesSource = new BehaviorSubject<boolean>(true);
        this.onlyCompanies = this.onlyCompaniesSource.asObservable();

        this.lockCompaniesSource = new BehaviorSubject<boolean>(false);
        this.lockCompanies = this.lockCompaniesSource.asObservable();
    }

    setFilter(HCMCovered: boolean = null, NotFullyCovered: boolean = null, Consensus: boolean = null, Credit: boolean = null){
        let filterTemp = this.filterSource.getValue();
        if(HCMCovered != null)
            filterTemp.HCMCovered = HCMCovered;
        if(NotFullyCovered != null)
            filterTemp.NotFullyCovered = NotFullyCovered;
        if(Consensus != null)
            filterTemp.Consensus = Consensus;
        if(Credit != null)
            filterTemp.Credit = Credit;
        this.filterSource.next(filterTemp);
    }

    setOnlyCompanies(bool: boolean){
        this.onlyCompaniesSource.next(bool);
        let filterTemp = this.filterSource.getValue();
        filterTemp.OnlyCompanies = this.onlyCompaniesSource.getValue();
        this.filterSource.next(filterTemp)
    }

    setLockCompanies(bool: boolean){
        this.lockCompaniesSource.next(bool);
        let filterTemp = this.filterSource.getValue();
        filterTemp.LockCompanies = this.lockCompaniesSource.getValue();
        this.filterSource.next(filterTemp)
    }
}