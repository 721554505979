import { Component, ViewChild, Inject, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SelectionModel } from "@angular/cdk/collections";

import { Filter, AggTree, AGKeyValues, FilterKeyValuesColors } from 'app/1.Report/report.model';
import { ApiService, ResultService, SharedFunctionsService } from '../services';
import { TableFilterService } from 'app/Templates/styles/services';
import { Logger } from 'app/_helpers';
import { MatSidenav } from "@angular/material/sidenav";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "customize",
    templateUrl: "../view/customize.component.html"
})
export class CustomizeComponent extends TableFilterService implements AfterViewChecked {
    @ViewChild('sidenav') sidenav: MatSidenav;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns = ['XmlId', 'Name', 'IsCredit', 'Type', 'Formula'];
    //dataSource = new MatTableDataSource();
    tree: Array<AggTree>;
    isLoadingResults = true;
    isError = false;

    filterList: Array<string> = [];

/*
constructor(private changeRef: ChangeDetectorRef) { }
Implement AfterViewChecked interface

ngAfterViewChecked(): void { this.changeRef.detectChanges(); }
*/
    //filterAttributes: Filter[];

    //selection = new SelectionModel();

    result: Array<AGKeyValues>; //används endast till att kolla så att result service har rätt värde TA BORT
    treeTitle: string;

    constructor(private changeRef: ChangeDetectorRef,
        private resultService: ResultService,
        public dialog: MatDialog, 
        public sharedFunctions: SharedFunctionsService,
        private api: ApiService, logger: Logger
    ) {
        super(logger);
        //this.selection = new SelectionModel<AGKeyValues>(true, []);
    }
    ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

    ngOnInit() {

        // If the user changes the sort order, reset back to the first page.
        //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.resultService.keyValues.subscribe(data => {
            this.result = data;
            this.selection = new SelectionModel<AGKeyValues>(true, []);
            this.dataSource.data.forEach(element => {
                if (this.resultService.contains(element)) {
                    this.selection.select(element);
                }
            });
        });

        this.getAggregatedReports();

        this.treeTitle = "";
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    createFilterAttributes(): Array<Filter> {
        let filterAttributes = new Array<Filter>();
        filterAttributes.push({
            Name: "Raw value",
            Choosen: true,
            Color: FilterKeyValuesColors.Rawvalue
        });
        filterAttributes.push({
            Name: "Formula",
            Choosen: true,
            Color: FilterKeyValuesColors.Formula
        });
        filterAttributes.push({
            Name: "Non-periodic",
            Choosen: true,
            Color: FilterKeyValuesColors.NonPeriodic
        });
        filterAttributes.push({
            Name: "Consensus",
            Choosen: true,
            Color: FilterKeyValuesColors.Concensus
        });
        filterAttributes.push({
            Name: "Tech-indicator",
            Choosen: true,
            Color: FilterKeyValuesColors.TechIndicator
        });
        return filterAttributes;
    };

    treeUpdate(element: Array<AGKeyValues>) {
        this.dataSource.data = element;
        this.selection = new SelectionModel<AGKeyValues>(true, []);
        element.forEach(element => {
            if (this.resultService.contains(element)) {
                this.selection.select(element);
            }
        });
        this.ngAfterViewInit();
    }

    setTreeTitle(title: string) {
        this.treeTitle = title;
    }

    showFilterRow() {
        // the filter row expl
        //category
        this.filterList = new Array<string>();
        if (this.treeTitle != "All") this.filterList.push(this.treeTitle)

        //type
        let filterTypeList = new Array<string>();
        this.filterAttributes.forEach(element => {
            if (element.Choosen)
                filterTypeList.push(element.Name)
        });
        //tesult
        if (filterTypeList.length != this.filterAttributes.length)
            this.filterList = this.filterList.concat(filterTypeList);
    }

    changeResult(value: AGKeyValues) {
        if (!this.resultService.contains(value))
            this.resultService.add(value);
        else
            this.resultService.delete(value);
    }

    changeAllResult() {
        if (this.selection.selected.length > 0)
            this.resultService.addAll(<Array<AGKeyValues>>this.selection.selected);
        else
            this.resultService.deleteAll(this.dataSource.filteredData);
    }

    getAggregatedReports() {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.api.getAggregatedReportAllKeyValues();
                }),
                map(data => {
                    this.isLoadingResults = false;
                    (data as AggTree).Name = "All";
                    return data;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isError = true;
                    return [];
                })
            ).subscribe(data => {
                var temp = new Array<AggTree>();
                temp.push(data);
                this.tree = temp;
            });
    }

    openFormulaDialog(element: any) {
        this.dialog.open(FormulaDialog, {
            width: '800px',
            maxHeight: '90vh',
            backdropClass: 'backdrop',
            autoFocus: false,
            data: element
        });
    }

    //set the height of the table with all metrics, relative to the filter height
    tableHeight() {
        let filter = document.getElementById('table-filter').offsetHeight;
        return "calc(100% - " + filter + "px)"
    }
}

@Component({
    selector: "formula-dialog",
    templateUrl: "../view/formula-dialog.component.html"
})
export class FormulaDialog {
    XmlId: string;
    constructor(public dialogRef: MatDialogRef<FormulaDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
