import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class ResultService {

    private isSectorChosenSource: BehaviorSubject<boolean>;
    isSectorChosen;

    constructor() {
        this.isSectorChosenSource = new BehaviorSubject<boolean>(true);
        this.isSectorChosen = this.isSectorChosenSource.asObservable();
    }

    setIsSectorChosen(sectorChosen: boolean){
        this.isSectorChosenSource.next(sectorChosen);
    }

}