import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "../services/api.service";
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ParseFilename } from 'app/Templates/styles/services/parse-filename';
import { Logger } from 'app/_helpers';
import { AggregatedExcelSheets } from 'app/4.Result/model';

@Component({
  selector: "update-report",
  templateUrl: "../view/update.component.html"
})
export class UpdateComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateComponent>,
    public api: ApiService,
    private parseFilename: ParseFilename,
    private logger: Logger
  ) { }

  uploadedFile: File;
  fileInfo: Array<AggregatedExcelSheets> | undefined;
  selectedSheets: Array<number>;

  isError: boolean;
  isLoading: boolean;
  isErrorMessage: string;

  ngOnInit() {
    this.isError = false;
    this.isLoading = false;
    this.resetFileInfo();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resetFileInfo() {
    this.fileInfo = new Array<AggregatedExcelSheets>();
  }

  selectedSheet(index: number) {
    let arrIndex = this.selectedSheets.indexOf(index);

    if (arrIndex === -1) {
      this.selectedSheets.push(index);
      this.selectedSheets = this.selectedSheets.sort((a, b) => a - b);
    } else this.selectedSheets.splice(arrIndex, 1);
  }

  onFileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.uploadedFile = fileList[0];
      this.isError = false;

      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoading = true;
            return this.api.getInfoFromExcel(this.uploadedFile);
          }),
          map(data => {
            this.isLoading = false;
            this.isError = false;
            if (!(data as Array<AggregatedExcelSheets>).some(x => x.IsUpdatable))
              throw new Error(
                "No updatable research screening sheets could be found in the provided xlsx-file"
              );
            return data;
          }),
          catchError(error => {
            this.isLoading = false;
            this.isError = true;
            if (error.error != null) {
              this.isErrorMessage = error.error;
            } else {
              this.isErrorMessage = error;
            }
            return [];
          })
        )
        .subscribe(data => {
          this.fileInfo = <Array<AggregatedExcelSheets>>data;
          this.selectedSheets = new Array<number>();
          if (data.length == 1) {
            this.selectedSheets.push(0);
            this.updateExcel();
          }
        });
    }
  }

  updateExcel() {
    if (this.uploadedFile != null) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoading = true;
            return this.api.updateExcel(this.uploadedFile, this.selectedSheets);
          }),
          map(data => {
            this.isLoading = false;
            this.isError = false;
            return data;
          }),
          catchError(error => {
            this.logger.error("updateExcel", error);
            this.isLoading = false;
            this.isError = true;
            this.isErrorMessage = error.error;
            return [];
          })
        ).subscribe(data => {
          if (!(data && data.header)) return;
          this.resetFileInfo();
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(data.blob);
          link.download = this.parseFilename.getFilenameFromContentDisposition(data.header);
          link.click();
        });
    }
  }

  

}
