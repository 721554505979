import { Component } from "@angular/core";

import { ResultHeadline, Company, FilterColors } from "../../customsector.model";
import { ResultService } from "../services/result.service";
import { AnnualquarterlyResultService } from "../../../1.Report/annualQuarterly/services/annualquarterly-result.service";
import { SelectedEquityCredit } from "../../../1.Report/report.model";
import { ExistingComponent } from './existing.component';
import { listItem } from '../../../Templates/styles/services/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ExistingService } from '../services/existing.service';
import { AggregatedReportCollection } from '../../../Templates/styles/services/model';
import { SaveDialog } from './save-dialog.component';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'custom-list',
    templateUrl: '../view/customlist.component.html',
    animations: [
        listItem
    ]
})
export class CustomListComponent{
    currentTabExisting;
    resultHeadline: ResultHeadline;
    resultKeyValues: Company[];
    isReadOnly: boolean;

    existingReports: Array<AggregatedReportCollection>;

    isLoadingResults;
    isErrorResults = false;

    selectionInPreviousStep: SelectedEquityCredit;

    selectedReport: AggregatedReportCollection

    constructor(public resultService: ResultService, public timeDialog: MatDialog, 
        public snackBar: MatSnackBar, private annualquarterlyService: AnnualquarterlyResultService, 
        private existingService: ExistingService, private auth: AuthorizeService) { }

    ngOnInit() {
        
        this.auth.getUser().subscribe(u => this.isReadOnly = u.name.indexOf('@handelsbanken.') < 0);
        this.resultService.headline.subscribe(report => this.resultHeadline = report);
        this.resultService.isLoading.subscribe(loading => this.isLoadingResults = loading);
        this.resultService.isError.subscribe(error => this.isErrorResults = error);
        this.resultService.keyValues.subscribe(reportKeyValues => this.resultKeyValues = reportKeyValues);
        this.existingService.data.subscribe(data => this.existingReports = data);
        this.existingService.selected.subscribe(
            data => {
              this.selectedReport = data;
            }
          );

        this.annualquarterlyService.selectedEquityCreditKeyvalue.subscribe(selected => {
            if(!selected.Equity)
                this.resultService.setAllUnderworkUse(true, false);
            else if(!selected.Credit)
                this.resultService.setAllUnderworkUse(false,false);
            this.selectionInPreviousStep = selected
            
        });
    }

    selectReport(val: AggregatedReportCollection) {
        //select the report
        if (val != undefined) {
            this.existingService.setSelected(val);
            this.resultService.updateHeadline(val.Name, val.UserName, val.Id);
        }
    }
    deselectReport() {
        this.existingService.setSelected(new AggregatedReportCollection());
        this.resultService.updateHeadline("", "");
        this.deleteAllKeyValues();
    }


    getFilterKeyValuesColor(val: string): string {
        if (val == null) return FilterColors.Standard
        val = val.trim().toLowerCase().replace(/\s/g, '');
        if (val == "hcmcoverage") return FilterColors.Normal;
        else if (val == 'deactivated') return FilterColors.Deactivated;
        else if (val == 'consensus' || val == 'nonperiodic') return FilterColors.ImportOnly;
        else if (val == 'notfullycovered') return FilterColors.Notfullcoverage;
        else return FilterColors.Standard
    }

    deleteKeyValue(index: number) {
        this.resultKeyValues.splice(index, 1);
        this.resultService.update(this.resultKeyValues);
    }
    deleteAllKeyValues() {
        this.resultKeyValues = [];
        this.resultService.update(this.resultKeyValues);
    }

    openSnackBar(message: string, action: string, style: any) {
        this.snackBar.open(message, action, {
            duration: 1000,
            panelClass: style
        });
    }

    openExistingDialog() {
        this.timeDialog.open(ExistingComponent, {
            width: '900px',
            height: '95vh',
            backdropClass: 'backdrop',
            autoFocus: false,
        });
    }

    updateResultName(name: string){
        this.resultService.writeHeadline(name)
    }

    setUnderworkUse(index: number, isEquity: boolean, use: boolean){
        this.resultService.setUnderworkUse(isEquity, use, index);
    }

    saveList() {
        let dialogRef = this.timeDialog.open(SaveDialog, {
            width: '450px',
            backdropClass: 'backdrop',
            autoFocus: false,
            data: this.resultHeadline
        });

        dialogRef.afterClosed().subscribe(data => {
            
        });
    }

    //copied from annualquarterly
    drop(event: CdkDragDrop<Company[]>) {
        moveItemInArray(this.resultKeyValues, event.previousIndex, event.currentIndex);
    }

    checkUncheckAll(selected) {
        this.existingService.getSelected.EquityUnderwork = selected;
        this.existingService.getSelected.CreditUnderwork = selected;
    }
    checkEquity(selected){
        this.existingService.getSelected.EquityUnderwork = selected; 
    }
    checkCredit(selected){
        this.existingService.getSelected.CreditUnderwork = selected; 
    }
}
