import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { AggregatedReportCollection, TimePeriod, AGKeyValues, AggTree } from 'app/1.Report/report.model';
import { Logger } from 'app/_helpers';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
  get baseUrlGrowApi() {
    return `${environment.baseUrlScreeningApi}v1/`;
  }

  get baseUrlReonApi() {
    return environment.baseUrlReonApi;
  }

  constructor(private http: HttpClient, 
    private logger: Logger) {}

  getAggregatedReports(isPrivate: boolean = false): Observable<AggregatedReportCollection[]> {
    return this.http.get<AggregatedReportCollection[]>(
      `${this.baseUrlGrowApi}screening?isPrivate=${isPrivate}`,
      { withCredentials: true }
    );
  }

  getReportTimePeriods(reportId: number): Observable<TimePeriod[]> {
    return this.http.get<TimePeriod[]>(
      `${this.baseUrlGrowApi}screening/timeperiods/${reportId}`,
      { withCredentials: true }
    );
  }

  getCurrencies(): Observable<Array<string>> {
    return this.http.get<Array<string>>(
      `${this.baseUrlGrowApi}screening/currencies`,
      { withCredentials: true }
    );
  }

  saveCreatedList(
    name: string,
    status: string,
    reportId: number,
    user: string,
    keyValues: Array<AGKeyValues>,
    timeperiods: Array<TimePeriod>,
    currency:string
  ) {

    return this.http.put<number>(
      `${this.baseUrlGrowApi}screening/metriclist`,
      {
        Name: name,
        Status: status,
        ReportId: reportId,
        User: user,
        Keyvalues: keyValues,
        Timeperiods: timeperiods,
        Currency:currency
      },
      { withCredentials: true }
    );
  }

  getAggregatedReportKeyValues(reportId: number): Observable<AGKeyValues[]> {
    return this.http.get<AGKeyValues[]>(
      `${this.baseUrlReonApi}aggregatedreport/getreportkeyvalues?reportId=${reportId}`,
      { withCredentials: true }
    );
  }

  getAggregatedReportAllKeyValues(): Observable<AggTree> {
    return this.http.get<AggTree>(
      `${this.baseUrlReonApi}aggregatedreport/getkeyvalues`,
      { withCredentials: true }
    );
  }

}
