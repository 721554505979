import { Injectable } from '@angular/core';

@Injectable()
export class ParseFilename {
    getFilenameFromContentDisposition(disp: string): string {
        const regex = new RegExp('(?:filename=)(.*?)(?=;)');
        //const regex = new RegExp('(?<=filename=)(.*?)(?=;)');
        const matches = disp.match(regex);
        if (matches.length > 0) {
            return matches[0]
                .replace('filename=', '')
                .replace(new RegExp('"', 'g'), '');
        }
        return 'UnknownFilename.xlsm';
    }
}
