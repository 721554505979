<div class="middle">
    <div class="w-6 h-12 left">
        <customize></customize>
    </div>

    <div class="w-3 result h-12">
        <div class="h-12 y-align pad-4 result-list result-list-help" *ngIf="resultKeyValues.length < 1">
            <div class="help-result-text">
                <p class="result-top">This space will soon be filled with your hand picked metrics. But first let's get
                    to know each other</p>
                <div class="result-bottom pad-2-bottom">
                    <mat-icon>arrow_upward</mat-icon>
                    <p>When you are done with your list use the 'Next' button above this box to proceed</p>
                </div>
                <div class="result-left">
                    <mat-icon>arrow_back</mat-icon>
                    <p>To the left you can choose some metrics. They will appear here</p>
                </div>
                <div class="result-right">
                    <mat-icon>arrow_forward</mat-icon>
                    <p>To the right you have some settings that can be altered. You can also search for previously
                        saved metric lists.</p>
                </div>
                <div class="result-bottom">
                    <p>Below you can save your metric list. Note that if you don't, the created excel will NOT be
                        updatable</p>
                    <mat-icon>arrow_downward</mat-icon>
                </div>
            </div>
        </div>
        <div class="h-12 w-12 result-list" *ngIf="resultKeyValues.length >= 1">
            <div class="row y-align pad-2-bottom pad-2-top">
                <div class="w-12">
                    <div class="row color-white y-align w-12">
                        <div class="w-9 row">
                            <h2>Selected metrics</h2>
                            <p class="h2-expl font-white">&nbsp; ({{resultKeyValues.length}})</p>
                        </div>
                    </div>
                </div>
            </div>
            <button mat-raised-button class="button result-list-save" *ngIf="!isReadOnly"
                [disabled]="resultKeyValues.length < 1 || !timePeriodsDone" (click)="saveList()">
                <mat-icon aria-label="Save">save</mat-icon>&nbsp;Save metric list
            </button>
            <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
                <div @result-item class="drag-item" *ngFor="let value of resultKeyValues; let i = index" cdkDrag
                    cdkDragLockAxis="y">
                    <div class="y-align">
                        <div class="w-1">
                            <div class="circle-small" [ngClass]="getFilterKeyValuesColor(value.Type)"></div>
                        </div>
                        <div class="w-9">
                            {{value.Name}}
                        </div>
                        <div class="w-1 result-department">
                            <span *ngIf="value.IsCredit">Cr</span>
                            <span *ngIf="!value.IsCredit">Eq</span>
                        </div>
                        <div class="w-1">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="deleteKeyValue(i)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete from list</span>
                                </button>
                                <ng-container *ngIf="value.Type != null && value.Type.indexOf('Non-periodic') == -1">
                                    <button mat-menu-item [matMenuTriggerFor]="timePeriod">
                                        <mat-icon>today</mat-icon>
                                        <span>Time period</span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="value.Type == null || value.Type.indexOf('Non-periodic') != -1">
                                    <button mat-menu-item [matMenuTriggerFor]="timePeriod" disabled>
                                        <mat-icon>today</mat-icon>
                                        <span>Time period</span>
                                    </button>
                                </ng-container>
                                <button mat-menu-item disabled>
                                    <mat-icon>attach_money</mat-icon>
                                    <span>Change currency</span>
                                </button>
                            </mat-menu>

                            <mat-menu #timePeriod="matMenu">
                                <button mat-menu-item *ngFor="let period of timePeriods"
                                    [disabled]="period.NameId === value.TimePeriodId"
                                    (click)="updatePeriod(period.NameId, value.XmlId, value.Name)">
                                    <span
                                        [ngClass]="period.NameId === value.TimePeriodId ? 'bold' : null">{{period.Name}}</span>
                                </button>
                            </mat-menu>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-3 right">

        <div class="w-12 h-4 pad-2-top pad-2-side">
            <existing-search-box [reportList]="existingReports" [resultHeadline]="resultHeadline"
                (selected)="selectReport($event)" (deselected)="deselectReport()" (advanced)="openExistingDialog()">
            </existing-search-box>
        </div>

        <div class="w-12 h-4  pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align">
                <div *ngIf="!timePeriodsDone" class="right-inner-error">
                    <mat-icon>error</mat-icon>
                </div>
                <div class="w-12">
                    <div class="pad-3-bottom column">
                        <div class="row">
                            <h2>Time periods</h2>
                            <p class="h2-expl" *ngIf="timePeriods.length > 1">&nbsp; ({{timePeriods.length}})</p>
                        </div>
                        <p class="h2-expl font-gray">Create more periods by using the pen</p>
                        <button matTooltip="Advanced options" [matTooltipPosition]="'above'"
                            class="font-gray-dark right-inner-icons" (click)="openTimeDialog()" mat-icon-button
                            [disabled]="isReadOnly">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="y-align">
                        <div class="icon">
                            <mat-icon>today</mat-icon>
                        </div>
                        <div class="cont">
                            <nouislider style="width:100%" [connect]="true" [min]="min" [max]="max" [step]="step"
                                [tooltips]="[true, true]" (change)="addPeriod($event)" [(ngModel)]="date"></nouislider>
                        </div>
                    </div>
                </div>
                <div class="right-inner-help" *ngIf="false">
                    <p class="h2-expl">Change the default time period with the sliders. Use the
                        advanced button
                        to create several periods, which then can be set per metric.</p>
                    <button class="color-blue" mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="w-12 h-4 pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align radius-0-bottom">
                <div class="w-12">
                    <h2>Currency</h2>
                    <p class="h2-expl font-gray">Unclick the button to choose specific currency</p>
                    <div class="y-align">
                        <div class="icon">
                            <mat-icon>attach_money</mat-icon>
                        </div>
                        <div class="cont">
                            <div class="column w-12">
                                <mat-form-field>
                                    <mat-select [(value)]="selectedCurrency" [disabled]="selectedCurrency == ''"
                                        (selectionChange)="setCurrency(selectedCurrency)">
                                        <mat-option *ngFor="let value of currencies" value="{{value}}">{{value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-slide-toggle [checked]="selectedCurrency == ''"
                                    (change)="$event.checked ? setCurrency('') : setCurrency('EUR')"
                                    style="font-size:1.1rem">Report
                                    in Local Currency</mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>