import { Injectable } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { Logger } from 'app/_helpers';

/** 
 * This service can be inherited by a component that want to use selection in tables
 * Note that the provided data structure must have a unique key called Id
*/
@Injectable()
export class TableSelectionService {

    private _dataSource = new MatTableDataSource();
    private _selection = new SelectionModel();

    constructor(public logger: Logger) { }

    set dataSource(value: MatTableDataSource<any>) {
        this._dataSource = value;
    }
    get dataSource() {
        return this._dataSource;
    }

    set selection(value: SelectionModel<any>) {
        this._selection = value;
    }
    get selection() {
        return this._selection;
    }

    // Whether the number of selected elements matches the total number of rows.
    isAllSelected(currSelected: Array<any> = null): boolean {
        let ret;
        if (currSelected == null) {
            const numSelected = this.selection.selected.length;
            const numRows = this.dataSource.data.length;
            ret = numSelected == numRows;
        } else {
            let nmrSelected = 0;
            this.selection.selected.forEach(element => {
                let temp = <any>element;
                currSelected.forEach(val => {
                    if (temp.Id === val.Id) {
                        nmrSelected = nmrSelected + 1;
                    }
                });
            });
            ret = nmrSelected == currSelected.length;
        }
        return ret;
    }

    private clear(currSelected: Array<any> = null) {
        for (let i = 0; i < this.selection.selected.length; i++) {
            let temp = <any>this.selection.selected[i];
            currSelected.forEach(val => {
                if (temp.Id === val.Id) {
                    this.selection.selected.splice(i, 1);
                    this.selection.toggle(val)
                    i--;
                }
            });
        }
    }

    // Selects all rows if they are not all selected; otherwise clear selection.
    masterToggle() {
        this.isAllSelected(<Array<any>>this.dataSource.filteredData) ?
            this.clear(<Array<any>>this.dataSource.filteredData) :
            this.dataSource.filteredData.forEach(row => this.selection.select(row));
    }

    /**
     * TODO, DOES NOT WORK YET
     * Reset the selection so it only contains results for the current datasource, having posts in the selection that
     * isn't in the datasource will make the checkboxes work inconsistently
     * @param dataSource The array which the selection is based on
     * @param result The array which decides which element should be selected
     */
    resetSelectionDatasource(dataSource: Array<any>, result: Array<any>) {
        if(dataSource == null || result == null) return;
        this.selection = new SelectionModel<any>(true, []);
        dataSource.forEach(dataSourceElement => {
            if(result.findIndex(dataSourceElement) > -1) {
                this.selection.select(dataSourceElement);
            }
        });
    }
}
