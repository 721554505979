<style>
    ul {
        list-style: none;
    }

    .top-node {
        padding-left: 0px;
    }

    .con {
        display: flex;
        align-items: center;
    }
</style>

<ng-container *ngIf="topNode">
    <div class="con">
        <button mat-icon-button (click)="topExpanded = !topExpanded; expandColapseAll()">
            <mat-icon>
                <ng-container *ngIf="topExpanded">keyboard_arrow_up</ng-container>
                <ng-container *ngIf="!topExpanded">keyboard_arrow_down</ng-container>
            </mat-icon>
        </button>
        <mat-checkbox *ngIf="!radioButton" (change)="topNodeSelected()" [checked]="topSelected" > All</mat-checkbox>
        <mat-radio-button *ngIf="radioButton" (change)="topNodeSelectedRadio()" [checked]="topSelected"> All</mat-radio-button>
    </div>
    <div style="margin-top: 1.5rem"></div>
</ng-container>

<ul [ngClass]="topNode ? 'top-node' : ''">
    <li *ngFor="let value of data">
        <ng-container *ngIf="value != null">
            <div class="con">
                <ng-container>
                    <button mat-icon-button (click)="value.Expanded = !value.Expanded">
                        <ng-container *ngIf="value.Children.length > 0">
                            <mat-icon>
                                <ng-container *ngIf="value.Expanded">keyboard_arrow_up</ng-container>
                                <ng-container *ngIf="!value.Expanded">keyboard_arrow_down</ng-container>
                            </mat-icon>
                        </ng-container>
                    </button>
                    <mat-checkbox *ngIf="!radioButton" (change)="checkboxChange(value)" [checked]="value.Selected">{{value.Label}}</mat-checkbox>
                    <mat-radio-button *ngIf="radioButton" (change)="radioChange(value)" [checked]="value.Selected">{{value.Label}}</mat-radio-button>
                </ng-container>
            </div>
            <ng-container *ngIf="value.Children != null && value.Expanded">
                <sector-tree [data]="value.Children" [topNode]="false" (treeOutput)="emitTree()" (listOutput)="emitList($event)" 
                    (titleOutput)="emitTitle($event)" [radioButton]="radioButton"></sector-tree>
            </ng-container>
        </ng-container>
    </li>
</ul>