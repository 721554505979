import { Component, ViewChild } from '@angular/core';

import { ApiService } from '../services/api.service';

import { AggregatedReportCollection, Filter } from "../../report.model"
import { ResultService } from 'app/1.Report/annualQuarterly/services/result.service';
import { TimePeriodsService } from '../services/timeperiods.service';
import { TableFilterService } from '../../../Templates/styles/services/table-filter.service';
import { ExistingService } from '../services/existing.service';
import { CurrencyService } from '../services/currency.service';
import { Logger } from 'app/_helpers';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: "existing",
    templateUrl: "../view/existing.component.html"
})
export class ExistingComponent extends TableFilterService {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns = ['Id', 'UserName', 'Name', 'Type'];
    selectedReport: AggregatedReportCollection;

    isLoadingResults = true;
    isError = false;
    isReadOnly: boolean;

    constructor(public dialogRef: MatDialogRef<ExistingComponent>,
        private result: ResultService,
        private timeperiods: TimePeriodsService,
        private existingService: ExistingService,
        private currencyService: CurrencyService,
        private api: ApiService,
        private auth: AuthorizeService,
        logger: Logger) {
        super(logger);
    }

    clickFilterApply(val: number) {
        this.clickFilter(val);

        // if (this.loadedGlobal) return;

        // var attr = this.filterAttributes[val];
        // if (attr.Name.indexOf('Global') > -1)
        // {
        //     this.existingService.fetch(false);
        //     this.logger.debug("clickFilterApply attr", attr);
        //     this.loadedGlobal = true;
        // }
    }

    ngOnInit() {
        this.auth.getUser().subscribe(u => {
            this.isReadOnly = u.name.indexOf('@handelsbanken.') < 0
            this.filterAttributes=this.createFilterAttributes();
        });
        this.existingService.data.subscribe(data => this.dataSource.data = data);
        this.existingService.selected.subscribe(data => this.selectedReport = data);
        this.existingService.isLoading.subscribe(x => this.isLoadingResults = x);
        this.existingService.isError.subscribe(x => this.isError = x);

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    createFilterAttributes(): Array<Filter> {
        let filterAttributes = new Array<Filter>();
        if (!this.isReadOnly) {
            filterAttributes.push({
                Name: "Private",
                Choosen: true,
                Color: "blue"
            });
        }
        if (!this.isReadOnly) {
            filterAttributes.push({
                Name: "Global",
                Choosen: true,
                Color: "lemon"
            });
        }
        filterAttributes.push({
            Name: "System Global",
            Choosen: this.isReadOnly,
            Color: "pink"
        });
        return filterAttributes;
    };

    updateSelectedReport(val: AggregatedReportCollection) {
        //this.selectedReport = val;
        this.existingService.setSelected(val);
        this.result.updateHeadline(this.selectedReport.Name, val.UserName, this.selectedReport.Id);
        this.timeperiods.getReportTimePeriods(this.selectedReport.Id);
        this.currencyService.setSelected(this.selectedReport.Currency);

        this.onNoClick()
    }


    isSelectedReport(val: AggregatedReportCollection) {
        if (val.Id == this.selectedReport.Id) {
            //this.updateSelectedReport(val);
            return true;
        } else {
            return false;
        }
    }

    // DUPLICATED FROM customize.component.ts
    //set the height of the table with all metrics, relative to the filter height
    tableHeight(includeTwo: boolean = true) {
        let filter = document.getElementById('table-filter').offsetHeight;
        if (!includeTwo) {
            let filter2 = document.getElementById('table-filter2').offsetHeight;
            return "calc(100% - " + filter + "px - " + filter2 + "px - 3rem - 1px)"
        }
        return "calc(100% - " + filter + "px)"
    }

}
