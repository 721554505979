import { Injectable } from '@angular/core';

import { AggregatedReportCollection, Filter, AggTree, AGKeyValues, FilterKeyValuesColors } from "../../report.model"

@Injectable()
export class SharedFunctionsService {

    constructor(){}

    getFilterColor(val: string, filterAttributes: Filter[]): string {
        if (val == null || filterAttributes == null) return "standard";
        for (let filter of filterAttributes) {
            if (filter.Name.toLowerCase() == val.toLowerCase())
                return filter.Color;
        }
        return "standard";
    }

}