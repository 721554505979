import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { TimePeriod, TimePeriodContent } from "../../report.model";
import { merge, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class TimePeriodsService {
  private dataSource: BehaviorSubject<Array<TimePeriod>>;
  data;

  isLoading = false;
  isError = false;

  constructor(private api: ApiService) {
    this.dataSource = new BehaviorSubject<Array<TimePeriod>>(
      new Array<TimePeriod>()
    );
    this.data = this.dataSource.asObservable();

    this.addDefault();
  }

  getReportTimePeriods(reportId: number) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.api.getReportTimePeriods(reportId);
        }),
        map(data => {
          this.isLoading = false;
          return data;
        }),
        catchError(() => {
          this.isLoading = false;
          this.isError = true;
          return [];
        })
      )
      .subscribe(data => {
        this.dataSource.next(data);
      });
  }

  deleteRow(value: TimePeriodContent, position: number) {
    var temp = this.dataSource.getValue()[position].Content;
    for (var i = 0; i < temp.length; i++) {
      if (value.Year == temp[i].Year) {
        temp.splice(i, 1);
        break;
      }
    }
    this._updateDataSourceValue(position, temp);
  }

  clearRows(position: number) {
    this._updateDataSourceValue(position, []);
  }

  addRow(
    q1: boolean,
    q2: boolean,
    q3: boolean,
    q4: boolean,
    qy: boolean,
    date: Array<number>,
    position: number
  ) {
    //var temp = this.dataSource.getValue()[position].Content;
    var temp = Array<TimePeriodContent>();

    var min = date[0];
    while (min <= date[1]) {
      var val = new TimePeriodContent();
      val = { Year: min, Q1: q1, Q2: q2, Q3: q3, Q4: q4, Annual: qy };
      min++;
      temp.push(val);
    }
    this._updateDataSourceValue(position, temp);
  }

  private _updateDataSourceValue(
    position: number,
    updateArray: Array<TimePeriodContent>
  ) {
    var tempAdd = this.dataSource.getValue();
    tempAdd[position].Content = updateArray;
    this.dataSource.next(tempAdd);
  }

  changeName(newName: string, position: number): boolean {
    var temp = this.dataSource.getValue();
    var error = false;

    temp.forEach(element => {
      if (element.Name == newName) error = true;
    });

    if (!error) this.dataSource.getValue()[position].Name = newName;

    this.dataSource.next(temp);

    return error;
  }


  addNew() {
    var temp = this.dataSource.getValue();
    var newPeriod = new TimePeriod();
    var nmr = temp[temp.length - 1].NameId;
    newPeriod = { Name: "Custom" + nmr, NameId: nmr + 1, Content: new Array<TimePeriodContent>() };
    temp.push(newPeriod);
    this.dataSource.next(temp);
  }

  private checkName(name: string) {
    var nameUnique = true;
    this.dataSource.getValue().forEach(element => {
      if (element.Name == name) nameUnique = false;
    });
    return nameUnique;
  }

  addDefault() {
    if (this.dataSource.getValue().length < 1) {
      this.dataSource.getValue().push({ Name: "Default", NameId: 1, Content: this.addDefaultHelper() })
    }
  }

  private addDefaultHelper(): Array<TimePeriodContent> {
    let content = new Array<TimePeriodContent>();
    for (let i = 2018; i <= 2022; i++)
      content.push({
        Year: i,
        Q1: false,
        Q2: false,
        Q3: false,
        Q4: false,
        Annual: true
      });
    return content;
  }

  deletePeriod(index: number) {
    var temp = this.dataSource.getValue();
    temp.splice(index, 1);
    this.dataSource.next(temp);
  }

  resetTimePeriods() {
    this.dataSource.next(new Array<TimePeriod>({ Name: "Default", NameId: 1, Content: this.addDefaultHelper() }));
  }

}
