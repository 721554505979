import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

import {
  AggregatedExcelSheets
} from "../../4.Result/model";
import { environment } from 'environments/environment';
import { AuthorizeService } from 'api-authorization/authorize.service';

@Injectable()
export class ApiService {
  get baseUrlReonApi() {
    return environment.baseUrlReonApi;
  }

  constructor(
    private httpClient: HttpClient,
    private authorize: AuthorizeService
  ) {}

    getInfoFromExcel(file: File): Observable<Array<AggregatedExcelSheets>> {
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        return this.httpClient
            .post<Array<AggregatedExcelSheets>>(
              `${this.baseUrlReonApi}aggregatedreport/GetInfoFromExcel`, formData, { withCredentials: true })
    }

    updateExcel(file: File, selectedSheets: Array<number>): Observable<any> {
        let formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        formData.append('data', JSON.stringify(selectedSheets));

        return Observable.create((observer: any) => {
          this.authorize.getAccessToken().toPromise()
            .then((accessToken: string) => {
                this.uploadExcelFile(observer, formData, accessToken);
            });
        });
    }

    private uploadExcelFile(observer: any, formData: FormData, accessToken: string) {
      let xhr = new XMLHttpRequest(), data = formData;

      xhr.open('POST', `${this.baseUrlReonApi}aggregatedreport/UpdateExcel`, true);
      //xhr.setRequestHeader("Content-Type", "multipart/form-data; charset=utf-8;");
      xhr.responseType='blob';
      xhr.withCredentials = true;
      xhr.setRequestHeader("Authorization", "bearer " + accessToken);

      xhr.onreadystatechange = function() {
          if(xhr.readyState === 4) {
              if(xhr.status === 200) {
                  var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                  var blob = new Blob([xhr.response], {type: contentType});
                  var header = xhr.getResponseHeader('Content-Disposition');
                  observer.next({blob: blob, header: header});
                  observer.complete();
              } else {
                  observer.error(xhr.response);
              }
          }
      }
      xhr.send(data);      
    }
}
