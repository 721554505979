import { Injectable, ErrorHandler, Inject } from "@angular/core";
import { AppInsightsLogger } from "./app-insights-logger";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(@Inject(AppInsightsLogger) private appInsightsLogger: AppInsightsLogger) { }

    handleError(error: Error) {
        this.appInsightsLogger.logException(error); // Manually log exception
        console.error(error);
    }
}