<style>
    ul {
        list-style: none;
    }

    .top-node {
        padding-left: 0px;
    }

    .con {
        display: flex;
        align-items: center;
    }
</style>

<ul [ngClass]="topNode ? 'top-node' : ''">
    <li *ngFor="let value of data">
        <ng-container *ngIf="value != null">
            <div class="con">
                <ng-container *ngIf="value.Info == null">
                    <button mat-icon-button (click)="value.expanded = !value.expanded">
                        <ng-container *ngIf="value.Element != null && value.Element[value.Element.length-1].Info == null">
                            <mat-icon>
                                <ng-container *ngIf="value.expanded">keyboard_arrow_up</ng-container>
                                <ng-container *ngIf="!value.expanded">keyboard_arrow_down</ng-container>
                            </mat-icon>
                        </ng-container>
                    </button>
                    <mat-radio-button (change)="checkboxChange(value)" [checked]="topNode">{{value.Name}}</mat-radio-button>
                </ng-container>
                <!-- <ng-container *ngIf="value.Info != null">
                    <mat-checkbox>{{value.Info.Name}}</mat-checkbox>
                </ng-container> -->
            </div>
            <ng-container *ngIf="value.expanded || topNode">
                <tree-view [data]="value.Element" [topNode]="false" (onDataUpdate)="emit($event)" (onDataTitle)="emitTitle($event)"></tree-view>
            </ng-container>
        </ng-container>
    </li>
</ul>