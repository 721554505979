<button class="dialog-close" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>

<div class="h-12 background-white pad-3-bottom">
    <div class="background-gray pad-4" id="table-filter">
        <h1 class="pad-2-top pad-1-bottom">Metric information</h1> <!--{{data.Name}}-->
        <p class="h1-expl">Here you can see more information about the choosen metric.</p>
    </div>
    <!--<button color="accent" mat-button (click)="addNewPeriod()" [disabled]="timePeriods[0].Content.length < 1"
                            style="padding-left: 0">
                            <mat-icon>add</mat-icon>
                            <span class="left-gray-text">Add another time period</span>
                        </button>-->

    <div class="pad-4 column">
        <div class="row w-12 pad-3-bottom y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Name: </h3>
            </div>
            <div class="w-10">{{data.Name}}</div>
        </div>
        <div class="row w-12 pad-3-bottom y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Mnemonic: </h3>
            </div>
            <div class="w-10">{{data.Mnemonic}}</div>
        </div>
        <div *ngIf="XmlId != null" class="row w-12 pad-3-bottom y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Xml name: </h3>
            </div>
            <div class="w-10">{{data.XmlId}}</div>
        </div>
        <div class="row w-12 pad-3-bottom y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Type: </h3>
            </div>
            <div class="w-10">{{data.Type}}</div>
        </div>
        <div class="row w-12 pad-3-bottom y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Credit: </h3>
            </div>
            <div class="w-10">{{data.IsCredit}}</div>
        </div>
        <div class="row w-12 y-align-end">
            <div class="w-2" style="margin-top: 5px">
                <h3>Formula: </h3>
            </div>
            <div class="w-10 overflow-y-scroll">{{data.Formula}}</div>
        </div>
    </div>
</div>