import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ResultService } from "./result.service";

@Injectable()
export class SettingsResultService {

    private sectorTreeSource: BehaviorSubject<Array<string>>;
    sectorTree;

    constructor(private resultService: ResultService) {
        this.sectorTreeSource = new BehaviorSubject<Array<string>>([]);
        this.sectorTree = this.sectorTreeSource.asObservable();
    }

    getIsSectorChosen(){
        return this.resultService.isSectorChosen.source.value;
    }

}