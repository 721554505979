import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SectorTree } from '../services/model';

@Component({
    selector: 'sector-tree',
    templateUrl: '../view/sectortree.component.html'
})

export class SectorTreeComponent {
    @Input() data: Array<SectorTree>;
    @Input() topNode: boolean;
    @Input() radioButton: boolean;

    @Output() treeOutput: EventEmitter<Array<SectorTree>> = new EventEmitter<Array<SectorTree>>();
    @Output() listOutput: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>(); 

    topSelected: boolean;
    topExpanded: boolean;

    list: Array<number>;

    constructor() { }

    ngOnInit() {
        this.topSelected = false;
        this.topExpanded = false;
    }

    ngAfterViewInit() {}

    topNodeSelected() {
        this.topSelected = !this.topSelected;
        this.data.forEach(element => {
            element.Selected = this.topSelected;
        });
        this.emitTree();
        this.emitTitle("All");
    }

    topNodeSelectedRadio() {
        this.topSelected = !this.topSelected;
        this.emitList([]);
        this.emitTitle("All");
    }

    expandColapseAll(){
        this.data.forEach(element => {
            element.Expanded = this.topExpanded;
            
        });
    }

    checkboxChange(value: SectorTree) {
        value.Selected = !value.Selected;
        this.emitTree();
        this.emitTitle(value.Label);
    }

    radioChange(value: SectorTree){
        this.topSelected = false;
        this.list = [];
        this.retrieveLeafs(value);
        this.emitList(this.list);
        this.emitTitle(value.Label);
    }

    private retrieveLeafs(value: SectorTree){
        if(value != null){
            this.list.push(value.Data);
            if(value.Children != null || value.Children.length < 1){
                value.Children.forEach(element => {
                    this.retrieveLeafs(element);
                });
            }
        }
    }

    emitList(value: Array<number>) {
        this.listOutput.emit(value);
    }
    
    emitTree() {
        this.treeOutput.emit(this.data);
    }

    emitTitle(title: string){
        this.titleOutput.emit(title);
    }

}