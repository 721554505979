import { Component, OnInit } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ResultService } from 'app/1.Report/annualQuarterly/services/result.service';

import { AggregatedReportCollection, AGKeyValues, FilterKeyValuesColors, ResultHeadline, TimePeriod } from "../../report.model"
import { TimePeriodsDialog } from './timeperiods-dialog.component';
import { SaveDialog } from './save-dialog.component';
import { ExistingComponent } from './existing.component';
import { AnnualquarterlyResultService, CurrencyService, TimePeriodsService, ExistingService } from '../services';
import { listItem } from 'app/Templates/styles/services/animations';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'annual-quarterly',
    templateUrl: '../view/annualquarterly.component.html',
    animations: [
        listItem
    ]
})
export class AnnualQuarterlyComponent implements OnInit {
    currentTabExisting;
    resultHeadline: ResultHeadline;
    resultKeyValues: AGKeyValues[];
    timePeriods: Array<TimePeriod>;
    timePeriodsDone: boolean;
    isReadOnly: boolean;

    existingReports: Array<AggregatedReportCollection>;

    isLoadingResults;
    isErrorResults = false;

    showTimeperiodBadge: boolean;

    currencies: Array<string>;
    selectedCurrency: string;

    max = 2030;
    min = 1990;
    step = 1;
    date: Array<number>;
    

    constructor(public resultService: ResultService, public timeDialog: MatDialog, private tpService: TimePeriodsService, 
        public snackBar: MatSnackBar,
        private aqResult: AnnualquarterlyResultService, private currencyService: CurrencyService, 
        private existingService: ExistingService, private auth: AuthorizeService) { }

    ngOnInit() {
        this.auth.getUser().subscribe(u => this.isReadOnly = u.name.indexOf('@handelsbanken.') < 0);
        this.resultService.headline.subscribe(report => this.resultHeadline = report);
        this.resultService.isLoading.subscribe(loading => this.isLoadingResults = loading);
        this.resultService.isError.subscribe(error => this.isErrorResults = error);
        this.resultService.keyValues.subscribe(reportKeyValues => this.resultKeyValues = reportKeyValues);
        this.currencyService.currencies.subscribe(data => this.currencies = data);
        this.currencyService.selected.subscribe(data => this.selectedCurrency = data);
        this.existingService.data.subscribe(data => this.existingReports = data);

        this.showTimeperiodBadge = false;

        //indicate if the timeperiods are done
        this.aqResult.timePeriods.subscribe(data => this.timePeriodsDone = data);

        this.tpService.data.subscribe(data => {
            this.timePeriods = data;
            if (data[0].Content.length > 0) {
                this.date = new Array<number>(2);
                this.date[0] = data[0].Content[0].Year;
                this.date[1] = data[0].Content[data[0].Content.length - 1].Year;
            }
        });

    }
    
    selectReport(val: AggregatedReportCollection) {
        //select the report
        if (val != undefined) {
            this.existingService.setSelected(val);
            this.resultService.updateHeadline(val.Name, val.UserName, val.Id);
            this.tpService.getReportTimePeriods(val.Id);
            this.currencyService.setSelected(val.Currency);
        }
    }
    deselectReport() {
        this.existingService.setSelected(new AggregatedReportCollection());
        this.resultService.updateHeadline("", "");
        this.tpService.resetTimePeriods();
        this.currencyService.setSelected('')
        this.deleteAllKeyValues();
    }

    addPeriod(date: Array<number>) {
        this.tpService.addRow(false, false, false, false, true, date, 0);
    }

    getFilterKeyValuesColor(val: string): string {
        if (val == null) return FilterKeyValuesColors.Standard
        val = val.trim().toLowerCase().replace(/\s/g, '');
        if (val == "rawvalue") return FilterKeyValuesColors.Rawvalue;
        else if (val == 'formula') return FilterKeyValuesColors.Formula;
        else if (val == 'non-periodic') return FilterKeyValuesColors.NonPeriodic;
        else if (val == 'consensus') return FilterKeyValuesColors.Concensus;
        else if (val == 'tech-indicator') return FilterKeyValuesColors.TechIndicator;
        else return FilterKeyValuesColors.Standard;
    }

    deleteKeyValue(index: number) {
        this.resultKeyValues.splice(index, 1);
        this.resultService.update(this.resultKeyValues);
    }
    deleteAllKeyValues() {
        this.resultKeyValues = [];
        this.resultService.update(this.resultKeyValues);
    }

    updatePeriod(period: number, keyValueUniqueName: string, keyValueName: string) {
        if (this.resultService.updateTimePeriod(period, keyValueUniqueName))
            this.openSnackBar(keyValueName, "Changed", 'style-succes')
        else
            this.openSnackBar(keyValueName, "ERROR!", 'style-error')
    }

    openSnackBar(message: string, action: string, style: any) {
        this.snackBar.open(message, action, {
            duration: 1000,
            panelClass: style
        });
    }

    openTimeDialog(): void {
        this.showTimeperiodBadge = true;
        this.timeDialog.open(TimePeriodsDialog, {
            width: '800px',
            height: '95vh',
            backdropClass: 'backdrop',
            autoFocus: false,
            data: { reportId: this.resultHeadline.ReportId }
        });
    }

    openExistingDialog() {
        this.timeDialog.open(ExistingComponent, {
            width: '900px',
            height: '95vh',
            backdropClass: 'backdrop',
            autoFocus: false,
        });
    }

    updateResultName(name: string) {
        this.resultService.writeHeadline(name)
    }

    setCurrency(currency: string) {
        this.currencyService.setSelected(currency);
    }

    saveList() {
        let dialogRef = this.timeDialog.open(SaveDialog, {
            width: '450px',
            backdropClass: 'backdrop',
            autoFocus: false,
            data: this.resultHeadline
        });

        dialogRef.afterClosed().subscribe(data => {

        });
    }

    drop(event: CdkDragDrop<AGKeyValues[]>) {
        moveItemInArray(this.resultKeyValues, event.previousIndex, event.currentIndex);
    }

}