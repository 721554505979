import { Component, ChangeDetectorRef, ViewChild, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { trigger, animate, transition, style } from "@angular/animations";
import { Router } from '@angular/router';

import { AnnualquarterlyResultService } from '../1.Report/annualQuarterly/services/annualquarterly-result.service';
import { CustomsectorResultService } from '../2.CustomSector/customsector-result.service';
import { UpdateComponent } from '../Update/components';
import { Logger } from 'app/_helpers';
import { environment } from 'environments/environment';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        animate('400ms ease-in', style({ height: 100 }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ height: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  apiOffline: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  animations: boolean = false;

  headerVisible: boolean = true;

  metricDoneShow: boolean;
  metricDone: boolean;
  metricTimePeriod: boolean;
  metricHeadline: boolean;
  metricKeyValues: boolean;

  customlistIsSector: boolean;
  customlistsectorShow: boolean;
  customlistsectorDone: boolean;
  customlistDone: boolean;
  sectorDone: boolean;
  userId: string;

  get backUrl() : string
  {
    return environment.backUrl;
  }

  constructor(private snackBar: MatSnackBar, private annualquarterlyService: AnnualquarterlyResultService,
    public customlistsectorService: CustomsectorResultService, private cdr: ChangeDetectorRef, private logger: Logger,
    private router: Router, public dialog: MatDialog, private auth: AuthorizeService) {
      this.auth.getUser().subscribe(u => this.userId = u.name);
     }

  @ViewChild('stepper') stepper;


  ngOnInit() {
    this.metricInit();
    this.customlistsectorInit();

    document.documentElement.style.overflow = 'hidden';  // firefox, chrome
    this.logger.debug("Started application");

    // apiOfflineSubject.subscribe(offline => {
    //   this.apiOffline = offline;
    // });
    
  }

  ngAfterViewInit() {
    this.animations = true;
    this.cdr.detectChanges(); //so we don't get a ugly error message https://stackoverflow.com/questions/34364880/expression-has-changed-after-it-was-checked

    const appLoader = document.querySelector(".app-loader");
    if (appLoader)
    {
      appLoader.remove();
    }
    
  }

  back()
  {
    location.href = this.backUrl;
  }

  login()
  {
      this.router.navigate(['login']);
  }

  logout()
  {
      this.router.navigate(['logout']);
  }

  /** 
   * Check if the browser is IE
  */
  isIE(): number {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return 0;
  }

  /* metric check */
  metricInit() {
    this.metricDoneShow = false;

    this.annualquarterlyService.timePeriods.subscribe(data => {
      this.metricTimePeriod = data;
      this.metricDoneCheck();
    });
    this.annualquarterlyService.headline.subscribe(data => {
      this.metricHeadline = data;
      this.metricDoneCheck();
    });
    this.annualquarterlyService.keyValues.subscribe(data => {
      this.metricKeyValues = data;
      this.metricDoneCheck();
    });
  }

  metricDoneCheck() {
    if (!this.metricTimePeriod || !this.metricKeyValues) { // !this.metricHeadline || //At the moment this should not be required
      this.metricDone = false;
      this.metricDoneShow = true;
    } else {
      this.metricDone = true;
      if (this.metricDoneShow) {
        this.openSnackBar("Level 2 is now unlocked", "", '');
        this.metricDoneShow = false;
      }
    }
  }
  metricDoneMessage() {
    if (!this.metricDone) {
      let mess = "";
      !this.metricTimePeriod ? mess += "Time periods, " : "";
      !this.metricKeyValues ? mess += "Metric list" : "";
      this.openSnackBar("Fill in: " + mess, "NOT DONE", '')
    }
  }

  /* custom list and sector check */
  customlistsectorInit() {
    this.customlistsectorShow = false;
    this.customlistsectorService.isSector.subscribe(data => this.customlistIsSector = data); //to show the correct error message

    this.customlistsectorService.customlist.subscribe(data => {
      this.customlistDone = data;
      this.customlistsectorCheck();
    });
    this.customlistsectorService.sector.subscribe(data => {
      this.sectorDone = data;
      this.customlistsectorCheck();
    });
  }
  customlistsectorCheck() {
    if (!this.customlistDone && !this.sectorDone) {
      this.customlistsectorDone = false;
      this.customlistsectorShow = true;
    } else {
      this.customlistsectorDone = true;
      if (this.customlistsectorShow) {
        this.openSnackBar("Level 3 is now unlocked", "", '');
        this.customlistsectorShow = false;
      }
    }
  }

  settingsDone(): boolean {
    return false;
  }

  openSnackBar(message: string, action: string, style: any) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: style
    });
  }

  createReport() {
    this.headerVisible = false;
    document.documentElement.style.overflow = 'auto';  // firefox, chrome
  }

  goHome() {
    this.headerVisible = true;
    document.documentElement.style.overflow = 'hidden';  // firefox, chrome
  }

  updateReport() {
    this.dialog.open(UpdateComponent, {
      width: '82rem',
      height: 'calc(100vh - 40px)',
      backdropClass: 'backdrop',
      disableClose: true,
      autoFocus: false,
    });
  }

}
