import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { SectorTree, Sector } from "../../customsector.model";

@Injectable()
export class ResultService {

    private sectorTreeSource: BehaviorSubject<Array<SectorTree>>;
    sectorTree;

    private sectorListSource: BehaviorSubject<Array<Sector>>;
    sectorList;

    constructor() {
        this.sectorTreeSource = new BehaviorSubject<Array<SectorTree>>([]);
        this.sectorTree = this.sectorTreeSource.asObservable();

        this.sectorListSource = new BehaviorSubject<Array<Sector>>([]);
        this.sectorList = this.sectorListSource.asObservable();
    }

    setSectorTree(tree: Array<SectorTree>) {
        this.sectorTreeSource.next(tree);

        this.sectorListSource.next([]);
        tree.forEach(element => {
            this.setSectorList(element);
        });

    }

    private setSectorList(value: SectorTree) {
        if (value != null) {
            if (value.Selected) {
                let temp = {
                    Data: value.Data,
                    Label: value.Label
                };
                let temp2 = this.sectorListSource.getValue();
                temp2.push(temp);
                this.sectorListSource.next(temp2);
            }
            if (value.Children != null) {
                value.Children.forEach(element => {
                    this.setSectorList(element);
                })
            }
        }
    }

    private containsList(data: number): boolean {
        let exists = false;
        this.sectorListSource.getValue().forEach(element => {
            if (element.Data = data) exists = true;
        })
        return exists;
    }

    deleteAll() {
        this.sectorListSource.next([]);
        let tree = this.sectorTreeSource.getValue();
        tree.forEach(element => {
            this.resetSectorTree(element);
        });
        this.sectorTreeSource.next(tree);
    }

    private resetSectorTree(value: SectorTree) {
        if (value != null) {
            if (value.Selected) {
                value.Selected = false;
            }
            if (value.Children != null) {
                value.Children.forEach(element => {
                    this.resetSectorTree(element);
                })
            }
        }
    }
}