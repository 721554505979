import { Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AggregatedReportCollection, ResultHeadline } from '../services/model';

@Component({
    selector: 'existing-search-box',
    templateUrl: '../view/existingSearchBox.component.html',
})
export class ExistingSearchtBox {
    @Input() reportList: Array<AggregatedReportCollection>;
    @Input() resultHeadline: ResultHeadline;
    
    @Output() selected: EventEmitter<AggregatedReportCollection> = new EventEmitter<AggregatedReportCollection>();
    @Output() deselected: EventEmitter<any> = new EventEmitter();
    @Output() advanced: EventEmitter<any> = new EventEmitter();

    myControl = new FormControl();
    filteredOptions: Observable<Array<AggregatedReportCollection>>;

    ngOnInit() {
        //Search
        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.Name),
                map(name => name ? this._filter(name) : this.reportList.slice())
            );
    }

    ngOnChanges(changes: SimpleChanges) {}

    private _filter(name: string): Array<AggregatedReportCollection> {
        const filterValue = name.toLowerCase();

        return this.reportList.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }

    displayFn(val?: AggregatedReportCollection): string | undefined {
        return "";
    }

    searchedReport(val: AggregatedReportCollection) {
        //select the report
        if (val != undefined) {
            this.selected.emit(val)
        }
    }
    deselectReport() {
        this.deselected.emit()
    }

    advancedClicked(){
        this.advanced.emit()
    }
}
