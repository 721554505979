import { Component, ViewChild, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ResultHeadline, TimePeriod, TimePeriodContent, AggregatedReportCollection } from "../../report.model";
import { merge, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { WhoamiService } from 'app/Templates/styles/services';
import { ResultService, TimePeriodsService, ExistingService, ApiService, CurrencyService } from '../services';
import { ApiCallStates, Save } from 'app/Templates/styles/services/model';

@Component({
    selector: 'save-dialog',
    templateUrl: '../view/save-dialog.component.html'
})
export class SaveDialog {

    types: Array<string>;

    apiCallStates: ApiCallStates = new ApiCallStates();

    constructor(
        public dialogRef: MatDialogRef<SaveDialog>, private resultService: ResultService, private timePeriodService: TimePeriodsService,
        private existingService: ExistingService, private api: ApiService, private whoamiService: WhoamiService,
        private currencyService:CurrencyService,
        @Inject(MAT_DIALOG_DATA) public resultHeadline: ResultHeadline
    ) { }

    ngOnInit() {
        this.types = new Array("Private", "Global");
    }

    ngOnDestroy() {

    }

    onNoClick(save: Save = null): void {
        if (save) {
            this.resultService.writeHeadline(save.Headline.Headline)
            this.saveList(save);
        }
        else {
            this.dialogRef.close();
        }
    }

    saveList(data: Save) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.apiCallStates.isError = false;
                    this.apiCallStates.errorMessage = null;
                    this.apiCallStates.isLoading = true;

                    let aggr = new Array<TimePeriod>();
                    this.timePeriodService.data.source.value.forEach(element => {
                        let periods = new Array<TimePeriodContent>();
                        element.Content.forEach(content => {
                            periods.push({
                                Year: content.Year,
                                Q1: content.Q1,
                                Q2: content.Q2,
                                Q3: content.Q3,
                                Q4: content.Q4,
                                Annual: content.Annual
                            });
                        });

                        aggr.push({
                            Name: element.Name,
                            NameId: element.NameId,
                            Content: periods
                        });
                    });
                    return this.api.saveCreatedList(data.Headline.Headline, data.Type, data.Headline.ReportId,
                        this.whoamiService.whoami, this.resultService.getKeyValues(), aggr,this.currencyService.getSelected());
                }),
                map(data => {
                    this.apiCallStates.isError = false;
                    return data;
                }),
                catchError(error => {
                    this.apiCallStates.isLoading = false;
                    this.apiCallStates.isError = true;
                    this.apiCallStates.errorMessage = error.error;
                    return [];
                })
            ).subscribe(reportId => {
                let headline = this.resultService.getHeadline();
                headline.ReportId = reportId;
                this.resultService.setHeadline(headline);

                this.existingService.fetch();
                let selected = new AggregatedReportCollection;
                selected.Id = reportId;

                this.existingService.setSelected(selected);
                this.apiCallStates.isLoading = false;
                this.apiCallStates.isSuccess = true;
                setTimeout(() => {
                    this.apiCallStates.isSuccess = false;
                    this.dialogRef.close();
                }, 2000)
            });
    }

}