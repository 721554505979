import { Component, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { TableFilterService } from "../../../Templates/styles/services/table-filter.service";
import { Filter } from "../../../Templates/styles/services/model";
import { AggregatedReportCollection} from "../../customsector.model";
import { ResultService, ExistingService, ApiService } from '../services';
import { Logger } from 'app/_helpers';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "existing",
  templateUrl: "../view/existing.component.html"
})
export class ExistingComponent extends TableFilterService {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ["Id", "UserName", "Name", "Type"];
  selectedReport: AggregatedReportCollection;

  isLoadingResults = true;
  isError = false;
  isReadOnly: boolean;


  constructor(
    public dialogRef: MatDialogRef<ExistingComponent>,
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private resultService: ResultService,
    private existingService: ExistingService,
    private api: ApiService,
    private auth: AuthorizeService,
    logger: Logger
  ) {
    super(logger);
  }

  ngOnInit() {
    //this.getAggregatedReports();
    this.auth.getUser().subscribe(u => {
      this.isReadOnly = u.name.indexOf('@handelsbanken.') < 0
      this.filterAttributes=this.createFilterAttributes();
  });
    this.existingService.data.subscribe(data => (this.dataSource.data = data));
    this.existingService.selected.subscribe(
      data => {
        this.selectedReport = data;
      }
    );

    this.existingService.isLoading.subscribe(x => (this.isLoadingResults = x));
    this.existingService.isError.subscribe(x => (this.isError = x));


    // If the user changes the sort order, reset back to the first page.
    //this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    //this.dataSource.paginator = this.paginator;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


  createFilterAttributes(): Array<Filter> {
    const filterAttributes = new Array<Filter>();
    if(!this.isReadOnly){
        filterAttributes.push({
        Name: "Private",
        Choosen: true,
        Color: "blue"
        });
      }
    if(!this.isReadOnly){
        filterAttributes.push({
          Name: "Global",
          Choosen: true,
          Color: "lemon"
          });
    }
    filterAttributes.push(
      {
      Name: "System Global",
      Choosen: this.isReadOnly,
      Color: "pink"
      });
    return filterAttributes;
  }

  updateSelectedReport(val: AggregatedReportCollection) {

    this.existingService.setSelected(val);
    this.resultService.updateHeadline(this.selectedReport.Name, val.UserName, this.selectedReport.Id)
      .subscribe(companies=> {
        this.resultService.setAllUnderworkUse(true, this.selectedReport.EquityUnderwork);
        this.resultService.setAllUnderworkUse(false, this.selectedReport.CreditUnderwork);
      });
    this.logger.debug("updated SelecteReport",val);
    this.existingService.getSelected.EquityUnderwork = this.selectedReport.EquityUnderwork;
    this.existingService.getSelected.CreditUnderwork = this.selectedReport.CreditUnderwork;
    this.onNoClick()
  }

  // DUPLICATED FROM customize.component.ts
  //set the height of the table with all metrics, relative to the filter height
  tableHeight(includeTwo: boolean = true) {
    const filter = document.getElementById('table-filter').offsetHeight;
    if (!includeTwo) {
      const filter2 = document.getElementById('table-filter2').offsetHeight;
      return "calc(100% - " + filter + "px - " + filter2 + "px - 3rem - 1px)"
    }
    return "calc(100% - " + filter + "px)"
  }
}
