<button class="dialog-close" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>

<div class="h-12 background-white" style="overflow: hidden">
    <div class="loading-shade background-white" *ngIf="isLoadingResults">
        <mat-spinner></mat-spinner>
    </div>

    <!--<div class="spacer">
    <div class="hQuestion">
        <h3>Custom list</h3>
        <mat-icon>help_outline</mat-icon>
    </div>
    <button mat-button [disabled]="true" style="padding-left: 0">
        <mat-icon color="accent"></mat-icon>
        <span class="left-gray-text"></span>
    </button>
</div>-->

    <div class="column loading-shade background-white" *ngIf="isError">
        <h1 class="font-error pad-4-bottom">Error!</h1>
        <p class="pad-2-bottom">Ops, something went wrong. Be so kind and try to retriew the data again</p>
        <button class="button" mat-raised-button (click)="getAggregatedReports(); isError=false">Try again</button>
    </div>

    <div class="background-gray pad-4" id="table-filter" style="display: grid">
        <h1 class="pad-2-top pad-2-bottom">Find a previously saved company list</h1>
        <p class="h1-expl">Search a saved report. It can either be private or global.</p>
    </div>

    <div class="pad-4 w-12 h-12" [ngStyle]="{'height': tableHeight(false)}">
        <div class="row pad-2-bottom w-8" id="table-filter2">
            <div class="w-12 header">
                <mat-form-field class="w-10">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search existing company list"
                        #searchTerm>
                    <button mat-button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilter('0000'); searchTerm.value=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div class="w-8 border-2-right" [ngStyle]="{'height': tableHeight()}">
            <mat-table #table [dataSource]="dataSource" matSort>

                <!-- Radio Column -->
                <ng-container matColumnDef="Id">
                    <mat-header-cell *matHeaderCellDef [ngClass]="'column-small'"> </mat-header-cell>
                    <mat-radio-group class="radio-group" [(ngModel)]="selectedReport">
                        <mat-cell *matCellDef="let element" style="overflow:initial;" [ngClass]="'column-small'">
                            <mat-radio-button class="radio-button" [value]="element" (click)="updateSelectedReport(element)"
                                [checked]="element.Id == selectedReport.Id">
                            </mat-radio-button>
                        </mat-cell>
                    </mat-radio-group>
                </ng-container>

                <!-- User Column -->
                <ng-container matColumnDef="UserName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-medium'"> User
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-medium'" class="color-gray">
                        {{element.UserName}} </mat-cell>
                </ng-container>

                <!-- Report Column -->
                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Metric Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="Type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-large'"> Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-large'">
                        <div class="row y-align">
                            <span class="circle-small mar-1-right" [ngClass]="getFilterColor(element.Type)"></span>
                            <span class="color-gray">{{element.Type}}</span>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>

        <div class="w-4 row pad-3-left">
            <!--<ul class="ul-basic row">
                                        <li *ngFor="let filter of filterAttributes; let i = index" [ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'"
                                            (click)="clickFilter(i); searchTerm.value=''">
                                            {{filter.Name}}
                                        </li>
                                    </ul>-->
            <h3 class="pad-2-bottom">Filter list</h3>
            <ul class="ul-basic ul-margin-1 w-10">
                <li *ngFor="let filter of filterAttributes; let i = index">
                    <!--[ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'" (click)="clickFilter(i); searchTerm.value=''"-->
                    <div class="row y-align">
                        <div class="w-1"></div>
                        <mat-checkbox class="w-2" (change)="clickFilter(i); searchTerm.value=''" [checked]="filter.Choosen">
                        </mat-checkbox>
                        <div class="w-2">
                            <div class="circle-small" [ngClass]="getFilterColor(filter.Name)"></div>
                        </div>
                        <span class="w-7">{{filter.Name}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>