import { Component, ViewChild, ElementRef, Input } from "@angular/core";
import { OrderedKeyValueData } from "../model";
import { Logger } from 'app/_helpers';

declare var $: any;

@Component({
    selector: 'table-dialog',
    templateUrl: '../view/table-dialog.component.html'
})
export class TableDialog {
    @Input() tableData : Array<OrderedKeyValueData>;

    constructor(private logger: Logger) { 
        
    }
    private tableDOM: ElementRef;
    @ViewChild('resultTable') set content(content: ElementRef) {
        this.tableDOM = content;
        this.advancedTableScroll();
    };

    markedCompanies: Array<number>;

    ngOnInit() {
        this.markedCompanies = [];
    }

    advancedTableScroll() {
        if (this.tableDOM != null) {
            $('table').on('scroll', function () {
                $("table > *").width($(this).width() + $(this).scrollLeft());
            });

            var $stickyHeader = $('table thead tr th:first-child');
            var $stickyCells = $('table tbody tr td:first-child');

            $('table').on('scroll', function () {
                $stickyHeader.css('left', ($(this).scrollLeft() + 'px'));
                $stickyCells.css('left', ($(this).scrollLeft() + 'px'));
            });
        }
    }

    _tableHeight: string;
    
    tableHeight(): string {
        let totHeightPixel = document.documentElement.clientHeight;// * 0.95; // 0.95 since the dialog box height is 95vh
        let theadHeightPixel = document.getElementById("thead").clientHeight;
        let padding = 190; //since the top and bottom padding is 60px each
        let tbodyHeightPixel = totHeightPixel - theadHeightPixel - padding;

        return tbodyHeightPixel + "px";
    }

    stringWidthIn(event: any) {
        let change = (92 - event.srcElement.offsetWidth);
        if(change <= 0) {
            event.srcElement.style.marginLeft = (change - 10) + "px";
        }
    }

    stringWidthOut(event: any) {
        let change = (event.srcElement.offsetWidth - 92);
        if(change >= 0) {
            event.srcElement.style.marginLeft = 0;
        }
    }

    markCompany(companyId: number) {
        let exists = this.markedCompanies.indexOf(companyId);
        if (exists >= 0) {
            this.markedCompanies.splice(exists, 1);
        } else {
            this.markedCompanies.push(companyId);
        }
    }
}