import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialImportModule } from 'material/materialimport.module';

import {
  AnnualQuarterlyComponent, ExistingComponent, CustomizeComponent, FormulaDialog, SaveDialog,
  TimePeriodsDialog, TimePeriodsContentComponent, TimePeriodsAddComponent
} from './components';
import { TreeView } from './treeview.component';
import {
  ApiService,
  CurrencyService,
  ExistingService,
  ResultService,
  SharedFunctionsService, 
  TimePeriodsService,
} from './services';
import { StyleTemplatesModule } from 'app/Templates/styles/styletemplates.module';

@NgModule({
  declarations: [
    AnnualQuarterlyComponent,
    ExistingComponent,
    CustomizeComponent,
    FormulaDialog,
    TreeView,
    TimePeriodsDialog,
    TimePeriodsAddComponent,
    TimePeriodsContentComponent,
    SaveDialog
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    HttpClientModule,
    StyleTemplatesModule
  ],
  entryComponents: [FormulaDialog, TimePeriodsDialog, SaveDialog, ExistingComponent],
  exports: [
    AnnualQuarterlyComponent
  ],
  providers: [
    ApiService,
    SharedFunctionsService,
    ResultService,
    TimePeriodsService,
    CurrencyService,
    ExistingService
  ]
})
export class AnnualQuarterlyModule { }