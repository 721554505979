<div class="w-12 right-inner y-align">
    <div class="w-12">
        <!--<h2>Find saved list</h2>-->
        <!--<div class="pad-1-bottom y-align row">
                    <button class="icon-button font-gray-dark" (click)="openExistingDialog()" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <b *ngIf="resultHeadline.ReportId != null" class="font-quick font-small pad-2-left">{{resultHeadline.Headline}}</b>
                </div>
                <p class="h2-expl font-gray">Been here before? Go ahead, search for a metric list. Click the icon
                    button to search in more detail.</p>-->
        <div class="pad-1-bottom column">
            <h2 *ngIf="!resultHeadline.ReportId">Saved list</h2>
            <p *ngIf="!resultHeadline.ReportId" class="h2-expl font-gray">Click the pen to do an advanced
                search</p>
            <p *ngIf="resultHeadline.ReportId" class="h2-expl font-gray">Selected list:
                <button class="icon-small" matTooltip="Deselect report" [matTooltipPosition]="'above'" (click)="deselectReport()"
                    mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </p>
            <h2 *ngIf="resultHeadline.ReportId != null" class="font-bold">{{resultHeadline.Headline}}</h2>
            <button matTooltip="Advanced options" [matTooltipPosition]="'above'" class="font-gray-dark right-inner-icons"
                (click)="advancedClicked()" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <!--<p class="h2-expl" *ngIf="timePeriods.length > 1">&nbsp; ({{timePeriods.length}})</p>-->
        </div>
        <div class="cont">
            <mat-form-field class="w-12" appearance="standard">
                <input matInput placeholder="Search existing list" [matAutocomplete]="auto" [formControl]="myControl">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="searchedReport($event.option.value)">
                    <mat-option *ngFor="let report of filteredOptions | async" [value]="report">
                        <span>{{report.Name}}</span>,
                        <small class="color-gray">{{report.UserName}}</small>
                    </mat-option>
                </mat-autocomplete>
                <!--<button mat-button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilter('0000'); searchTerm.value=''">
                        <mat-icon>close</mat-icon>
                    </button>-->
            </mat-form-field>
            <!--<button mat-button class="small-button" (click)="openExistingDialog()">advanced</button>-->
        </div>
    </div>
    <!--<div class="right-inner-gray">
                <i *ngIf="resultHeadline.ReportId != null" class="font-quick font-small">'{{resultHeadline.Headline}}'
                    selected</i>
                <div class="right-inner-icons">
                    <button matTooltip="Advanced options" [matTooltipPosition]="'below'" class="font-gray-dark"
                        (click)="openExistingDialog()" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <!--<button matTooltip="Help" [matTooltipPosition]="'above'" class="font-gray-dark" mat-icon-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>-->
    <!--</div>
            </div>-->
</div>