import { Injectable } from "@angular/core";
import { merge, BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from "./api.service";
import { ResultHeadline, Company} from "../../customsector.model";
import { AggregatedReportCollection } from "../../customsector.model";

@Injectable()
export class ResultService {

  public keyValuesSource: BehaviorSubject<Company[]>;
  keyValues;
  private isLoadingSource: BehaviorSubject<boolean>;
  isLoading;
  private isErrorSource: BehaviorSubject<boolean>;
  isError;

  private headlineSource: BehaviorSubject<ResultHeadline>;
  headline;

  private selectedSource: BehaviorSubject<AggregatedReportCollection>;
  selected;
 
 

  constructor(private api: ApiService) {

    this.keyValuesSource = new BehaviorSubject<Company[]>([]);
    this.keyValues = this.keyValuesSource.asObservable();

    this.isLoadingSource = new BehaviorSubject<boolean>(false);
    this.isLoading = this.isLoadingSource.asObservable();

    this.isErrorSource = new BehaviorSubject<boolean>(false);
    this.isError = this.isErrorSource.asObservable();

    this.headlineSource = new BehaviorSubject<ResultHeadline>({ Headline: "", ReportId: null, Author: null });
    this.headline = this.headlineSource.asObservable();

    this.selectedSource = new BehaviorSubject<AggregatedReportCollection>(
      new AggregatedReportCollection()
    );
    this.selected = this.selectedSource.asObservable();
 
  }

  updateHeadline(headline: string, author: string, reportId: number = null) :Observable<Company[]> | null{
    var resultHeadline = new ResultHeadline();
    resultHeadline = {
      Headline: headline,
      ReportId: reportId,
      Author: author
    };
  
    this.headlineSource.next(resultHeadline);
    if (reportId==null) return null;
    const subscribe= this.fetch(reportId);
    subscribe.subscribe(data => this.keyValuesSource.next(data));
    return subscribe;
  }

  writeHeadline(newHeadline: string) {
    this.headlineSource.getValue().Headline = newHeadline;
    this.headlineSource.next(this.headlineSource.getValue());
  }

  private fetch(reportId: number):Observable<Company[]> {
    return merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingSource.next(true);
          return this.api.getAggregatedCompanies(reportId);
        }),
        map(data => {
          this.isLoadingSource.next(false);
          return data;
        }),
        catchError(() => {
          this.isLoadingSource.next(false);
          this.isErrorSource.next(true);
          return [];
        })
      );
  }

  update(keyArray: Company[]) {
    this.keyValuesSource.next(keyArray);
  }

  add(keyvalue: Company) {
    var temp = this.keyValuesSource.getValue();
    var add = true;
    temp.forEach(element => {
      if (element.Id == keyvalue.Id) add = false;
    });
    if (add) {
      temp.push(keyvalue);
      this.keyValuesSource.next(temp);
    }
  }


  delete(keyvalue: Company) {

    let temp = this.deleteHelper(keyvalue);
    this.keyValuesSource.next(temp);
  }

  private deleteHelper(companies: Company): Array<Company> {
    var temp = this.keyValuesSource.getValue();
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].Id == companies.Id) {
        temp.splice(i, 1);
        continue;
      }
    }
    return temp;
  }

  deleteAll(values: Array<Company>) {
    let temp = this.keyValuesSource.getValue();
    values.forEach(element => {
      temp = this.deleteHelper(element);
    });
    this.keyValuesSource.next(temp);
  }

  addAll(value: Array<Company>) {
    let temp = [];
    value.forEach(element => {
      if (!this.contains(element)) temp.push(element);
    });
    this.keyValuesSource.next(this.keyValuesSource.getValue().concat(temp));
  }

  contains(keyvalue: Company): boolean {
    var val = false;
    this.keyValues.source.value.forEach(element => {
      if (element.Id === keyvalue.Id) {
        val = true;
      }
    });
    return val;
  }

  setUnderworkUse(isEquity: boolean, use: boolean, index: number) {
    let temp = this.keyValuesSource.getValue();
    if (isEquity) temp[index].UseEquityUnderwork = use;
    else temp[index].UseCreditUnderwork = use;
    this.keyValuesSource.next(temp);
  }

  setAllUnderworkUse(isEquity: boolean, use: boolean) {
    let temp = this.keyValuesSource.getValue();

    temp.forEach(element => {
      if (isEquity)element.UseEquityUnderwork = use;
      else element.UseCreditUnderwork = use;      
    });

    this.keyValuesSource.next(temp);
  }

  /*GET*/
  getKeyValues() {
    return this.keyValuesSource.getValue()
  }


  getHeadline() {
    return this.headlineSource.getValue()
  }
  setHeadline(headline: ResultHeadline) {
    this.headlineSource.next(headline)
  }

  //public setSelected(val: AggregatedReportCollection) {
  //  this.selectedSource.next(val);
  //}
}
