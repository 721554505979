import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialImportModule } from "material/materialimport.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { ReportModule } from "./1.Report/report.module";
import { CustomSectorModule } from "./2.CustomSector/customsector.module";
import { AnnualquarterlyResultService } from "./1.Report/annualQuarterly/services";
import { CustomsectorResultService } from "./2.CustomSector/customsector-result.service";
import { SettingsModule } from "./3.Settings/settings.module";
import { ResultModule } from "./4.Result/result.module";
import { UpdateModule } from "./Update/update.module";
import { NgxLoggerLevel, LoggerModule } from 'ngx-logger';
import { routing } from './app.routing';
import { Logger } from './_helpers';
import { HomeComponent } from './home';
import { environment } from 'environments/environment';
import { AuthorizeInterceptor } from 'api-authorization/authorize.interceptor';
import { ApiAuthorizationModule } from 'api-authorization/api-authorization.module';
import { UnauthorizedInterceptor } from 'api-authorization/unauthorized.interceptor';
import { ErrorHandlerService } from './common/error-handler-service';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG, 
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: environment.production,
    }),
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    ReportModule,
    CustomSectorModule,
    SettingsModule,
    ResultModule,
    UpdateModule,
    ApiAuthorizationModule,
    HttpClientModule,
    routing
    
  ],
  exports: [FormsModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Logger,
    AnnualquarterlyResultService,
    CustomsectorResultService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
