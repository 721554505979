<div class="blue-back"></div>

<div class="top-white" *ngIf="!isIE()">
  <div class="top-white-round"></div>
</div>

<div class="top-left-buttons">
  <button *ngIf="backUrl" matTooltip="Go back to Research Online!" [matTooltipPosition]="'below'"
        mat-icon-button (click)="back()" mat-mini-fab>
        <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div id="top-container" class="overflow-hidden h-12">
  <div id="ie" *ngIf="isIE() > 0">You seem to use Internet Explorer! To get the best possibly experience we kindly ask
    you to switch to
    <b>Google Chrome</b> or
    <b>Mozilla Firefox</b>. Thanks</div>

  <div id="header" *ngIf="headerVisible">
    <!--headerVisible-->

    <div id="header-inner"><img src="./assets/shb-large-logo.svg" width="200" />
      <h1>Research Screening</h1>
      <div class="h1-expl pad-2-top">This tool makes it possible to create excels consisting of your selected companies
        and metrics.</div>

      <div class="pad-4-top">
        <button class="button-success" mat-raised-button (click)="createReport()">Create report</button>
      </div>
      <div style="margin:10px 0 10px 0">
        <button mat-button id="header-small" (click)="updateReport()">or update an existing Excel report</button>
      </div>

      <div *ngIf="apiOffline">Application is offline. Please try again later</div>
      <!-- <div>
        <p>
          <button type="button" (click)="logout()">Logout</button>
          <button type="button" (click)="login()">Login</button>
        </p>
      </div> -->

    </div>

  </div>

  <div class="row">
    <div class="top-right-buttons">
      <span class="userId">{{userId}}</span>
      
      <button matTooltip="Go home!" [matTooltipPosition]="'below'" (click)="goHome()" mat-mini-fab *ngIf="!headerVisible">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
    </div>

    <div id="stepper" class="w-12" style="width: 100vw">
      <mat-horizontal-stepper linear #stepper="matHorizontalStepper">
        <mat-step [stepControl]="firstFormGroup" [completed]="metricDone">
          <!--metricDone-->

          <ng-template matStepLabel>Metrics</ng-template>
          <report></report>
          <div class="nav-button-error" *ngIf="!metricDone">
            <ul class="ul-basic">
              <li *ngIf="!metricKeyValues">The created metric list can't be empty</li>
              <li *ngIf="!metricTimePeriod">There shall be no empty time periods</li>
            </ul>
          </div>
          <div class="nav-button-success" *ngIf="metricDone">
          </div>
          <div class="nav-button-container">
            <div class="nav-button-background"></div>
            <button class="button-success" [disabled]="!metricDone" mat-raised-button matStepperNext>
              next <mat-icon>arrow_rightward</mat-icon>
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [completed]="customlistIsSector ? sectorDone : customlistDone">

          <ng-template matStepLabel>Companies</ng-template>
          <custom-sector></custom-sector>
          <div class="nav-button-error" *ngIf="!customlistDone && !customlistIsSector">
            <ul class="ul-basic">
              <li>The created custom list can't be empty</li>
            </ul>
          </div>
          <div class="nav-button-error" *ngIf="!sectorDone && customlistIsSector">
            <ul class="ul-basic">
              <li *ngFor="let error of customlistsectorService.getSectorErrorlist()">
                {{error}}
              </li>
            </ul>
          </div>
          <div class="nav-button-success" *ngIf="customlistDone && !customlistIsSector"></div>
          <div class="nav-button-success" *ngIf="sectorDone && customlistIsSector"></div>
          <div class="nav-button-container" style="left: calc(50% - 5rem)">
            <button class="button-success" [disabled]="customlistIsSector ? !sectorDone : !customlistDone"
              mat-raised-button matStepperNext>
              next <mat-icon>arrow_rightward</mat-icon>
            </button>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Result</ng-template>
          <result [currentStepInStepper]="stepper.selectedIndex"></result>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>