import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialImportModule } from '../../material/materialimport.module'
import { CustomSectorComponent } from './customsector.component';
import { StyleTemplatesModule } from '../Templates/styles/styletemplates.module';
import { SectorModule } from './sector/sector.module';
import { CustomListModule } from './customList/customlist.module';
import { CustomsectorResultService } from './customsector-result.service';

@NgModule({
  declarations: [
    CustomSectorComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    StyleTemplatesModule,
    SectorModule,
    CustomListModule
  ],
  providers: [
    CustomsectorResultService
  ],
  exports: [
    CustomSectorComponent,
  ]
})
export class CustomSectorModule { }