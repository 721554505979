import { Injectable } from "@angular/core";
import { merge, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import {
    AGKeyValues,
    ResultHeadline,
    TimePeriod
} from "app/1.Report/report.model";
import { ApiService } from "app/1.Report/annualQuarterly/services/api.service";
import { TimePeriodsService } from "./timeperiods.service";

@Injectable()
export class ResultService {
    private keyValuesSource: BehaviorSubject<AGKeyValues[]>;
    keyValues;
    private isLoadingSource: BehaviorSubject<boolean>;
    isLoading;
    private isErrorSource: BehaviorSubject<boolean>;
    isError;
    private headlineSource: BehaviorSubject<ResultHeadline>;
    headline;

    constructor(
        private tpService: TimePeriodsService,
        private api: ApiService
    ) {
        this.keyValuesSource = new BehaviorSubject<AGKeyValues[]>([]);
        this.keyValues = this.keyValuesSource.asObservable();

        this.isLoadingSource = new BehaviorSubject<boolean>(false);
        this.isLoading = this.isLoadingSource.asObservable();

        this.isErrorSource = new BehaviorSubject<boolean>(false);
        this.isError = this.isErrorSource.asObservable();

        this.headlineSource = new BehaviorSubject<ResultHeadline>({
            Headline: "",
            ReportId: null,
            Author: null
        });
        this.headline = this.headlineSource.asObservable();

        this.tpService.data.subscribe(data => this.checkKeyValuesTimePeriods(data));
    }


    updateHeadline(headline: string, author: string, reportId: number = null) {

        var resultHeadline = new ResultHeadline();
        resultHeadline = {
            Headline: headline,
            ReportId: reportId,
            Author: author
        };
        this.headlineSource.next(resultHeadline);
        if (reportId != null) this.fetch(reportId);
    }

    writeHeadline(newHeadline: string) {
        this.headlineSource.getValue().Headline = newHeadline;
        this.headlineSource.next(this.headlineSource.getValue());
    }

    private fetch(reportId: number) {
        this.keyValuesSource.next([]);
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingSource.next(true);
                    return this.api.getAggregatedReportKeyValues(reportId);
                }),
                map(data => {
                    this.isLoadingSource.next(false);
                    this.isErrorSource.next(false);
                    return data;
                }),
                catchError(() => {
                    this.isLoadingSource.next(false);
                    this.isErrorSource.next(true);
                    return [];
                })
            ).subscribe(data => this.keyValuesSource.next(data));
    }

    update(keyArray: AGKeyValues[]) {
        this.keyValuesSource.next(keyArray);
    }

    add(keyvalue: AGKeyValues) {
        var temp = this.keyValuesSource.getValue();
        var add = true;
        temp.forEach(element => {
            if (element.XmlId == keyvalue.XmlId) add = false;
        });
        if (add) {
            keyvalue.TimePeriodId = 1;
            temp.push(keyvalue);
            this.keyValuesSource.next(temp);
            //this.headlineSource.next({...this.headlineSource.value, ReportId: null}) //makes the report non updatable 
        }
    }

    delete(keyvalue: AGKeyValues) {
        let temp = this.deleteHelper(keyvalue);
        this.keyValuesSource.next(temp);
    }

    private deleteHelper(companies: AGKeyValues): Array<AGKeyValues> {
        var temp = this.keyValuesSource.getValue();
        //this.keyValues.subscribe(reportKeyValues => temp = reportKeyValues);
        for (var i = 0; i < temp.length; i++) {
            if (temp[i].XmlId == companies.XmlId) {
                temp.splice(i, 1);
                continue;
            }
        };
        return temp;
    }

    deleteAll(values: Array<AGKeyValues>) {
        let temp = this.keyValuesSource.getValue();
        values.forEach(element => {
            temp = this.deleteHelper(element);
        });
        this.keyValuesSource.next(temp);
    }

    addAll(value: Array<AGKeyValues>) {
        let temp = [];
        value.forEach(element => {
            if (!this.contains(element)) {
                element.TimePeriodId = 1;
                temp.push(element);
            }
        });
        this.keyValuesSource.next(this.keyValuesSource.getValue().concat(temp));
    }

    contains(keyvalue: AGKeyValues): boolean {
        var val = false;
        this.keyValues.source.value.forEach(element => {
            if (element.XmlId === keyvalue.XmlId) {
                val = true;
            }
        });
        return val;
    }

    updateTimePeriod(period: number, keyValueName: string): boolean {
        var found = false;

        this.keyValuesSource.getValue().forEach(element => {
            if (element.XmlId == keyValueName) {
                element.TimePeriodId = period;
                found = true;
            }
        });
        return found;
    }

    // if the users deletes a timeperiod we must make sure the keyvalues that uses that timeperiod is reset to the default timeperiod
    private checkKeyValuesTimePeriods(timeperiods: Array<TimePeriod>) {
        let temp = this.keyValuesSource.getValue();

        temp.forEach(keyVal => {
            let hit = false;
            timeperiods.forEach(timeperiod => {
                if (timeperiod.NameId == keyVal.TimePeriodId)
                    hit = true;
            });
            if (!hit)
                keyVal.TimePeriodId = 1;
        });
        this.keyValuesSource.next(temp);
    }

    /*GET*/
    getKeyValues() {
        return this.keyValuesSource.getValue()
    }

    getHeadline() {
        return this.headlineSource.getValue()
    }
    setHeadline(headline: ResultHeadline){
        this.headlineSource.next(headline)
    }
}
