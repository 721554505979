import { Component, ViewChild, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Save, ApiCallStates, ResultHeadline, AggregatedReportCollection } from "../../../Templates/styles/services/model";
import { ResultService } from "../services/result.service";

import { merge, Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from "../services/api.service";
import { WhoamiService } from "../../../Templates/styles/services/whoami.service";
import { ExistingService } from "../services/existing.service";

@Component({
    selector: 'save-dialog',
    templateUrl: '../view/save-dialog.component.html'
})
export class SaveDialog {

    types: Array<string>;
    apiCallStates: ApiCallStates = new ApiCallStates();

    constructor(
        public dialogRef: MatDialogRef<SaveDialog>, private resultService: ResultService,
        private existingService: ExistingService, private api: ApiService, private whoamiService: WhoamiService,
        @Inject(MAT_DIALOG_DATA) public resultHeadline: ResultHeadline
    ) { }

    ngOnInit() {
        this.types = new Array("Private", "Global");
    }

    onNoClick(save: Save = null): void {
        if (save) {
            this.resultService.writeHeadline(save.Headline.Headline)
            //this.resultService.saveList(save);
            this.saveList(save);
        }
        else
            this.dialogRef.close();
    }

    saveList(data: Save) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.apiCallStates.isLoading = true;
                    this.apiCallStates.errorMessage = null;
                    this.apiCallStates.isLoading = true;
                    return this.api.saveCreatedList(
                        data.Headline.Headline,
                        data.Type,
                        data.Headline.ReportId,
                        this.whoamiService.whoami,
                        this.resultService.getKeyValues(),
                        this.existingService.getSelected.EquityUnderwork,
                        this.existingService.getSelected.CreditUnderwork,
                    );
                }),
                map(data => {
                    this.apiCallStates.isError = false;
                    return data;
                }),
                catchError(error => {
                    this.apiCallStates.isLoading = false;
                    this.apiCallStates.isError = true;
                    this.apiCallStates.errorMessage = error.error;
                    return [];
                })
            )
            .subscribe(reportId => {
                let headline = this.resultService.getHeadline();
                headline.ReportId = reportId;
                this.resultService.setHeadline(headline);

                this.existingService.fetch();
                let selected = new AggregatedReportCollection();
                selected.Id = reportId;
                this.existingService.setSelected(selected);

                this.apiCallStates.isLoading = false;
                this.apiCallStates.isSuccess = true;
                setTimeout(() => {
                    this.apiCallStates.isSuccess = false;
                    this.dialogRef.close();
                }, 2000)
            });
    }

}