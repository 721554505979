import { NgModule } from "@angular/core";
import { MaterialImportModule } from "material/materialimport.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UpdateComponent } from "./components";
import { ApiService } from "./services";

@NgModule({
    declarations: [
        UpdateComponent
    ],
    imports: [
        MaterialImportModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        ApiService
    ],
    exports: [
        UpdateComponent
    ],
})
export class UpdateModule { }