<div class="middle">
    <div class="error-reached" *ngIf="isErrorResults">
        <div class="child">
            <p>Ops, something went wrong. Be so kind and try to retriew the data again</p>
        </div>
    </div>

    <div class="loading-shade background-gray opacity-50" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <div class="w-6 h-12 left">
        <!--<mat-tab-group [dynamicHeight]="true" (selectedTabChange)="$event.tab.textLabel == 'Customised' ? currentTabExisting = false : currentTabExisting = true">
            <mat-tab label="Customised">
                <customize></customize>
            </mat-tab>
            <mat-tab label="Existing">
                <existing></existing>
            </mat-tab>
        </mat-tab-group>-->
        <customize></customize>
    </div>

    <div class="w-3 result h-12">
        <div class="h-12 y-align pad-4 result-list result-list-help" *ngIf="resultKeyValues.length < 1">
            <div class="help-result-text">
                <p class="result-top">Here you can choose comapnies to include in your report. This is how:</p>
                <div class="result-bottom pad-2-bottom">
                    <mat-icon>arrow_upward</mat-icon>
                    <p>When you are done with your list use the button above this box to proceed</p>
                </div>
                <div class="result-left">
                    <mat-icon>arrow_back</mat-icon>
                    <p>To the left you can choose some comapnies. They will appear here</p>
                </div>
                <div class="result-right">
                    <mat-icon>arrow_forward</mat-icon>
                    <p>To the right you have some settings that can be altered. You can also search for previously
                        saved company lists.</p>
                </div>
                <div class="result-bottom">
                    <p>Below you can save your company list. Note that if you don't, the created excel will NOT be
                        updatable</p>
                    <mat-icon>arrow_downward</mat-icon>
                </div>
            </div>
        </div>

        <div class="h-12 w-12 result-list" *ngIf="resultKeyValues.length >= 1">
            <div class="row y-align pad-2-bottom pad-2-top">
                <div class="w-11">
                    <div class="row color-white">
                        <h2>Selected companies</h2>
                        <p class="h2-expl color-white">&nbsp; ({{resultKeyValues.length}})</p>
                    </div>
                </div>
            </div>
            <button *ngIf="!isReadOnly"
             mat-raised-button class="button result-list-save" [disabled]="resultKeyValues.length < 1" (click)="saveList()">
                <mat-icon aria-label="Save">save</mat-icon>&nbsp;Save company list
            </button>
            <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
                <div @result-item class="drag-item" *ngFor="let value of resultKeyValues; let i = index" cdkDrag
                    cdkDragLockAxis="y">
                    <div class="y-align">
                        <div class="w-1">
                            <div class="circle-small" [ngClass]="getFilterKeyValuesColor(value.Type)"></div>
                        </div>
                        <div class="w-9">
                            {{value.Name}}
                        </div>
                        <div class="w-1 result-department"></div>
                        <div class="w-1">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="deleteKeyValue(i)">
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete from list</span>
                                </button>

                                <button *ngIf="!isReadOnly" mat-menu-item [matMenuTriggerFor]="underWork">
                                    <mat-icon>work_outline</mat-icon>
                                    <span>Under work report</span>
                                </button>
                            </mat-menu>

                            <mat-menu #underWork="matMenu">
                                <span mat-menu-item *ngIf="selectionInPreviousStep.Equity" [disabled]="!value.ExistsEquityUnderwork">
                                    <mat-checkbox [disabled]="!value.ExistsEquityUnderwork" [(ngModel)]="selectedReport.EquityUnderwork"
                                    (click)="setUnderworkUse(i, true, !value.UseEquityUnderwork)">
                                        Equity metrics
                                    </mat-checkbox>
                                </span>
                                <span mat-menu-item *ngIf="selectionInPreviousStep.Credit" [disabled]="!value.ExistsCreditUnderwork">
                                    <mat-checkbox [disabled]="!value.ExistsCreditUnderwork" [(ngModel)]="selectedReport.CreditUnderwork"
                                    (click)="setUnderworkUse(i, false, !value.UseCreditUnderwork)">
                                        Credit metrics
                                    </mat-checkbox>
                                </span>
                            </mat-menu>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-3 right">
        <!--<div class="w-12 h-3 y-align pad-4">
                <div class=" w-12">
                    <h2>Annual & quarterly</h2>
                </div>
            </div>-->
        <div class="w-12 h-6  pad-2-top pad-2-side">
            <existing-search-box [reportList]="existingReports" [resultHeadline]="resultHeadline" (selected)="selectReport($event)"
                (deselected)="deselectReport()" (advanced)="openExistingDialog()"></existing-search-box>
        </div>

        <div class="w-12 h-6  pad-2-top pad-2-side">
            <div class="w-12 right-inner y-align  radius-0-bottom">
                <div class="w-12">
                    <!--<h2>Find saved list</h2>-->
                    <!--<div class="pad-1-bottom y-align row">
                        <mat-icon>work_outline</mat-icon>
                    </div>-->
                    <li *ngIf="!isReadOnly">
                        <div class="pad-1-bottom column">
                            <h2>Under work data</h2>
                            <p class="h2-expl font-gray">Set this globaly or make the selection on company level.</p>
                        </div>
                    </li>
                    <ul class="ul-margin-1 ul-basic">
                        <li *ngIf="!isReadOnly">
                            <div class="row y-align">

                                <mat-checkbox class="w-1" [checked]="false" (change)="resultService.setAllUnderworkUse(true, $event.checked);
                                resultService.setAllUnderworkUse(false, $event.checked);
                                checkUncheckAll($event.checked)">
                                </mat-checkbox>
                                <span class="w-9">Use underwork data when possible</span>
                            </div>
                        </li>
                        <li *ngIf="selectionInPreviousStep.Equity && !isReadOnly">
                            <div class="row y-align">
                                <mat-checkbox class="w-1"[(ngModel)]="selectedReport.EquityUnderwork" [checked]="selectedReport.EquityUnderwork" 
                                (change)="resultService.setAllUnderworkUse(true, $event.checked);
                                checkEquity($event.checked)">
                                </mat-checkbox>
                                <span class="w-9">Use under work data for equity metrics</span>
                            </div>
                        </li>
                        <li *ngIf="selectionInPreviousStep.Credit && !isReadOnly">
                            <div class="row y-align">
                                <mat-checkbox class="w-1" [(ngModel)]="selectedReport.CreditUnderwork" [checked]="selectedReport.CreditUnderwork" 
                                (change)="resultService.setAllUnderworkUse(false, $event.checked);
                                checkCredit($event.checked)">
                                </mat-checkbox>
                                <span class="w-9">Use under work data for credit metrics</span>
                            </div>
                        </li>
                    </ul>
                    <!--TO BE FIXED-->
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="icon">
                    <mat-icon style="padding-left: 8px">work_outline</mat-icon>
                </div>
                <div class="cont">
                    <div class="column">
                        <span>Always use under work reports...</span>
                        <mat-checkbox [checked]="false">...when possible</mat-checkbox>
                        <mat-checkbox *ngIf="selectionInPreviousStep.Equity" [checked]="false">...for equity metrics</mat-checkbox>
                        <mat-checkbox *ngIf="selectionInPreviousStep.Credit" [checked]="false">...for credit metrics</mat-checkbox>
                        <span style="color:gray; font-size:11px; padding-top:6px">or make the selection for a specific company</span>
                    </div>
                </div>-->

<!--<ul>
            <li>
                <span matTooltip="Save created metric list" [matTooltipPosition]="'above'" style="padding-top: 12px" [matTooltipDisabled]="currentTabExisting || true">
                    <button mat-mini-fab color="accent" [disabled]="currentTabExisting || true">
                        <mat-icon>save</mat-icon>
                    </button>
                </span>
            </li>
            <li>
                <span matTooltip="De-select all companies" [matTooltipPosition]="'above'" style="padding-top: 12px" [matTooltipDisabled]="currentTabExisting">
                    <button mat-mini-fab color="accent" [disabled]="currentTabExisting" (click)="deleteAllKeyValues()">
                        <mat-icon>delete_sweep</mat-icon>
                    </button>
                </span>
            </li>
        </ul>-->