import { Injectable, OnInit } from "@angular/core";
import { AuthorizeService } from 'api-authorization/authorize.service';

/**
 * This service holds info about the user.
 * (just username for now)
 */
@Injectable()
export class WhoamiService {

  private _whoami: string;

  constructor(private auth: AuthorizeService) {
    this.getWhoami();
  }

  private getWhoami() {

    this.auth.getUser().subscribe(u => {
      const username = u.name;
      const userArr = username.split("@handelsbanken."); 
      this._whoami = userArr.length == 2 ? userArr[0].toLowerCase() : username;
      //this._whoami = (username.indexOf('\\') > 0 ? username.split('\\')[1] : username).toLowerCase();

    }) ;
  }

  get whoami(): string {
    return this._whoami;
  }
}
