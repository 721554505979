import { Component } from '@angular/core';
import { List } from 'app/Templates/styles/services/model';

@Component({
  selector: 'report',
  templateUrl: './report.component.html',
})
export class ReportComponent {

  choice: string;
  headerList: Array<List>

  constructor() { }

  ngOnInit() {
    this.choice = 'AnnualQuarterly';
    this.headerList = [
      {Path: "AnnualQuarterly", Name: "Annual & quarterly", Disabled: false},
      /*{Path: "TimeSeries", Name: "Time series", Disabled: true}*/
    ]
  }
}