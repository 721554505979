import { SectorFilter } from "../datastructure.model";

export interface AGKeyValues {
    Mnemonic: string;
    XmlId: string;
    Name: string;
    Type: string;
    TimePeriodId: number;
}

export interface Company {
    Id: number;
    Name: string;
    Type: string;
    Sector: number;
    UseEquityUnderwork: boolean;
    UseCreditUnderwork: boolean;
}

export class Aggregated {
    KeyValues: Array<AGKeyValues>;
    Companies: Array<Company>;
    Timeperiods: Array<TimePeriod>;
    Currency: string;
}

export class AggregatedAll {
    KeyValues: Array<AGKeyValues>;
    Companies: Array<Company>;
    Sectors: Array<Sector>;
    Timeperiods: Array<TimePeriod>;
    Countries: Array<Country>;
    SectorFilter: SectorFilter;
}
export class Country {
    Id: number;
    Name: string;
    Type: string;
}

export class AggregatedSector {
    KeyValues: Array<AGKeyValues>;
    Countries: Array<Country>;
    Sectors: Array<Sector>;
    Timeperiods: Array<TimePeriod>;
    Filter: SectorFilter;
    Currency: string;
}

export class Sector {
    Id: number;
    Label: string;
}

export class TimePeriod {
    Name: string;
    NameId: number;
    Content: Array<TimePeriodContent>;
}

export class TimePeriodContent {
    Year: number;
    Q1: boolean;
    Q2: boolean;
    Q3: boolean;
    Q4: boolean;
    Annual: boolean;
}

// Data table structures
export class OrderedKeyValueData {
    Id: string;
    Name: string;
    Type: string;
    CollectionCurrency: string;
    Range: RangeFormat;
    Companies: Array<CompanyDataSerie>;
}

export class CompanyDataSerie {
    Currency: string;
    Id: number;
    Name: string;
    Serie: Array<DataPoint>;
}

export class DataPoint {
    FormattedValue: string;
    Value: any;
    HasValue: boolean;
    Quarter: number;
    Year: number;
}

export enum RangeFormat {
    None,
    BelowThousands,
    Thousands,
    Millions,
    Billions,
    Percentage,
    Factor,
    Auto
}

// Data table information for Excel
export class AggregatedExcel {
    Header: AggregatedExcelHeader;
    Data: Array<OrderedKeyValueData>; 
}

export class AggregatedExcelHeader {
    Metriclist: ExcelAttribute;
    Companylist: ExcelAttribute;
    Currency: string;
    Sectors: Array<ExcelAttribute>;
    Countries: Array<ExcelAttribute>;
    Specials: ExcelSpecials;
}

export class ExcelAttribute {
    Name: string;
    Id: number;
}

export class ExcelSpecials {
    Filter: SectorFilter;
    OnlyCompanies: boolean;
    LockCompaniesInSector: boolean;
    UWEquity: boolean;
    UWCredit: boolean;
}

export class AggregatedExcelSheets {
    Index: number;
    Name: string;
    Data: AggregatedExcelHeader;
    IsUpdatable: boolean;
    Visibility: string;
}

