import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ResultHeadline, Save, ApiCallStates } from '../services/model';
import { WhoamiService } from '../services/whoami.service';

@Component({
    selector: 'hb-save',
    templateUrl: '../view/save.component.html',
})
export class SaveComponent {
    @Input() headline: ResultHeadline;
    @Input() types: Array<string>
    @Input() apiCallStates: ApiCallStates;

    @Output() output: EventEmitter<Save> = new EventEmitter<Save>();
    @Output() apiCallStatesOutput: EventEmitter<ApiCallStates> = new EventEmitter<ApiCallStates>();
    
    typeChoice: string;
    headlineWritten: string;

    currentUser: string;

    constructor(public whoamiService: WhoamiService) { }

    ngOnInit() {
        this.currentUser = this.whoamiService.whoami;
        this.typeChoice = this.types[0];
        this.headlineWritten = this.headline.Headline;
    }

    ngOnChanges(changes: SimpleChanges) {}

    save(update: boolean){
        let head = new ResultHeadline;
        head = {
            Headline: this.headlineWritten,
            ReportId: !update ? this.headline.ReportId = null : this.headline.ReportId,
            Author: this.headline.Author
        }
        this.output.emit({
            Headline: head,
            Type: this.typeChoice
        })
    }

    emitApiCallStates(){
        this.apiCallStatesOutput.emit(this.apiCallStates);
    }
}