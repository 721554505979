import { Component, ViewChild, ElementRef, Input, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap, retryWhen } from 'rxjs/operators';

import { ApiService } from "../services/api.service";
import { CustomsectorResultService } from "../../2.CustomSector/customsector-result.service";
import { AnnualquarterlyResultService } from "../../1.Report/annualQuarterly/services/annualquarterly-result.service";
import { Aggregated, AGKeyValues, Company, TimePeriod, TimePeriodContent, AggregatedSector, Sector, AggregatedAll, OrderedKeyValueData, Country, AggregatedExcel, AggregatedExcelHeader, ExcelAttribute, ExcelSpecials } from "../model"
import { TableDialog } from "./table-dialog.component";
import { ParseFilename } from 'app/Templates/styles/services/parse-filename';
import { Logger } from 'app/_helpers';
import { ExistingService } from "../../2.CustomSector/customList/services/existing.service";


declare var $: any;

@Component({
    selector: 'result',
    templateUrl: '../view/result.component.html',
})
export class ResultComponent implements AfterViewChecked {
    @Input() set currentStepInStepper(value: number) {
        if (value == 2) {
            this.postResult();
        }
        else {
            this.tableData = null;
        }
    };

    private tableDOM: ElementRef;
    @ViewChild('resultTable') set content(content: ElementRef) {
        this.tableDOM = content;
        this.ngAfterViewInit();
    };

    tableData: Array<OrderedKeyValueData>;
    isLoading: boolean;
    isError: boolean;
    isErrorMessage: string;
    isErrorMessageFull: string;

    constructor(private changeRef: ChangeDetectorRef,
        private apiService: ApiService, private annualquarterlyService: AnnualquarterlyResultService,
        private customlistsectorService: CustomsectorResultService,
        private parseFilename: ParseFilename, private logger: Logger,
        public tableDialog: MatDialog,
        public attributeService:ExistingService) { }

    ngAfterViewChecked(): void { this.changeRef.detectChanges(); }
        
    ngOnInit() {
        this.isLoading = false;
        this.isError = false;
        this.isErrorMessage = "";
        this.isErrorMessageFull = "";
    }

    ngAfterViewInit() {
        if (this.tableDOM != null) {
            $('table').on('scroll', function () {
                $("table > *").width($(this).width() + $(this).scrollLeft());
            });

            // New JavaScript

            var $stickyHeader = $('table thead tr th:first-child');
            var $stickyCells = $('table tbody tr td:first-child');

            $('table').on('scroll', function () {
                $stickyHeader.css('left', ($(this).scrollLeft() + 'px'));
                $stickyCells.css('left', ($(this).scrollLeft() + 'px'));
            });
        }
    }

    postResult() {

        let isSectorChosen = this.customlistsectorService.getIsSector();

        let res = new AggregatedAll;
        res.KeyValues = new Array<AGKeyValues>();
        res.Companies = new Array<Company>();
        res.Timeperiods = new Array<TimePeriod>();
        res.Sectors = new Array<Sector>();
        res.Countries = new Array<Country>();

        res = this.step1(res);

        isSectorChosen ? res = this.step2Sector(res) : res = this.step2Custom(res);

        let currency = this.annualquarterlyService.getCurrency == "" ? "" : this.annualquarterlyService.getCurrency;

        if (isSectorChosen) {
            let sec = new AggregatedSector;
            sec.KeyValues = res.KeyValues;
            sec.Countries = res.Countries;
            sec.Sectors = res.Sectors;
            sec.Timeperiods = res.Timeperiods;
            sec.Filter = res.SectorFilter;
            sec.Currency = currency;
            this.getSector(sec);
        } else {
            let cus = new Aggregated;
            cus.KeyValues = res.KeyValues;
            cus.Companies = res.Companies;
            cus.Timeperiods = res.Timeperiods;
            cus.Currency = currency;
            this.getCustomlist(cus);
        }
    }

    step1(res: AggregatedAll): AggregatedAll {
        let keyValues = this.annualquarterlyService.getKeyValues();
        let timeperiods = this.annualquarterlyService.getTimePeriods();

        keyValues.forEach(element => {
            res.KeyValues.push({
                XmlId: element.XmlId,
                Mnemonic: element.Mnemonic,
                Name: element.Name,
                Type: element.Type,
                TimePeriodId: element.TimePeriodId
            });
        });

        timeperiods.forEach(element => {
            let periods = new Array<TimePeriodContent>();
            element.Content.forEach(content => {
                periods.push({
                    Year: content.Year,
                    Q1: content.Q1,
                    Q2: content.Q2,
                    Q3: content.Q3,
                    Q4: content.Q4,
                    Annual: content.Annual
                });
            });

            res.Timeperiods.push({
                Name: element.Name,
                NameId: element.NameId,
                Content: periods
            });
        });

        return res;
    }

    step2Custom(res: AggregatedAll): AggregatedAll {
        let companies = this.customlistsectorService.getCompanies();

        companies.forEach(element => {
            if (element.Type != 'Deactivated') {
                res.Companies.push({
                    Id: element.Id,
                    Name: element.Name,
                    Type: element.Type,
                    Sector: element.Sector,
                    UseEquityUnderwork: element.UseEquityUnderwork,
                    UseCreditUnderwork: element.UseCreditUnderwork
                })
            }
        });

        return res;
    }

    step2Sector(res: AggregatedAll): AggregatedAll {
        let sectorList = this.customlistsectorService.getSectorList();
        let countries = this.customlistsectorService.getCountries();

        res.SectorFilter = this.customlistsectorService.getSectorFilter();

        countries.forEach(element => {
            res.Countries.push({
                Id: element.Id,
                Name: element.Name,
                Type: element.Type
            })
        });

        sectorList.forEach(element => {
            res.Sectors.push({
                Id: element.Data,
                Label: element.Label
            })
        });

        return res;
    }

    getCustomlist(v: Aggregated) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    return this.apiService.postResult(v);
                }),
                map(data => {
                    this.isLoading = false;
                    this.isError = false;
                    return data;
                }),
                catchError(error => {
                    this.isLoading = false;
                    this.isError = true;
                    this.isErrorMessage = error.error;
                    this.isErrorMessageFull = error.message + " | " + error.error;
                    return [];
                })
            ).subscribe(data => {
                this.tableData = data;
            });
    }

    getSector(v: AggregatedSector) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    return this.apiService.postResultSector(v);
                }),
                map(data => {
                    this.isLoading = false;
                    this.isError = false;
                    return data;
                }),
                catchError(error => {
                    this.isLoading = false;
                    this.isError = true;
                    this.isErrorMessage = error.error;
                    this.isErrorMessageFull = error.message + " | " + error.error;
                    return [];
                })
            ).subscribe(data => this.tableData = data);
    }

    copyToClipboard(val: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    openTableDialog(): void {
        this.tableDialog.open(TableDialog, {
            maxWidth: '95vw',
            maxHeight: '95vh',
            backdropClass: 'backdrop',
            data: { data: this.tableData }
        });
    }

    downloadExcel() {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoading = true;
                    return this.apiService.createExcel(this.createExcelDataStruct());
                }),
                map(data => {
                    this.isLoading = false;
                    this.isError = false;
                    return data;
                }),
                catchError(error => {
                    this.isLoading = false;
                    this.isError = true;
                    this.isErrorMessage = error.error;
                    this.isErrorMessageFull = error.message + " | " + error.error;
                    return [];
                })
            ).subscribe(data => {
                if (!(data && data.header)) return;
                const filename = this.parseFilename.getFilenameFromContentDisposition(data.header);
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(data.blob);
                link.download = filename;
                link.click();
            });
    }

    createExcelDataStruct(): AggregatedExcel {
        let ae = new AggregatedExcel();
        let header = new AggregatedExcelHeader();

        ae.Data = this.tableData;

        // metric values
        let metricHeadline = this.annualquarterlyService.getHeadline;

        header.Metriclist = this.createExcelAttribute(metricHeadline.Headline, metricHeadline.ReportId);
   
        header.Currency = this.annualquarterlyService.getCurrency;

        // company values
        if (this.customlistsectorService.getIsSector()) {
            let sectors = Array<ExcelAttribute>();
            let countries = Array<ExcelAttribute>();
            let specials = new ExcelSpecials();

            let sectorsChosen = this.customlistsectorService.getSectorList();
            let countriesChosen = this.customlistsectorService.getCountries();

            countriesChosen.forEach(element => {
                countries.push(this.createExcelAttribute(element.Name, element.Id))
            });

            sectorsChosen.forEach(element => {
                sectors.push(this.createExcelAttribute(element.Label, element.Data))
            });

            specials.Filter = this.customlistsectorService.getSectorFilter();
            //console.log("specials",specials.Filter)

            header.Countries = countries;
            header.Sectors = sectors;
            header.Specials = specials;
    
        } else {
            let specials = new ExcelSpecials();
            let company = this.customlistsectorService.getCustomHeadline;
            header.Companylist = this.createExcelAttribute(company.Headline, company.ReportId);
            specials.UWEquity= this.attributeService.getSelected.EquityUnderwork;
            specials.UWCredit=this.attributeService.getSelected.CreditUnderwork;
            header.Specials = specials;
            
        }

        ae.Header = header;

        return ae;
    }

    private createExcelAttribute(name: string, id: number): ExcelAttribute {
        let ret = new ExcelAttribute()
        ret = {
            Name: name,
            Id: id
        }
        return ret;
    }
}
