import { Component, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TimePeriod, TimePeriodContent, Tab } from '../../report.model';
import { TimePeriodsService } from '../services/timeperiods.service';
import { AnnualquarterlyResultService } from '../services/annualquarterly-result.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'time-periods-dialog',
    templateUrl: '../view/timeperiods-dialog.component.html'
})
export class TimePeriodsDialog {
    @ViewChild('sidenav') sidenav: MatSidenav;

    timePeriods: Array<TimePeriod>;
    isLoading = false;
    isError = false;
    currentTab: Tab;

    timeperiodError: boolean;

    currentTimeperiodEditIndex: number;
    sideOpen: boolean;

    constructor(
        public dialogRef: MatDialogRef<TimePeriodsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any, private tpservice: TimePeriodsService, private aqResult: AnnualquarterlyResultService,
        public snackBar: MatSnackBar) { }

    ngOnInit() {
        this.currentTab = { Name: "Default", Index: 0 };
        this.tpservice.data.subscribe(data => this.timePeriods = data);

        if (this.timePeriods.length < 1)
            this.tpservice.addDefault();

        //indicate if the timeperiods are done
        this.aqResult.timePeriods.subscribe(data => {
            if (this.timeperiodError && data)
                this.timeperiodError = false
        });
        this.timeperiodError = !this.aqResult.timePeriodsDone;

        this.sideOpen = false;
        this.currentTimeperiodEditIndex = 0;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.currentTab = { Name: tabChangeEvent.tab.textLabel, Index: tabChangeEvent.index };
    }

    addNewPeriod() {
        this.tpservice.addNew();
        this.currentTab = { Name: this.timePeriods[this.timePeriods.length - 1].Name, Index: this.timePeriods.length - 1 };
        //this.editTimeperiod(this.timePeriods.length - 1);
        this.currentTimeperiodEditIndex = this.timePeriods.length - 1;
    }

    ngOnDestroy() {
        this.openSnackBar("Time periods", "Saved", 'style-succes');
    }

    openSnackBar(message: string, action: string, style: any) {
        this.snackBar.open(message, action, {
            duration: 1000,
            panelClass: style
        });
    }

    editTimeperiod(index: any){
        this.currentTimeperiodEditIndex = index;
        this.sidenav.open()
        this.sideOpen = true;
    }

    closeSide(close: boolean){
        close ? this.sidenav.close() : '';
        this.sideOpen = false;
    }

    // DUPLICATED FROM customize.component.ts
    //set the height of the table with all metrics, relative to the filter height
    tableHeight(){
        let filter = document.getElementById('table-filter').offsetHeight;
        return "calc(100% - " + filter + "px - 160px)" 
    }
}

@Component({
    selector: 'time-periods-add',
    templateUrl: '../view/timeperiods-add.component.html'
})
export class TimePeriodsAddComponent {
    @Input() value: TimePeriod;
    @Input() i: number;

    @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();

    max = 2030;
    min = 1990;
    step = 1;
    date: Array<number>;
    timePeriods: Array<TimePeriod>;

    nmrChosen = 0;

    constructor(private tpservice: TimePeriodsService) { }

    ngOnInit() {
        this.tpservice.data.subscribe(data => this.timePeriods = data);

        this.date = new Array<number>(2);
        this.date[0] = 2017;
        this.date[1] = 2021;

        this.chosen(true); //since Annual is checked by default
    }

    chosen(checked: boolean) {
        checked ? this.nmrChosen++ : this.nmrChosen--;
    }

    addPeriod(q1: boolean, q2: boolean, q3: boolean, q4: boolean, qy: boolean) {
        this.tpservice.addRow(q1, q2, q3, q4, qy, this.date, this.i);
        this.done.emit(true);
    }
}

@Component({
    selector: 'time-periods-content',
    templateUrl: '../view/timeperiods-content.component.html'
})
export class TimePeriodsContentComponent {
    @Input() value: TimePeriod;
    @Input() i: number;

    @Output() edit: EventEmitter<number> = new EventEmitter<number>();

    max = 2030;
    min = 1990;
    step = 1;
    date: Array<number>;
    timePeriods: Array<TimePeriod>;

    isLoading = false;
    isError = false;

    nmrChosen = 0;
    nameError = false;

    constructor(private tpservice: TimePeriodsService) { }

    ngOnInit() {
        this.tpservice.data.subscribe(data => this.timePeriods = data);

        this.date = new Array<number>(2);
        if(this.value.Content.length > 0){
            this.date[0] = this.value.Content[0].Year;
            this.date[1] = this.value.Content[this.value.Content.length - 1].Year;
        } else{
            this.date[0] = 1990;
            this.date[1] = 1990;
        }

        this.chosen(true); //since Annual is checked by default
    }

    chosen(checked: boolean) {
        checked ? this.nmrChosen++ : this.nmrChosen--;
    }

    changeName(newName: string, position: number) {
        this.nameError = this.tpservice.changeName(newName, position);
    }

    deletePeriod() {
        this.tpservice.deletePeriod(this.i);
    }

    deleteRow(value: TimePeriodContent) {
        this.tpservice.deleteRow(value, this.i);
    }

    clearRows() {
        this.tpservice.clearRows(this.i);
    }

    addPeriod(q1: boolean, q2: boolean, q3: boolean, q4: boolean, qy: boolean, date: Array<number>) {
        this.tpservice.clearRows(this.i);
        this.tpservice.addRow(q1, q2, q3, q4, qy, date, this.i);
    }

    emitEdit(){
        this.edit.emit(this.i);
    }
}