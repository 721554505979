<style>
    .mat-slide-toggle-content {
        white-space: pre-wrap !important;
        margin-top: 2rem !important;
    }
</style>
<div class="middle" style="padding-top: calc(134px - 75px);">

    <div class="loading-shade background-gray opacity-50" *ngIf="isLoading" style="z-index: 999999; padding-left: 15rem">
        <mat-spinner></mat-spinner>
    </div>

    <div class="column background-gray h-8 y-align x-align-center z-11 radius-50-bottom" *ngIf="isError && !isLoading">
        <h1 class="font-error pad-3-bottom">Error!</h1>
        <p>So this happened! You can try altering your criterias or use the button to copy the error mesage and send that to a site admin.</p>
        <p style="font-weight: normal; font-style: italic;"><br/>{{isErrorMessage}}</p>
        
        <button class="button mar-2-top" mat-raised-button (click)="copyToClipboard(isErrorMessageFull)"><mat-icon>file_copy</mat-icon> Copy error message</button>
    </div>

    <table-dialog *ngIf="!isLoading && !isError" [tableData]="tableData"></table-dialog>

    <div class="nav-button-container" style="left: calc((50% + 80rem) - 56rem)">
        <button id="excel-button" [disabled]="isLoading || isError" class="button-success" (click)="downloadExcel()" mat-raised-button matStepperNext>
            Download excel <mat-icon>cloud_download</mat-icon>
        </button>
    </div>

</div>