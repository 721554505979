import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { MaterialImportModule } from "material/materialimport.module";

import { ApiService, ResultService, CustomlistResultService, ExistingService } from "./services";
import { StyleTemplatesModule } from "../../Templates/styles/styletemplates.module";
import { CustomListComponent, ExistingComponent, CustomizeComponent, SaveDialog } from "./components";

@NgModule({
    declarations: [
        CustomListComponent,
        ExistingComponent,
        CustomizeComponent,
        SaveDialog
    ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        MaterialImportModule,
        HttpClientModule,
        StyleTemplatesModule
    ],
    entryComponents: [
        ExistingComponent, SaveDialog
    ],
    exports: [
        CustomListComponent
    ],
    providers: [
        ApiService,
        ResultService,
        CustomlistResultService,
        ExistingService
    ]
})
export class CustomListModule { }