<div>
    <hb-header [headline]="'2. Companies'" [data]="headerList" (chosenPath)="choiceChange($event)"></hb-header>

    <div [style.visibility]="(choice == 'CustomList') ? 'visible' : 'hidden'" [style.position]="(choice == 'Sector') ? 'absolute' : 'relative'" [style.left]="(choice == 'Sector') ? '100vw' : ''"> <!--[style.position]="(choice == 'Sector') ? 'absolute' : 'relative'" [style.left]="(choice == 'Sector') ? '100vw' : ''"-->
        <custom-list></custom-list>
    </div>

    <div [style.visibility]="(choice == 'Sector') ? 'visible' : 'hidden'" [style.position]="(choice == 'CustomList') ? 'absolute' : 'relative'" [style.left]="(choice == 'CustomList') ? '100vw' : ''"> <!--[style.position]="(choice == 'CustomList') ? 'absolute' : 'relative'" [style.left]="(choice == 'CustomList') ? '100vw' : ''"-->
        <sector></sector>
    </div>

    <!--<div *ngIf="(choice == 'CustomList')">
        <custom-list></custom-list>
    </div>

    <div *ngIf="(choice == 'Sector')">
        <sector></sector>
    </div>-->
</div>