<!--<div id="close-dialog">
    <button mat-mini-fab color="accent" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>-->

<button class="dialog-close" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>

<div class="h-12 background-white" style="overflow: hidden">
    <div class="background-gray pad-4" id="table-filter" style="display: grid">
        <h1 class="pad-2-top pad-2-bottom">Manage sector countries</h1>
        <p class="h1-expl">Select which companies the selected sector shall contained based on its country.</p>
    </div>

    <div class="pad-4 w-12 h-12" [ngStyle]="{'height': tableHeight(false)}">
        <div class="row pad-2-bottom w-8" id="table-filter2">
            <div class="w-12 header">
                <mat-form-field class="w-10">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search country" #searchTerm>
                    <button mat-button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm.value=''; applyFilter('')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div class="w-8 border-2-right" [ngStyle]="{'height': tableHeight()}">
            <mat-table #table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Id">
                    <mat-header-cell *matHeaderCellDef [ngClass]="'column-small'" style="overflow:initial;">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected(dataSource.filteredData)"
                            [indeterminate]="selection.hasValue() && !isAllSelected(dataSource.filteredData)">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="'column-small'" style="overflow:initial;">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                            [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Region </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="mat-chip-spec">
                        <!--<mat-chip [ngClass]="getFilterColor(element.Type)">
                                {{element.Type}}
                            </mat-chip>-->
                        <div class="row y-align">
                            <span class="circle-small mar-1-right" [ngClass]="getFilterColor(element.Type)"></span>
                            <span class="color-gray">{{element.Type}}</span>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>

        <div class="w-4 row pad-3-left">
            <h3 class="pad-2-bottom">Filter list</h3>
            <ul class="ul-basic ul-margin-1 w-10">
                <li *ngFor="let filter of filterAttributes; let i = index">
                    <div class="row y-align">
                        <div class="w-1"></div>
                        <mat-checkbox class="w-2" (change)="clickFilter(i); searchTerm.value=''" [checked]="filter.Choosen">
                        </mat-checkbox>
                        <div class="w-2">
                            <div class="circle-small" [ngClass]="getFilterColor(filter.Name)"></div>
                        </div>
                        <span class="w-7">{{filter.Name}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<!--
<div class="spacer-half">
    <div class="header">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search country" #searchTerm>
            <button mat-button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm.value=''; applyFilter('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="row mat-chip-spec">
        <div class="col-sm-12">
            <mat-chip-list>
                <mat-chip *ngFor="let filter of filterAttributes; let i = index" [ngClass]="filter.Choosen ? filter.Color : filter.Color + '-o'"
                    (click)="clickFilter(i); searchTerm.value=''">
                    {{filter.Name}}
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
</div>


<div class="spacer-half">
    <mat-table #table [dataSource]="dataSource" matSort style="max-height: calc(95vh - 280px); overflow-y: scroll; overflow-x: hidden">

        <ng-container matColumnDef="Id">
            <mat-header-cell *matHeaderCellDef [ngClass]="'column-small'" style="overflow:initial;">
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected(dataSource.filteredData)"
                    [indeterminate]="selection.hasValue() && !isAllSelected(dataSource.filteredData)">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="'column-small'" style="overflow:initial;">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Region </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-chip-spec">
                <div class="list-category">
                    <span class="list-item-category" [ngClass]="getFilterColor(element.Type)"></span>
                    <span class="color-gray">{{element.Type}}</span>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #paginator [pageSize]="59" [pageSizeOptions]="[20, 59, 100]" [showFirstLastButtons]="true">
    </mat-paginator>
</div>
-->