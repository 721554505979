import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingsComponent } from './components';
import { ApiService, ResultService } from './services';
import { StyleTemplatesModule } from '../Templates/styles/styletemplates.module';
import { MaterialImportModule } from 'material/materialimport.module';

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    StyleTemplatesModule
  ],
  exports: [
    SettingsComponent,
  ],
  providers: [
      ResultService,
      ApiService
  ]
})
export class SettingsModule { }