import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialImportModule } from 'material/materialimport.module'
import { StyleTemplatesModule } from '../Templates/styles/styletemplates.module';
import { ApiService } from './services';
import { ResultComponent, TableDialog } from './components';
import { AnnualquarterlyResultService } from 'app/1.Report/annualQuarterly/services';
import { CustomsectorResultService } from 'app/2.CustomSector/customsector-result.service';
import { SettingsResultService } from 'app/3.Settings/services';
import { ExistingService} from 'app/2.CustomSector/customList/services/existing.service'

@NgModule({
  declarations: [
    ResultComponent,
    TableDialog
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialImportModule,
    StyleTemplatesModule
  ],
  entryComponents: [
    TableDialog
  ],
  exports: [
    ResultComponent
  ],
  providers: [
    ApiService,
    AnnualquarterlyResultService,
    CustomsectorResultService,
    SettingsResultService,
    ExistingService
  ]
})
export class ResultModule { }